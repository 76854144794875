import React, { useContext, useEffect, useMemo, useState } from 'react'
import { PlacementsPickerProps } from '../../../common/PlacementsPickerContent'
import { PlacementCartItem } from './PlacementCartItem'
import useCampaignData from 'src/stores/campaignData'
import { fetchPlacementsInfo } from '../../../../api/fetchPlacementsInfo'
import { Button, Spinner } from 'react-bootstrap'
import { formattedNumber } from 'src/utils'
import { PlacementsInfo } from '../../../../model/types'
import { addCartPlacementsToCampaign } from '../../../../api/addCartPlacementsToCampaign'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'

type Props = {} & Pick<
	PlacementsPickerProps,
	| 'addedPlacements'
	| 'onRemovePlacementsFromCart'
	| 'detailedPlacementId'
	| 'getDetailedPlacement'
	| 'onClose'
>

export enum CartLoadingState {
	Pending,
	Fetching,
	Updating,
}

export const PlacementsCart = ({
	addedPlacements,
	onRemovePlacementsFromCart,
	detailedPlacementId,
	onClose,
	getDetailedPlacement,
}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { addToast } = useContext(ToastsContext)

	const [placementsInfo, setPlacementsInfo] = useState<PlacementsInfo[]>([])
	const [loadingState, setLoadingState] = useState<CartLoadingState>(
		CartLoadingState.Pending
	)

	const handleGetPlacementsInfo = async () => {
		try {
			setLoadingState(CartLoadingState.Fetching)
			const info = await fetchPlacementsInfo(
				campaign.id,
				addedPlacements.map((el) => el.pk)
			)
			setPlacementsInfo(info)
		} catch {
		} finally {
			setLoadingState(CartLoadingState.Pending)
		}
	}

	const handleConfirmPlacements = async () => {
		try {
			setLoadingState(CartLoadingState.Updating)
			await addCartPlacementsToCampaign(campaign.id, addedPlacements)
			await campaignInterface.refetchSelected()

			addToast({
				text: 'Добавлены новые места',
				type: 'success',
			})
			onClose()
		} catch {
		} finally {
			setLoadingState(CartLoadingState.Pending)
		}
	}

	const handleRemoveAllAdded = () => {
		onRemovePlacementsFromCart(addedPlacements.map((el) => el.pk))
	}
	const totalAddedPlacementsAmount = useMemo(() => {
		const total = addedPlacements.reduce((acc, placement) => {
			const info = placementsInfo.find((info) => info.pk === placement.pk)
			if (!info) {
				return acc
			}

			return (acc += parseFloat(placement.psc.price) * info.ac_days_count)
		}, 0)

		return total
	}, [addedPlacements, placementsInfo])

	useEffect(() => {
		if (addedPlacements.length) {
			handleGetPlacementsInfo()
		}
	}, [addedPlacements])

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: '100%',
			}}
		>
			<h5
				style={{
					borderBottom: '1px solid black',
					padding: '16px',
					margin: 0,
				}}
			>
				Выбранные места
			</h5>
			<div
				style={{
					flex: 1,
					overflowY: 'auto',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						justifyContent: 'space-between',
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{addedPlacements.map((el) => (
							<PlacementCartItem
								isLoading={
									loadingState === CartLoadingState.Fetching
								}
								extraInfo={placementsInfo.find(
									(info) => info.pk === el.pk
								)}
								key={el.pk}
								detailedPlacementId={detailedPlacementId}
								getDetailedPlacement={getDetailedPlacement}
								placement={el}
								onRemovePlacementsFromCart={
									onRemovePlacementsFromCart
								}
							/>
						))}
					</div>

					<div style={{ padding: '24px' }}>
						<Button
							disabled={loadingState !== CartLoadingState.Pending}
							onClick={handleRemoveAllAdded}
							variant="danger"
							style={{
								fontWeight: 500,
								fontSize: '16px',
								lineHeight: '24px',
								width: '100%',
								borderRadius: '12px',
								height: '40px',
							}}
						>
							Удалить все места
						</Button>
					</div>
				</div>
			</div>

			<div
				style={{
					borderTop: '1px solid #CED4DA',
					padding: '24px',
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				<div
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<h5 className="m-0">Итого:</h5>
					<h5 className="m-0">
						{formattedNumber(totalAddedPlacementsAmount)} ₽
					</h5>
				</div>

				<Button
					disabled={
						!addedPlacements.length ||
						loadingState !== CartLoadingState.Pending
					}
					onClick={handleConfirmPlacements}
					style={{
						borderRadius: '16px',
						height: '48px',
						fontWeight: 500,
						fontSize: '20px',
						lineHeight: '30px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '8px',
					}}
				>
					Подтвердить выбор
					{loadingState === CartLoadingState.Updating && (
						<Spinner
							animation="border"
							variant="primary"
							size="sm"
						/>
					)}
				</Button>
			</div>
		</div>
	)
}
