import React from 'react'
import { Form } from 'react-bootstrap'
import s from './placementItem.module.scss'
import {
	PlacementsPickerProps,
	SelectedPlacement,
} from '../../../../common/PlacementsPickerContent'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import { defaultPlacementImage } from 'src/stores/technical-requirements-store'

type Props = {
	isSelected: boolean
	isHovered: boolean
	placement: PlacementShort
} & Pick<PlacementsPickerProps, 'onSelectPlacement' | 'loading'>

export const SelectCheckbox = ({
	isSelected,
	isHovered,
	onSelectPlacement,
	placement,
	loading,
}: Props) => {
	const handleSelectPlacement = (e: React.ChangeEvent<HTMLInputElement>) => {
		const psc = placement.placement_showing_count?.[0]

		if (!psc) {
			return
		}

		const selectedPlacement: SelectedPlacement = {
			pk: placement.pk,
			placementType: placement.placement_type_name,
			name: placement.name,
			videoDurations: placement.video_durations,
			image: placement.image_url || defaultPlacementImage,

			psc: {
				pk: psc.pk,
				price: psc.price,
				videoDuration: psc.video_duration,
			},
		}

		onSelectPlacement(selectedPlacement, !isSelected)
	}

	return (
		<div
			onClick={(e) => e.stopPropagation()}
			style={{
				position: 'absolute',
				padding: '0 4px 4px 0',
				borderRadius: '0 0 5px 0 ',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: isSelected
					? '#e6f2ff'
					: isHovered
					? '#F5F5F5'
					: '#fff',
			}}
		>
			<Form.Check
				disabled={loading}
				className={s.customCheckbox}
				type="checkbox"
				checked={isSelected}
				onChange={handleSelectPlacement}
				style={{
					width: '20px',
					height: '20px',
				}}
			/>
		</div>
	)
}
