import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { DocsList } from './DocsList'

type DocumentsUploadFormProps = {
	adFormatId: number
	type:
		| 'vk'
		| 'yandex'
		| 'teletarget'
		| 'adstream'
		| 'booking'
		| 'public_transport'
		| 'districtbooking'
		| 'metro'
	campaignId: string
	title: string
	onDocumentLoaded?: React.Dispatch<React.SetStateAction<boolean>>
	styles?: React.CSSProperties
}
//Компонент использует код из CreateReportPlacementHeader, нужно вынести в общий компонент
export const DocumentsUploadForm = ({
	adFormatId,
	campaignId,
	type,
	onDocumentLoaded,
	styles,
}: DocumentsUploadFormProps) => {
	const [isLoading, setIsLoading] = useState(false)

	return (
		<Form.Group style={styles}>
			<Form.Label>
				<div
					style={{
						display: 'flex',
						gap: '10px',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					Загрузка документов
					{isLoading && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Spinner
								style={{ color: '#007BFF' }}
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
								className="me-2"
							/>
						</div>
					)}
				</div>
			</Form.Label>

			<DocsList
				onDocumentLoaded={onDocumentLoaded}
				setIsLoading={setIsLoading}
				adFormatId={adFormatId}
				type={type}
				campaignId={campaignId}
			/>
		</Form.Group>
	)
}
