import useCampaignData from '../../../../../stores/campaignData'
import { useMemo } from 'react'
import {
	Bid_Keys,
	Bid_Response,
	DistrictBookingExternalType,
} from '../../../../../stores/ADMarketTypes.types'

export type bidInfo = {
	type: Bid_Keys | 'districtbooking' | 'metro'
	id: number
	external?: DistrictBookingExternalType
}

type ReturnType = {
	isHaveBidRequest: (bidInfo: bidInfo) => Bid_Response | false | undefined
}

const useBidRequest = (): ReturnType => {
	const [campaign] = useCampaignData()

	return {
		isHaveBidRequest: (bidInfo) => {
			const bidRequests = campaign.bid_requests[bidInfo.type]

			if (bidInfo.type === 'districtbooking') {
				if (bidRequests) {
					const bid = bidRequests.find(
						(el) =>
							el.id === bidInfo.id &&
							el?.type === bidInfo.external
					)
					return bid
				} else {
					return false
				}
			} else {
				if (bidRequests) {
					const bid = bidRequests.find((el) => el.id === bidInfo.id)
					return bid
				} else {
					return false
				}
			}
		},
	}
}

export default useBidRequest
