import React, { useMemo } from 'react'
import {
	PlacementsPickerProps,
	SelectedPlacement,
} from '../../../../common/PlacementsPickerContent'
import { Button, ButtonGroup } from 'react-bootstrap'
import { formattedNumber } from 'src/utils'

type Props = {} & Pick<
	PlacementsPickerProps,
	| 'selectedPlacements'
	| 'onApplyCommonDuration'
	| 'onAddSelectedToCart'
	| 'isAllSelectedInAdded'
	| 'onRemovePlacementsFromCart'
>
export const ListFooter = ({
	selectedPlacements,
	onApplyCommonDuration,
	onAddSelectedToCart,
	isAllSelectedInAdded,
	onRemovePlacementsFromCart,
}: Props) => {
	const commonVideoDurations = useMemo(() => {
		const durations = selectedPlacements
			.map((obj) => new Set(obj.videoDurations))
			.reduce(
				(acc, set) => new Set([...acc].filter((num) => set.has(num)))
			)

		return Array.from(durations)
	}, [selectedPlacements])

	const activeCommonDuration = useMemo(() => {
		const usedVideoDurations = selectedPlacements.map(
			(el) => el.psc.videoDuration
		)

		// Check if all durations in usedVideoDurations are the same and if it exists in commonVideoDurations
		const isUniqueDuration = new Set(usedVideoDurations).size === 1
		const commonDuration = usedVideoDurations[0]

		return isUniqueDuration && commonVideoDurations.includes(commonDuration)
			? commonDuration
			: null
	}, [commonVideoDurations, selectedPlacements])

	const totalAmountPerDay = useMemo(() => {
		return selectedPlacements.reduce(
			(acc, el) => acc + parseFloat(el.psc.price),
			0
		)
	}, [selectedPlacements])

	const handleButtonClick = () => {
		if (isAllSelectedInAdded) {
			const ids = selectedPlacements.map((el) => el.pk)
			onRemovePlacementsFromCart(ids)
		} else {
			onAddSelectedToCart()
		}
	}

	const buttonTitle = isAllSelectedInAdded
		? 'Убрать из кампании'
		: 'Добавить в кампанию'

	return (
		<div
			style={{
				height: 'fit-content',
				flexShrink: 0,
				boxShadow: '0px -4px 10px rgba(33, 37, 41, 0.15)',
				padding: '16px',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				{commonVideoDurations.length > 0 && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '8px',
						}}
					>
						<p className="m-0">Длительность показа (в секундах):</p>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<ButtonGroup
								aria-label="commonVideoDurations_select"
								style={{
									borderRadius: '12px',
									overflow: 'hidden',
								}}
							>
								{commonVideoDurations.map((duration) => (
									<Button
										onClick={() =>
											onApplyCommonDuration(duration)
										}
										key={duration}
										variant={
											duration === activeCommonDuration
												? 'primary'
												: 'secondary'
										}
									>
										{duration}
									</Button>
								))}
							</ButtonGroup>

							<h6 className="m-0">
								{formattedNumber(totalAmountPerDay)} ₽ / день
							</h6>
						</div>
					</div>
				)}

				<Button
					variant={isAllSelectedInAdded ? 'danger' : 'primary'}
					onClick={handleButtonClick}
					style={{
						borderRadius: '16px',
						height: '48px',
						fontWeight: 500,
						fontSize: '20px',
						lineHeight: '30px',
					}}
				>
					{buttonTitle}
				</Button>
			</div>
		</div>
	)
}
