import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import YandexMap from 'src/components/UtilityComponents/YandexMap'
import useCampaignData from 'src/stores/campaignData'
import { DoorType } from 'src/stores/technical-requirements-store'
import useResponsive from 'src/utils/useResponsive'
import { BookingsMapQuickView } from '../../new-adformats-step/ui/common/bookings-map-quick-view/BookingsMapQuickView'
import ModalYandexMap from 'src/components/UtilityComponents/ModalYandexMap'
import PlacementCard from '../PlacementCard'
import { formattedNumber } from 'src/utils'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { PlacementsPickerWrapper } from '../PlacementPicker/placements-picker-new/ui/common/PlacementsPickerWrapper'

type Props = {}
export const IndoorBooking = ({}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [modalMapOpen, setModalMapOpen] = useState(false)
	const [placementPickerOpen, setPlacementPickerOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	const companyCoord =
		campaign.company.actual_location_lat &&
		campaign.company.actual_location_lon
			? {
					lat: +campaign.company.actual_location_lat,
					lon: +campaign.company.actual_location_lon,
			  }
			: undefined

	const bookings = useMemo(
		() =>
			campaign.booking.filter(
				(el) => el.placement.door_type === DoorType.indoor
			),
		[campaign]
	)
	const bookingsCoord = bookings.map((el) => ({
		lat: +el.placement.lat,
		lon: +el.placement.lon,
		title: el.placement.placement_type.name,
	}))

	const totals = useMemo(() => {
		const totals = { shows: 0, price: 0 }
		for (const booking of bookings) {
			totals.shows += parseFloat(booking.placement.ots_per_day || '0')
			totals.price += booking.placement_showing_count.ac_price
		}
		return totals
	}, [bookings])

	async function handleDeleteBooking(id) {
		setLoading(true)
		await campaignInterface.deleteBooking(id, false)
		await campaignInterface.refetchSelected()
		setLoading(false)
	}
	const handleDeleteAllBookings = async () => {
		setLoading(true)

		await campaignInterface.deleteAllBookingsByType(DoorType.indoor, false)

		await campaignInterface.refetchSelected()
		setLoading(false)
	}
	const { isMobile } = useResponsive()
	return (
		<>
			{bookings.length !== 0 && (
				<BookingsMapQuickView
					bookingsCoord={bookingsCoord}
					bookingsType="indoor"
					companyCoord={companyCoord}
					styles={{
						borderRadius: isMobile ? '12px' : '16px',
						height: '250px',
						overflow: 'hidden',
					}}
					showOnMapClick={() => setModalMapOpen(true)}
				/>
			)}

			<div style={{ marginTop: '16px' }}>
				{bookings.map((data, i) => (
					<React.Fragment key={i}>
						<PlacementCard
							data={data}
							onDelete={handleDeleteBooking}
							isMobile={isMobile}
							key={data.id}
						/>
					</React.Fragment>
				))}
			</div>

			{isMobile ? (
				<>
					{!!bookings.length && (
						<div className="d-flex">
							<Button
								className="flex-grow-1 "
								variant={'danger'}
								onClick={handleDeleteAllBookings}
								disabled={loading}
							>
								Удалить все
							</Button>
						</div>
					)}
					<div className="d-flex">
						<Button
							className="flex-grow-1 mt-2"
							variant={'primary'}
							onClick={() => setPlacementPickerOpen(true)}
							disabled={loading}
						>
							<i className="bi bi-plus-lg me-2" />
							Добавить места
						</Button>
					</div>
				</>
			) : (
				<div className="d-flex">
					<Button
						className="flex-grow-1"
						variant={'primary'}
						onClick={() => setPlacementPickerOpen(true)}
						disabled={loading}
					>
						<i className="bi bi-plus-lg me-2" />
						Добавить места
					</Button>
					{!!bookings.length && (
						<Button
							className="flex-grow-0 ms-3"
							variant={'danger'}
							onClick={handleDeleteAllBookings}
							disabled={loading}
						>
							Удалить все
						</Button>
					)}
				</div>
			)}
			{bookings.length !== 0 && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
						marginTop: '16px',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
						}}
					>
						<h6 className="m-0">Всего рекламных мест:</h6>
						<h6 className="m-0">{bookings.length}</h6>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
						}}
					>
						<h6 className="m-0">Всего рекламу увидят:</h6>
						<h6 className="m-0">
							~ {formattedNumber(totals.shows)} чел./день
						</h6>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<h6 className="m-0">Стоимость всего:</h6>
						<h6 className="m-0">
							{IntlFormatter.format(totals.price)}{' '}
						</h6>
					</div>
				</div>
			)}

			{placementPickerOpen && (
				<PlacementsPickerWrapper
					onClose={() => setPlacementPickerOpen(false)}
					open={placementPickerOpen}
				/>
			)}

			{modalMapOpen && (
				<ModalYandexMap
					show={modalMapOpen}
					onHide={() => setModalMapOpen(false)}
					modalTitle="Выбранные места внутренней рекламы"
					fullScreen={true}
					yMapProps={{
						width: '100%',
						height: '100%',
						area: true,
						zoom: 12,
						withZoomControls: true,
						isMobile: isMobile,
						buisnessCoords: [0, 0],
						smallData: bookings.map((el) => ({
							lat: el.placement.lat,
							lon: el.placement.lon,
						})),
					}}
				/>
			)}
		</>
	)
}
