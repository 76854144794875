import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import { MediaplanResponse } from '../model/apiTypes'
import { MediaplanTableRow } from '../model/tableModel'
import { mapBookings } from './mapBookings'
import { mapDistrictBookings } from './mapDistrictBookings'
import { booking } from 'src/constants/api'
import { DoorType } from 'src/stores/technical-requirements-store'
import { mapPublicTransport } from './mapPublicTransport'
import { mapMetro } from './mapMetro'
import { mapInternet } from './mapInternet'
import { InternetVariants } from 'src/components/_client/create-campaign/Step2-ADformats/new-adformats-step/types'
import { mapAdstream } from './mapAdstream'

export const getTableRowsData = (
	mediaplanResponse: MediaplanResponse,
	campaign: CampaignInfoTypeBookingsSeparation
) => {
	const campaignRegions = campaign.regions
		.map((region) => region.title)
		.join(', ')
	const campaignAges = campaign.ages.map((age) => age.title).join(', ')
	const campaignGenders = campaign.genders.map((age) => age.title).join(', ')

	const rows: MediaplanTableRow[] = []

	if (mediaplanResponse.district_adv_is_selected) {
		const row = mapDistrictBookings(mediaplanResponse.district_booking)
		rows.push(row)
	}

	if (mediaplanResponse.outdoor_adv_is_selected) {
		const row = mapBookings({
			bookings: mediaplanResponse.booking,
			type: DoorType.outdoor,
			extra: { campaignRegions },
		})

		rows.push(row)
	}

	if (mediaplanResponse.indoor_adv_is_selected) {
		const row = mapBookings({
			bookings: mediaplanResponse.booking,
			type: DoorType.indoor,
			extra: { campaignRegions },
		})

		rows.push(row)
	}

	if (mediaplanResponse.public_transport.is_selected) {
		const row = mapPublicTransport({
			publicTransport: mediaplanResponse.public_transport,
			extra: { isDividerRow: !mediaplanResponse.metro.is_selected },
		})

		rows.push(row)
	}

	if (mediaplanResponse.metro.is_selected) {
		const row = mapMetro(mediaplanResponse.metro)

		rows.push(row)
	}

	if (mediaplanResponse.yandex.is_selected) {
		const row = mapInternet({
			extra: {
				age: campaignAges,
				gender: campaignGenders,
				region: campaignRegions,
				isDividerRow: !mediaplanResponse.vk.is_selected,
			},
			internet: mediaplanResponse.yandex,
			type: InternetVariants.Yandex,
		})

		rows.push(row)
	}

	if (mediaplanResponse.vk.is_selected) {
		const row = mapInternet({
			extra: {
				age: campaignAges,
				gender: campaignGenders,
				region: campaignRegions,
				isDividerRow: true,
			},
			internet: mediaplanResponse.vk,
			type: InternetVariants.Vk,
		})

		rows.push(row)
	}
	if (mediaplanResponse.adstream.is_selected) {
		const row = mapAdstream({
			extra: {
				age: campaignAges,
				gender: campaignGenders,
				region: campaignRegions,
			},
			adstream: mediaplanResponse.adstream,
		})

		rows.push(row)
	}

	return rows
}
