import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../../styles/placement-info.scss'
import bem from '../../../utils/bem'
import ImageFullScreenWrapper from '../../UtilityComponents/ImageFullScreenWrapper'
import { PlacementBadge } from './PlacementsPage'
import ProviderYandexMapNew from '../Bookings/content/details/ProviderYandexMapNew'
import useProviderData from 'src/stores/providerData'
import { PlacementsMap } from './components'
import { DisplayPlacementPriceString } from 'src/components/_client/create-campaign/Step2-ADformats/BookingsAD/PlacementPicker/placement-details/PlacementDetails'

interface IReklamnieNositeliInfo {
	item: any
	withControlsButtons?: boolean
	onEdit: (itemId?: string | undefined) => void
	onArchive: (id) => Promise<void>
	onRestore: (id) => Promise<void>
	onDelete: (id) => Promise<void>
	onDisable: (id) => Promise<void>
	onEnable: (id) => Promise<void>
	isTablet: boolean
}

const cn = bem('reklamnie-nositeli-info')

const PlacementInfo: React.FC<IReklamnieNositeliInfo> = ({
	item,
	withControlsButtons = true,
	onEdit,
	onArchive,
	onRestore,
	onDelete,
	isTablet,
	onDisable,
	onEnable,
}) => {
	const [provider] = useProviderData()

	const [showModalMap, setShowModalMap] = useState(false)
	if (!item) return null

	return (
		<>
			<div style={{ overflowY: 'auto', padding: '24px' }}>
				<div className={cn('image_container')}>
					<ImageFullScreenWrapper>
						<img src={item.image} alt={item.name} />
					</ImageFullScreenWrapper>
				</div>
				<div className={'mt-3 d-flex justify-content-between'}>
					<h5>{item.name}</h5>
					<div className={'ms-2'}>
						<PlacementBadge
							status={item.status}
							is_active={item.is_active}
							activation_date={item.activation_date}
						/>
					</div>
				</div>
				{item.description && (
					<div className={'w-100'}>{item.description}</div>
				)}
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Идентификатор:</span>
					<span style={{ textAlign: 'right' }}>{item.id}</span>
				</div>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Медиа-формат:</span>
					<span style={{ textAlign: 'right' }}>
						<Link
							to={
								'/provider/media-formats?id=' +
								item.media_type.id
							}
						>
							{item.media_type.name}
						</Link>
					</span>
				</div>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Тип:</span>
					<span style={{ textAlign: 'right' }}>
						<a
							href="/"
							onClick={(e) => {
								e.preventDefault()
							}}
						>
							{item.placement_type.name}
						</a>
					</span>
				</div>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Размер:</span>
					<span style={{ textAlign: 'right' }}>{item.size}</span>
				</div>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Рекламу увидят:</span>
					<span style={{ textAlign: 'right' }}>
						{item?.ots ? (
							`${parseFloat(item?.ots_per_day || '0')} чел./день`
						) : (
							<span className={'text-secondary'}>Неизвестно</span>
						)}{' '}
					</span>
				</div>
				{item?.media_type?.duration && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Длительность показа:
						</span>
						<span style={{ textAlign: 'right' }}>
							{item?.media_type?.duration} секунд
						</span>
					</div>
				)}

				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>График показов:</span>
					<div style={{ textAlign: 'right' }}>
						{item.only_work_days
							? 'Только рабочие дни, '
							: 'В рабочие и выходные дни, '}
						<br />{' '}
						{item?.works_on_holidays
							? 'включая праздничные'
							: 'не включая праздничные'}
					</div>
				</div>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Цена за показы:</span>
					<span style={{ textAlign: 'right' }}>
						{item.placement_showing_count.map(
							({ showing_count_per_day, price, id }) => (
								<div key={id}>
									{DisplayPlacementPriceString(
										showing_count_per_day,
										price
									)}
								</div>
							)
						)}
					</span>
				</div>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>
						Координаты на карте:
					</span>
					<span style={{ textAlign: 'right' }}>
						{item.lat.replace('.', '°') + "'N"},{' '}
						{item.lon.replace('.', '°') + "'E"}
					</span>
				</div>
				<div
					style={{ width: '100%', height: '200px' }}
					className={
						'rounded-2 overflow-hidden mt-3 mb-3 position-relative'
					}
				>
					<ProviderYandexMapNew
						width={'100%'}
						height={'100%'}
						zoom={10}
						placements={[item]}
						fixed
						onClick={() => setShowModalMap(true)}
						isMobile={true}
					/>
					<Button
						variant={'secondary'}
						size={'sm'}
						className={'position-absolute'}
						style={{ top: '75%', right: '15px' }}
						onClick={() => setShowModalMap(true)}
					>
						Смотреть на карте
					</Button>
				</div>
			</div>
			{withControlsButtons && item.state !== 'used' && (
				<ControlsButtons
					item={item}
					onEdit={onEdit}
					onArchive={onArchive}
					onRestore={onRestore}
					onDelete={onDelete}
					onEnable={onEnable}
					onDisable={onDisable}
				/>
			)}

			<PlacementsMap
				modalTitle="Местоположение рекламного носителя"
				singleCoords={[
					item.lat,
					item.lon,
					item.placement_type?.image,
					item.placement_type?.name,
					item.size,
				]}
				onHide={() => setShowModalMap(false)}
				placements={[item]}
				show={showModalMap}
			/>
		</>
	)
}

export const ControlsButtons = ({
	item,
	onEdit,
	onArchive,
	onRestore,
	onDelete,
	onEnable,
	onDisable,
}) => {
	if (!item) return null
	if (item.in_archive)
		return (
			<div className={cn('controls')}>
				<div
					className={'control-btn'}
					onClick={() => {
						onRestore(item.id)
					}}
				>
					<i className="bi bi-box-arrow-up" />
					<span style={{ fontWeight: 500 }}>Восстановить</span>
				</div>
				<div
					className="control-btn"
					onClick={() => {
						onDelete(item.id)
					}}
				>
					<i className="bi bi-trash" />
					<span style={{ fontWeight: 500 }}>Удалить навсегда</span>
				</div>
			</div>
		)
	return (
		<div className={cn('controls')}>
			{item?.is_active && (
				<div
					className="control-btn"
					onClick={() => {
						onDisable(item.id)
					}}
				>
					<i className="bi bi-dash-circle" />
					<span style={{ fontWeight: 500 }}>Выключить</span>
				</div>
			)}
			{!item?.is_active && (
				<div
					className="control-btn"
					onClick={() => {
						onEnable(item.id)
					}}
				>
					<i className="bi bi-plus-circle" />
					<span style={{ fontWeight: 500 }}>Включить</span>
				</div>
			)}

			<div className={'control-btn'} onClick={() => onEdit(item.id)}>
				<i className="bi bi-pencil" />
				<span style={{ fontWeight: 500 }}>Изменить</span>
			</div>

			<div
				className="control-btn"
				onClick={() => {
					onArchive(item.id)
				}}
			>
				<i className="bi bi-trash" />
				<span style={{ fontWeight: 500 }}>Удалить</span>
			</div>
		</div>
	)
}
export default PlacementInfo
