import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import InputWithEndText from 'src/components/UtilityComponents/InputWithEndText'
import { BASE_URL } from 'src/constants/api'
import useCampaignData from 'src/stores/campaignData'
import { authFetch } from 'src/utils/authFetch'
import useDebounce from 'src/utils/useDebounce'
import useResponsive from 'src/utils/useResponsive'
import { getError } from 'src/utils/getError'
import { NumericFormat } from 'react-number-format'
import { useAdformatsStore } from '../../../adformats-store/adformats.store'

type MCRFields = {
	amount: string
}
type MCRErrors = {
	amount: string | undefined
}
type Props = {}
export const MoscowCentRingControls = ({}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)
	const removeAdFormatErrorByErrorId = useAdformatsStore(
		(state) => state.removeAdFormatErrorByErrorId
	)
	const minBudget = useMemo(() => {
		return campaign.public_transport?.min_budget || 0
	}, [campaign])

	const actualAmount = useMemo(() => {
		return parseFloat(campaign.public_transport?.amount || '0')
	}, [campaign])

	const [amount, setAmount] = useState(
		campaign?.public_transport?.amount || '0'
	)

	const debouncedAmount = useDebounce(amount, 400)

	const [errors, setErrors] = useState<MCRErrors>(() => ({
		amount:
			parseFloat(amount) < minBudget
				? `Минимальный бюджет ${minBudget} ₽`
				: undefined,
	}))

	const handleValidate = useCallback(
		(field: keyof MCRFields, value: string | number) => {
			const validateErrors: MCRErrors = {
				...errors,
			}

			if (field === 'amount') {
				const validValue = parseFloat(value as string)

				if (value === '') {
					validateErrors.amount = 'Добавьте бюджет'
				} else if (isNaN(validValue)) {
					validateErrors.amount = 'Некорректное значение'
				} else if (validValue < minBudget) {
					validateErrors.amount = `Минимальный бюджет ${minBudget} ₽`
				} else {
					validateErrors.amount = undefined
				}
			}
			return validateErrors
		},
		[errors, minBudget]
	)

	const updateMCR = async (field: keyof MCRFields, value: string) => {
		try {
			const validationResult = handleValidate(field, value)

			if (validationResult[field]) {
				setErrors(validationResult)
				return
			}

			const body = { [field]: value }

			//сброс isSelected state
			if (campaign.public_transport?.is_selected) {
				Object.assign(body, { is_selected: false })
			}

			await authFetch({
				method: 'PATCH',
				url: `${BASE_URL}/adv_companies/${campaign.id}/transport-adv/`,
				body,
			})
			await campaignInterface.refetchSelected()
			removeAdFormatErrorByErrorId('mcr', 'mcr_amount')
			setErrors({ amount: '' })
		} catch (e: any) {
			const error = getError(e)

			if (error?.data?.amount) {
				setErrors((prev) => ({
					...prev,
					amount: error.data.amount,
				}))
			}
		} finally {
		}
	}

	const handleAmountChange = (value: string | undefined) => {
		if (value) {
			const newAmount = value
				.replaceAll(' ', '')
				.replace('₽', '')
				.replace(',', '.')
			setAmount(newAmount)
		} else {
			setErrors({ amount: 'Введите бюджет' })
		}
	}
	useEffect(() => {
		if (actualAmount !== parseFloat(amount)) {
			updateMCR('amount', debouncedAmount)
		} else {
			const validateErrors = handleValidate('amount', amount)
			setErrors(validateErrors)
		}
	}, [debouncedAmount, actualAmount])

	useEffect(() => {
		if (!!errors.amount) {
			setAdFormatErrors('mcr', [
				{
					id: 'mcr_amount',
					field: 'Бюджет',
					message: errors.amount || 'Ошибка в бюджете',
				},
			])
		} else {
			removeAdFormatErrorByErrorId('mcr', 'mcr_amount')
		}
	}, [errors])

	const { isMobile } = useResponsive()
	return (
		<>
			<Form>
				<Row
					style={{
						display: 'flex',
						flexDirection: isMobile ? 'column' : 'row',
						gap: isMobile ? '1rem' : 0,
					}}
				>
					<Form.Group as={Col} id="mcr_amount">
						<Form.Label>Бюджет</Form.Label>

						<NumericFormat
							decimalScale={2}
							decimalSeparator=","
							value={amount}
							suffix=" ₽"
							thousandSeparator={' '}
							customInput={InputWithEndText}
							onChange={(e) => handleAmountChange(e.target.value)}
							size={'lg'}
							isInvalid={!!errors.amount}
							FeedBackComponent={
								<Form.Control.Feedback type="invalid">
									{errors.amount}
								</Form.Control.Feedback>
							}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Оплата</Form.Label>
						<Form.Control
							type={'text'}
							value={'За показы'}
							disabled
							size={'lg'}
						/>
					</Form.Group>
				</Row>
			</Form>
		</>
	)
}
