import React, { useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import YandexMap from 'src/components/UtilityComponents/YandexMap'
import useCampaignData from 'src/stores/campaignData'
import { PlacementsPickerProps } from '../common/PlacementsPickerContent'
import { LeftPanel } from './left-panel/LeftPanel'
import { RightPanel } from './right-panel/RightPanel'
import { PlacementShortWithStateAndFocus } from '../../../../BookingAD'

export const PlacementsPickerDesktop = ({
	placements,
	handleFocusPlacement,
	buisnessCoords,
	addedPlacements,
	selectedPlacements,
	onSelectPlacement,
	onApplyCommonDuration,
	onAddSelectedToCart,
	onRemovePlacementsFromCart,
	isAllSelectedInAdded,
	getDetailedPlacement,
	detailedPlacementId,
	onCloseDetailedPlacement,
	onAddDetailedPlacement,
	onChangePlacementsFilter,
	onSelectAll,
	placementTypes,
	focusCenter,
	onFocusCenterChange,
	onClose,
	loading,
}: PlacementsPickerProps) => {
	const [campaign, _] = useCampaignData()

	const placementsMapItems: PlacementShortWithStateAndFocus[] =
		useMemo(() => {
			return placements.map((el) => {
				const placementWithState: PlacementShortWithStateAndFocus = {
					...el,
					focus: detailedPlacementId === el.pk ? 'focus' : null,
					state: selectedPlacements.find(
						(selected) => selected.pk === el.pk
					)
						? 'selected'
						: 'default',
				}
				return placementWithState
			})
		}, [placements, selectedPlacements, detailedPlacementId])
	return (
		<div
			style={{
				position: 'relative',
				maxWidth: '100vw',
				overflow: 'hidden',
				height: '100%',
			}}
		>
			<LeftPanel
				loading={loading}
				onRemovePlacementsFromCart={onRemovePlacementsFromCart}
				onFocusCenterChange={onFocusCenterChange}
				placementTypes={placementTypes}
				onSelectAll={onSelectAll}
				onChangePlacementsFilter={onChangePlacementsFilter}
				onAddDetailedPlacement={onAddDetailedPlacement}
				onCloseDetailedPlacement={onCloseDetailedPlacement}
				addedPlacements={addedPlacements}
				detailedPlacementId={detailedPlacementId}
				getDetailedPlacement={getDetailedPlacement}
				isAllSelectedInAdded={isAllSelectedInAdded}
				onAddSelectedToCart={onAddSelectedToCart}
				onApplyCommonDuration={onApplyCommonDuration}
				onSelectPlacement={onSelectPlacement}
				placements={placements}
				selectedPlacements={selectedPlacements}
			/>

			<YandexMap
				width={'100%'}
				height={'100%'}
				zoom={14}
				data={placementsMapItems}
				onPlacemarkClick={getDetailedPlacement}
				focusCenter={focusCenter}
				buisnessCoords={buisnessCoords}
				withZoomControls={false}
				changeCenter={focusCenter as unknown as [string, string]}
				bigBuisnessLabel={campaign.company.actual_location}
				isMobile={false}
				placementsType={'all'}
			/>

			<RightPanel
				onClose={onClose}
				getDetailedPlacement={getDetailedPlacement}
				detailedPlacementId={detailedPlacementId}
				addedPlacements={addedPlacements}
				onRemovePlacementsFromCart={onRemovePlacementsFromCart}
			/>
		</div>
	)
}
