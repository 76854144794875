import React, {
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { PlacementShortWithStateAndFocus } from '../../../../BookingAD'
import useResponsive from 'src/utils/useResponsive'
import { PlacementsPickerMobile } from '../mobile/PlacementsPickerMobile'
import { PlacementsPickerDesktop } from '../desktop/PlacementsPickerDesktop'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import useCampaignData from 'src/stores/campaignData'
import {
	DetailedPlacementType,
	DetailedPsc,
	PlacementsFilter,
} from '../../model/types'
import { DoorType } from 'src/stores/technical-requirements-store'
import { addCartPlacementsToCampaign } from '../../api/addCartPlacementsToCampaign'

export type SelectedPsc = {
	pk: number
	price: string
	videoDuration: number
}
export type SelectedPlacement = {
	pk: number
	image: string | null
	placementType: string
	name: string
	videoDurations: number[]

	psc: SelectedPsc
}
export type PlacementsPickerProps = {
	placements: PlacementShort[]
	buisnessCoords?: [string, string]
	handleFocusPlacement: () => void
	dynamicCenter?: string[]
	selectedPlacements: SelectedPlacement[]
	onSelectPlacement: (
		placement: SelectedPlacement,
		isSelected: boolean
	) => void
	addedPlacements: SelectedPlacement[]
	onAddSelectedToCart: () => void
	onApplyCommonDuration: (videoDuration: number) => void
	onRemovePlacementsFromCart: (placementIds: number[]) => void
	isAllSelectedInAdded: boolean
	getDetailedPlacement: (placementId: number | null) => void
	detailedPlacementId: number | null
	onCloseDetailedPlacement: () => void
	onAddDetailedPlacement: (
		detailedPlacement: DetailedPlacementType,
		selectedPsc: DetailedPsc
	) => void
	onChangePlacementsFilter: (filter: Partial<PlacementsFilter>) => void
	onSelectAll: (selectAll: boolean) => void
	placementTypes: string[]
	focusCenter: [number, number]
	onFocusCenterChange: (coords: [number, number]) => void
	onClose: () => void
	loading: boolean
}

type Props = {
	onClose: () => void
	placements: PlacementShort[]
	onChangePlacementsFilter: (filter: Partial<PlacementsFilter>) => void
	placementTypes: string[]
	focusCenter: [number, number]
	onFocusCenterChange: (coords: [number, number]) => void
	onSortPlacements: (addedPlacements: SelectedPlacement[]) => void
	loading: boolean
}

export const PlacementsPickerContent = ({
	placements,
	onChangePlacementsFilter,
	placementTypes,
	focusCenter,
	onFocusCenterChange,
	onSortPlacements,
	onClose,
	loading,
}: Props) => {
	const { isMobile } = useResponsive()
	const [campaign, campaignInterface] = useCampaignData()

	const [selectedPlacements, setSelectedPlacements] = useState<
		SelectedPlacement[]
	>([])
	const [detailedPlacementId, setDetailedPlacementId] = useState<
		number | null
	>(null)

	const [addedPlacements, setAddedPlacements] = useState<SelectedPlacement[]>(
		() => {
			const bookings = campaign.booking.filter(
				(el) => el.placement.door_type === DoorType.indoor
			)

			const mappedPlacements: SelectedPlacement[] = placements
				.filter((el) =>
					bookings.some((booking) => booking.placement.id === el.pk)
				)
				.map((placement) => {
					const addedPlacement: SelectedPlacement = {
						image: placement.image_url,
						name: placement.name,
						pk: placement.pk,
						videoDurations: placement.placement_showing_count.map(
							(el) => el.video_duration
						),
						placementType: placement.placement_type_name,

						psc: {
							pk: placement.placement_showing_count[0].pk,
							price: placement.placement_showing_count[0].price,
							videoDuration:
								placement.placement_showing_count[0]
									.video_duration,
						},
					}
					return addedPlacement
				})

			return mappedPlacements
		}
	)

	const handleTogglePlacementSelect = (
		placement: SelectedPlacement,
		isSelected: boolean
	) => {
		if (isSelected) {
			setSelectedPlacements((prev) => [...prev, placement])
		} else {
			setSelectedPlacements((prev) =>
				prev.filter((el) => el.pk !== placement.pk)
			)
		}
	}

	const handleApplyCommonDuration = (videoDuration: number) => {
		const draftPlacements = [...selectedPlacements]

		for (let i = 0; i < selectedPlacements.length; i++) {
			const placementId = selectedPlacements[i].pk
			const draftPlacementIndex = draftPlacements.findIndex(
				(el) => el.pk === placementId
			)

			const placement = placements.find((el) => el.pk === placementId)

			if (placement && draftPlacementIndex !== -1) {
				const psc = placement.placement_showing_count.find(
					(el) => el.video_duration === videoDuration
				)

				if (psc) {
					const newPsc: SelectedPsc = {
						pk: psc.pk,
						price: psc.price,
						videoDuration: psc.video_duration,
					}
					draftPlacements[draftPlacementIndex] = {
						...draftPlacements[draftPlacementIndex],
						psc: {
							...newPsc,
						},
					}
				}
			}
		}

		setSelectedPlacements(draftPlacements)
	}

	const handleAddSelectedToCart = () => {
		const newAdded = [...addedPlacements]

		selectedPlacements.forEach((selected) => {
			const index = newAdded.findIndex((el) => el.pk === selected.pk)
			if (index !== -1) {
				// Update existing placement
				newAdded[index] = selected
			} else {
				// Add new placement
				newAdded.push(selected)
			}
		})
		onSortPlacements(newAdded)
		setAddedPlacements(newAdded)
	}

	const handleRemovePlacementFromCart = (placementIds: number[]) => {
		const updatedPlacements = addedPlacements.filter(
			(el) => !placementIds.includes(el.pk)
		)
		setAddedPlacements(updatedPlacements)
	}

	const handleCloseDetails = () => {
		setDetailedPlacementId(null)
	}

	const handleAddDetailedPlacementToCart = (
		detailedPlacement: DetailedPlacementType,
		selectedPsc: DetailedPsc
	) => {
		const mappedToSelected: SelectedPlacement = {
			image: detailedPlacement.image,
			name: detailedPlacement.name,
			pk: detailedPlacement.pk,
			videoDurations: detailedPlacement.placement_showing_counts.map(
				(el) => el.video_duration
			),

			placementType: detailedPlacement.placement_type.name,

			psc: {
				pk: selectedPsc.id,
				price: selectedPsc.price,
				videoDuration: selectedPsc.video_duration,
			},
		}

		const updateOrAddPlacement = (placements: SelectedPlacement[]) => {
			const index = placements.findIndex(
				(el) => el.pk === mappedToSelected.pk
			)
			if (index !== -1) {
				// Update existing placement
				return placements.map((el) =>
					el.pk === mappedToSelected.pk ? mappedToSelected : el
				)
			} else {
				// Add new placement
				return [...placements, mappedToSelected]
			}
		}
		const newAddedPlacements = updateOrAddPlacement(addedPlacements)

		onSortPlacements(newAddedPlacements)

		setAddedPlacements(newAddedPlacements)
		setSelectedPlacements((prev) => updateOrAddPlacement(prev))
	}

	const handleSelectAllPlacements = (selectAll: boolean) => {
		if (loading) return

		if (selectAll) {
			// Select all placements
			const allSelected: SelectedPlacement[] = placements.map(
				(placement) => ({
					pk: placement.pk,
					image: placement.image_url,
					placementType: placement.placement_type_name,
					name: placement.name,
					videoDurations: placement.placement_showing_count.map(
						(el) => el.video_duration
					),
					psc: {
						pk: placement.placement_showing_count[0].pk,
						price: placement.placement_showing_count[0].price,
						videoDuration:
							placement.placement_showing_count[0].video_duration,
					},
				})
			)
			setSelectedPlacements(allSelected)
		} else {
			// If all are selected, deselect all
			setSelectedPlacements([])
		}
	}

	const handleGetDetailedPlacement = (placementId: number | null) => {
		if (loading) {
			return
		}

		setDetailedPlacementId(placementId)

		if (!placementId) return
		const placement = placements.find((el) => el.pk === placementId)

		if (placement) {
			onFocusCenterChange([
				parseFloat(placement.lat),
				parseFloat(placement.lon),
			])
		}
	}

	const isAllSelectedInAdded = useMemo(() => {
		const addedPscIds = new Set(
			addedPlacements.map((placement) => placement.psc.pk)
		)

		return selectedPlacements.every((placement) =>
			addedPscIds.has(placement.psc.pk)
		)
	}, [selectedPlacements, addedPlacements])

	const placementsPickerProps: PlacementsPickerProps = {
		placements: placements,
		handleFocusPlacement: () => {},
		addedPlacements: addedPlacements,
		selectedPlacements: selectedPlacements,
		onSelectPlacement: handleTogglePlacementSelect,
		onApplyCommonDuration: handleApplyCommonDuration,
		onAddSelectedToCart: handleAddSelectedToCart,
		onRemovePlacementsFromCart: handleRemovePlacementFromCart,
		isAllSelectedInAdded: isAllSelectedInAdded,
		getDetailedPlacement: handleGetDetailedPlacement,
		detailedPlacementId: detailedPlacementId,
		onCloseDetailedPlacement: handleCloseDetails,
		onAddDetailedPlacement: handleAddDetailedPlacementToCart,
		onChangePlacementsFilter: onChangePlacementsFilter,
		onSelectAll: handleSelectAllPlacements,
		placementTypes: placementTypes,
		focusCenter: focusCenter,
		onFocusCenterChange: onFocusCenterChange,
		onClose: onClose,
		loading: loading,
	}

	useEffect(() => {
		setSelectedPlacements((prevSelected) =>
			prevSelected.filter((selected) =>
				placements.some((placement) => placement.pk === selected.pk)
			)
		)
	}, [placements])

	return isMobile ? (
		<PlacementsPickerMobile {...placementsPickerProps} />
	) : (
		<PlacementsPickerDesktop {...placementsPickerProps} />
	)
}
