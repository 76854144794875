import { DoorType } from 'src/stores/technical-requirements-store'
import { ForecastBooking } from '../model/apiTypes'
import { MediaplanTableRow, SubRow } from '../model/tableModel'

export const mapBookings = (input: {
	bookings: ForecastBooking[]
	type: DoorType
	extra?: { campaignRegions: string }
}): MediaplanTableRow => {
	const { bookings, type, extra } = input
	const formatName =
		type === DoorType.indoor
			? 'Внутренняя реклама'
			: 'Наружная реклама. Гарантированные показы'

	const filtredBookings = bookings.filter(
		(booking) => booking.placement.door_type === type
	)

	const totalFormatSums = {
		regions: extra?.campaignRegions || '',
		showing_count: 0,
		forecast_views: 0,
		amount: 0,
	}

	const subRows: SubRow[] = []

	for (const booking of filtredBookings) {
		totalFormatSums.showing_count += parseFloat(
			booking.placement.ots_ac || '0'
		)
		totalFormatSums.forecast_views += booking.forecast_views_count
		totalFormatSums.amount += booking.booking_budget

		const subRow: SubRow = {
			id: booking.id.toString(),
			role: 'data',
			data: {
				placementType: booking.placement.placement_type.name,
				address: booking.placement.name,
				gender: '',
				age: '',
				showing_count: parseFloat(booking.placement.ots_ac || '0'),
				forecast_views: booking.forecast_views_count,
				amount: booking.booking_budget,
			},
		}
		subRows.push(subRow)
	}

	const row: MediaplanTableRow = {
		id: `row_${type}`,
		isDividerRow: true,
		data: {
			region: totalFormatSums.regions,
			gender: '-',
			age: '-',
			showing_count: totalFormatSums.showing_count,
			forecast_views: totalFormatSums.forecast_views,
			amount: totalFormatSums.amount,
			format: {
				name: formatName,
				placementsCount: subRows.length,
			},
		},
		subrows: subRows,
		subrowsOpen: false,
	}

	return row
}
