import { BASE_URL } from 'src/constants/api'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import { authFetch } from 'src/utils/authFetch'
import { PlacementsFilter } from '../model/types'

export const fetchPlacements = async (filter: Partial<PlacementsFilter>) => {
	const queryParams = new URLSearchParams(
		Object.entries(filter)
			.filter(([_, value]) => value !== undefined) // Remove undefined values
			.reduce((acc, [key, value]) => {
				acc[key] = String(value) // Ensure all values are strings
				return acc
			}, {} as Record<string, string>)
	).toString()

	return await authFetch<PlacementShort[]>({
		url: `${BASE_URL}/adv_companies/placements/?${queryParams}`,
		method: 'GET',
	})
}
