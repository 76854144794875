import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Card, Dropdown, Modal } from 'react-bootstrap'
import {
	deleteCompanyMedia,
	uploadCompanyMedia,
} from '../../../../../../constants/api'
import {
	BookingType,
	PlacementShowingCountType,
	PlacementType,
} from '../../../../../../stores/ADMarketTypes.types'
import useCampaignData from '../../../../../../stores/campaignData'
import useResponsive from '../../../../../../utils/useResponsive'
import { spreadArray } from '../../../../../_provider/Mediaformats/MediaInfo'
import BottomSheet from '../../../../../UtilityComponents/Bottomsheet'
import ImageFullScreenWrapper from '../../../../../UtilityComponents/ImageFullScreenWrapper'
import s from '../../DataUpload.module.scss'
import { minMax } from './BookingUpload'
import CreativeFileInput from '../../../../../UtilityComponents/CreativeFileInput'
import useBidRequest, { bidInfo } from '../../CreateCreative/useBidRequest'
import { CreativeTemplateKeysType } from '../../constants'
import { TemplatesModal } from '..'
import { PlaceRequirements } from './place-requirements/PlaceRequirements'
import useNotificationData from 'src/stores/notificationsData'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { OrderLayoutModal } from '../order-layout-modal'
import { CreateCreativeFields } from '../../CreateCreative/CreateCreativeForm'
import { NewContentCreatedStatusType } from 'src/stores/types/newContendCreatedType'
import { formattedNumber } from 'src/utils'

interface IBookingUploadItem {
	booking: BookingType
	bookingType: 'indoor' | 'outdoor'
}

const BookingUploadItem: FC<IBookingUploadItem> = ({
	booking,
	bookingType,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()
	const { isHaveBidRequest } = useBidRequest()
	const [modalOpen, setModalOpen] = useState(false)

	const placementScreenResolution =
		`${booking.placement.media_type.min_width}*${booking.placement.media_type.min_height}` as CreativeTemplateKeysType

	const [_, notificationsInterface] = useNotificationData()

	const createdMaterialStatus = notificationsInterface.getNewContentCreated(
		booking.id.toString()
	) as NewContentCreatedStatusType | undefined

	const [loadingInProgress, setLoadingInProgress] = useState(false)

	const { addToast } = useContext(ToastsContext)

	const bidInfo: bidInfo = { id: booking.id, type: 'booking' }

	const [showDesktopModal, setShowDesktopModal] = useState<
		false | 'requirement' | 'info'
	>(false)
	const [showBottomModal, setShowBottomModal] = useState<
		false | 'requirement' | 'info'
	>(false)

	let file = useMemo(
		() =>
			campaign?.media.find(({ booking_id }) => booking.id === booking_id),
		[campaign?.media, booking]
	)

	const handleDesktopModalClose = () => setShowDesktopModal(false)

	const handleCreateMaterial = async () => {
		setModalOpen(true)
	}

	const closeModal = () => {
		setModalOpen(false)
	}

	const [isOrderLayoutModal, setIsOrderLayoutModal] = useState(false)

	const handleIsOrderLayoutOpen = () => {
		setModalOpen(false)
		setIsOrderLayoutModal(true)
	}
	const handleOrderLayoutSubmit = async (values: CreateCreativeFields) => {
		for (let key in values) {
			if (
				values[key] === null ||
				values[key] === undefined ||
				values[key] === ''
			) {
				delete values[key]
			}
		}

		await campaignInterface.createNewBidRequest(values, {
			booking_id: booking.id.toString(),
		})
		setIsOrderLayoutModal(false)
	}

	const handleStatusHasChanged = async (
		status: NewContentCreatedStatusType
	) => {
		if (status === 'start') {
			setLoadingInProgress(true)
		}
		if (status === 'end') {
			setLoadingInProgress(false)

			await campaignInterface.refetchSelected()
			addToast({
				text: `Загрузка контента для ${booking?.placement?.placement_type.name} завершена`,
				type: 'success',
			})
			notificationsInterface.setNewContendCreated({
				[booking.id.toString()]: 'pending',
			})
			return
		}
	}

	useEffect(() => {
		if (!!createdMaterialStatus) {
			handleStatusHasChanged(createdMaterialStatus)
		}
	}, [createdMaterialStatus])

	return (
		<>
			{modalOpen && (
				<TemplatesModal
					getOrderLayoutModal={handleIsOrderLayoutOpen}
					newContentKey={booking.id.toString()}
					placement={booking.placement}
					instanceId={booking.id.toString()}
					clsName={'booking'}
					campaingId={campaign.id}
					closeModal={closeModal}
					modalOpen={modalOpen}
					screenResolution={placementScreenResolution}
				/>
			)}
			{isOrderLayoutModal && (
				<OrderLayoutModal
					onSubmit={handleOrderLayoutSubmit}
					open={isOrderLayoutModal}
					onClose={() => setIsOrderLayoutModal(false)}
				/>
			)}
			<Card className={s.card} style={{ marginBottom: '8px' }}>
				<div className={'d-flex mb-3'}>
					<div className={'me-3'}>
						<ImageFullScreenWrapper>
							<img
								src={
									booking.placement.image ||
									'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
								}
								alt={booking.placement.name}
								style={{
									width: '80px',
									height: '80px',
									objectFit: 'cover',
									objectPosition: '50% 50%',
									borderRadius: '4px',
								}}
							/>
						</ImageFullScreenWrapper>
					</div>
					<div
						className={'d-flex flex-column justify-content-center'}
					>
						<h5 className={'mb-1'}>
							{booking?.placement?.placement_type.name}
						</h5>
						<p className={'m-0 text-secondary'}>
							{booking?.placement?.name}
						</p>
					</div>
				</div>
				<CreativeFileInput
					loadingInProgress={loadingInProgress}
					bidInfo={{ id: booking.id, type: 'booking' }}
					initialValue={
						file
							? {
									src: file.file,
									name:
										file.file_name || 'Текущее изображение',
							  }
							: {
									src: null,
									name: '',
							  }
					}
					updateUrl={uploadCompanyMedia}
					removeUrl={deleteCompanyMedia.params(file?.id || 0)}
					updateMethod={'POST'}
					nameInBody={'file'}
					additionalBody={{ booking_id: booking.id }}
					description={`Допустимые форматы — ${spreadArray([
						...(booking.placement.media_type?.image_formats
							? booking.placement.media_type.image_formats
							: []),
						...(booking.placement.media_type?.video_formats
							? booking.placement.media_type.video_formats
							: []),
					])}. Ширина: ${minMax(
						booking.placement.media_type.min_width,
						booking.placement.media_type.max_width,
						'px'
					)}, высота: ${minMax(
						booking.placement.media_type.min_height,
						booking.placement.media_type.max_height,
						'px'
					)} , не более 20 МБ. Длительность — 5 секунд.`}
					allowedExtension={[
						...(booking.placement.media_type?.image_formats
							? booking.placement.media_type.image_formats
							: []),
						...(booking.placement.media_type?.video_formats
							? booking.placement.media_type.video_formats
							: []),
					]}
					maxSizeMb={20}
					onFileLoad={() => campaignInterface.refetchSelected()}
					onFileDelete={async () =>
						await campaignInterface.refetchSelected()
					}
				/>
				<div
					className={isMobile ? 'd-grid' : 'd-flex'}
					style={{
						gap: isMobile ? '8px' : '16px',
						marginTop: '16px',
					}}
				>
					{!isHaveBidRequest(bidInfo) && !file && (
						<Button
							variant={'light'}
							onClick={handleCreateMaterial}
							style={{ width: isMobile ? '100%' : '50%' }}
						>
							Создать материал
						</Button>
					)}

					<Button
						variant={'light'}
						onClick={() => {
							if (isMobile) {
								setShowBottomModal('requirement')
							} else {
								setShowDesktopModal('requirement')
							}
						}}
						style={{ width: isMobile ? '100%' : '50%' }}
					>
						Требования к материалу
					</Button>
					<Button
						variant={'light'}
						onClick={() => {
							if (isMobile) {
								setShowBottomModal('info')
							} else {
								setShowDesktopModal('info')
							}
						}}
						style={{ width: isMobile ? '100%' : '50%' }}
					>
						Подробнее о месте
					</Button>
				</div>
			</Card>

			{/*Modals*/}

			{!isMobile && (
				<Modal
					className={s.modalContainer}
					show={showDesktopModal}
					onHide={handleDesktopModalClose}
					centered
					size={showDesktopModal === 'info' ? undefined : 'lg'}
					animation={true}
				>
					<Modal.Header closeButton className={s.modalHeader}>
						<Modal.Title as={'h5'}>
							{showDesktopModal === 'requirement' &&
								'Требования к загрузке материала'}
							{showDesktopModal === 'info' && 'Подробнее о месте'}
						</Modal.Title>
					</Modal.Header>

					{showDesktopModal === 'requirement' && (
						<Modal.Body>
							<PlaceRequirements placement={booking.placement} />
						</Modal.Body>
					)}
					{showDesktopModal === 'info' && (
						<InfoAboutPlace
							onHide={handleDesktopModalClose}
							placement={booking.placement}
							placement_showing_count={
								booking?.placement_showing_count
							}
						/>
					)}
				</Modal>
			)}
			{isMobile && (
				<BottomSheet
					show={Boolean(showBottomModal)}
					onHide={() => setShowBottomModal(false)}
					fullSize={true}
					bgOpacity={0.5}
					touchEvents={true}
				>
					<div className={'position-relative'}>
						{showBottomModal === 'requirement' ? (
							<PlaceRequirements placement={booking.placement} />
						) : (
							<InfoAboutPlace
								onHide={handleDesktopModalClose}
								placement={booking.placement}
								placement_showing_count={
									booking?.placement_showing_count
								}
							/>
						)}
					</div>
				</BottomSheet>
			)}
		</>
	)
}

export default BookingUploadItem

export const InfoAboutPlace = ({
	placement,
	onHide,
	placement_showing_count,
	withPrice = false,
	noMobileHeader = false,
}: {
	placement: Omit<PlacementType, 'placement_showing_counts'>
	onHide: () => any
	placement_showing_count: PlacementShowingCountType
	withPrice?: boolean
	noMobileHeader?: boolean
}) => {
	const [, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()
	return (
		<>
			{isMobile && !noMobileHeader && (
				<div
					style={{
						position: 'sticky',
						top: 0,
						left: 0,
						padding: '16px',
						borderBottom: '1px solid #CED4DA',
						backgroundColor: '#fff',
					}}
				>
					<h5 className={'mb-1'}>{placement?.placement_type.name}</h5>
					<p className={'m-0 text-secondary'}>{placement?.name}</p>
				</div>
			)}
			<div
				className={'p-0'}
				style={{ backgroundColor: '#fff', borderRadius: '8px' }}
			>
				{(!isMobile || noMobileHeader) && (
					<div
						style={{
							padding: isMobile ? '16px' : '40px 40px 24px 40px',
						}}
					>
						<h5 className={'mb-1'}>
							{placement?.placement_type.name}
						</h5>
						<p className={'m-0 text-secondary'}>
							{placement?.name}
						</p>
					</div>
				)}
				<div className={'d-flex justify-content-center'}>
					<ImageFullScreenWrapper>
						<img
							src={
								placement.image ||
								'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
							}
							alt={placement.name}
							style={{
								width: '100%',
								height: '320px',
								objectFit: 'cover',
								objectPosition: '50% 50%',
							}}
						/>
					</ImageFullScreenWrapper>
				</div>

				<div
					style={{
						padding: isMobile ? '16px' : '24px 40px 32px 40px',
					}}
					className={'d-grid'}
				>
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							Размер
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{placement?.size}
						</div>
					</div>
					<Dropdown.Divider className={'m-0 p-0'} />
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							Рекламу увидят
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{placement?.ots_per_day ? (
								`${formattedNumber(
									placement.ots_per_day || '0'
								)} чел./день`
							) : (
								<span className={'text-secondary'}>
									Неизвестно
								</span>
							)}
						</div>
					</div>
					<Dropdown.Divider className={'m-0 p-0'} />
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							График показов
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{placement?.only_work_days
								? 'Только рабочие дни, '
								: 'В рабочие и выходные дни, '}
							<br />{' '}
							{placement?.works_on_holidays
								? 'включая праздничные'
								: 'не включая праздничные'}
						</div>
					</div>
					<Dropdown.Divider className={'m-0 p-0'} />
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							Срок размещения
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{campaignInterface.getCampaignPeriod()}
						</div>
					</div>
					<Dropdown.Divider className={'m-0 p-0'} />
					<div className={'d-flex justify-content-between mt-2 mb-2'}>
						<div
							className={'text-secondary'}
							style={{ fontSize: '14px' }}
						>
							{placement?.only_work_days
								? 'Рабочих дней'
								: 'Всего дней'}
						</div>
						<div style={{ fontSize: '14px', textAlign: 'right' }}>
							{placement_showing_count.ac_days_count}
						</div>
					</div>
					{withPrice && (
						<>
							<Dropdown.Divider className={'m-0 p-0'} />
							<div className={'mt-3 mb-3'}>
								{`${parseInt(
									placement_showing_count.price
								)} ₽ в день за ${placement_showing_count.name}`}
							</div>
							<Dropdown.Divider className={'m-0 p-0'} />
							<div
								className={
									'd-flex justify-content-between mt-3'
								}
							>
								<h5 style={{ fontSize: '20px' }}>
									Стоимость за период:
								</h5>
								<h5
									style={{
										fontSize: '20px',
										textAlign: 'right',
									}}
								>
									{`${placement_showing_count.ac_price} ₽`}
								</h5>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	)
}
function addToast(arg0: { text: string; type: string }) {
	throw new Error('Function not implemented.')
}
