import React from 'react'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import { MoscowCentRing } from 'src/components/_client/create-campaign/Step2-ADformats/public-transport/components'

export const MCR = () => {
	return (
		<CardWrapper>
			<MoscowCentRing />
		</CardWrapper>
	)
}
