import React, { useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Search } from 'src/components/UtilityComponents'
import { MassCoverageYandexMap } from '../MassCoverageYandexMap'
import {
	LeftPanelFooter,
	LeftPanelPlacementList,
	MassCoverageDetailedPlacement,
	RadiusRange,
} from '../../left-panel'
import { formatSliderValue, getDetailedItemActionType } from '../../../../utils'
import { Address, Center, Placemark } from '../MassCoverageModalMap'
import { PlacementShort, PlacementType } from 'src/stores/ADMarketTypes.types'
import { motion } from 'framer-motion'
import { ReactComponent as Expand } from 'src/assets/images/go-left.svg'
import s from './mobileModal.module.scss'
import { AddedPlacementsMobile } from './AddedPlacementsMobile'
import { ReactComponent as Close } from 'src/assets/images/close cancel x.svg'
import { ToggleRightPannel } from '../../right-panel'

type Props = {
	open: boolean
	onClose: () => void
	center: Center
	setCenter?: React.Dispatch<React.SetStateAction<Center>>
	focusCenter: Center | null
	getCirlce?: (cirlce: any) => void
	radius: number
	items?: Array<[number, number]>
	objectManagerJSON: {
		type: string
		features: Placemark[]
	}
	onItemClick: (e: any) => Promise<void>
	isFooterDisabled: boolean
	isPlacementsTheSame: boolean
	itemsCount: number
	onRadiusChange: (value: number) => void
	addedPlacement: PlacementShort[]
	onAddPlacements: () => void
	setDetailedPlacement: React.Dispatch<
		React.SetStateAction<PlacementType | undefined>
	>
	onRemoveAll: () => void
	onSubmit: () => Promise<void>
	onRemoveItem: (itemId: number) => void
	placements: PlacementShort[]
	onPlacementClick: (placement: PlacementShort) => Promise<void>
	detailedPlacement: PlacementType | undefined
	onSearchStringChange: (searchString: string) => void
	onAddItem: (placementId: number) => void
	searchAddresses: Address[] | null
	onAddressClick: (address: Address) => void
	searchString: string
	minAddedItemsCount: number
}
export const MassCoverageMobileModal = ({
	onClose,
	open,
	center,
	focusCenter,
	objectManagerJSON,
	getCirlce,
	items,
	onItemClick,
	radius,
	setCenter,
	isFooterDisabled,
	isPlacementsTheSame,
	itemsCount,
	onAddPlacements,
	onRadiusChange,
	addedPlacement,
	onPlacementClick,
	placements,
	detailedPlacement,
	setDetailedPlacement,
	onSearchStringChange,
	onSubmit,
	onRemoveAll,
	onRemoveItem,
	onAddItem,
	searchAddresses,
	onAddressClick,
	searchString,
	minAddedItemsCount,
}: Props) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const [isSearchActive, setIsSearchActive] = useState(false)
	const [addedPlacementsOpen, setAddedPlacementsOpen] = useState(false)

	const handleExpand = () => {
		if (isExpanded && isSearchActive) {
			setIsSearchActive(false)
		}
		setIsExpanded(!isExpanded)
	}
	const handlePlacementClick = (placement: PlacementShort) => {
		setIsSearchActive(false)
		onPlacementClick(placement)
	}

	const handleItemClick = async (e: any) => {
		await onItemClick(e)
		setIsExpanded(true)
	}
	const handleAddPlacements = () => {
		if (isPlacementsTheSame) {
			onRemoveAll()
		} else {
			onAddPlacements()
			setAddedPlacementsOpen(true)
		}
	}

	const handleSetDetailedPlacement = (
		placement: PlacementType | undefined
	) => {
		if (!placement) {
			setIsExpanded(false)
		}

		setDetailedPlacement(placement)
	}
	const handleSearchStringChange = (value: string) => {
		if (value.trim() !== '') {
			setIsExpanded(false)
			setIsSearchActive(true)
			setDetailedPlacement(undefined)
		} else {
			setIsSearchActive(false)
		}
		onSearchStringChange(value)
	}

	const handleAddressClick = (address: Address) => {
		onSearchStringChange('')
		setIsExpanded(false)
		setAddedPlacementsOpen(false)
		setIsSearchActive(false)
		onAddressClick(address)
	}

	const isAlreadyAdded = useMemo(
		() => !!addedPlacement.find((el) => el.pk === detailedPlacement?.id),
		[addedPlacement, detailedPlacement]
	)
	const handleDetailedAction = () => {
		if (isAlreadyAdded) {
			onRemoveItem(detailedPlacement?.id || 0)
			return
		}

		if (detailedPlacement) {
			onAddItem(detailedPlacement.id)
		}
	}

	const isControlsNeeded = useMemo(() => {
		if (isSearchActive) return false

		if (!detailedPlacement) return true
		else return !isExpanded
	}, [isExpanded, detailedPlacement, isSearchActive])

	return (
		<Modal
			fullscreen
			show={open}
			onHide={onClose}
			scrollable={false}
			dialogClassName={s.mobileModal}
			backdropClassName={s.mobileModal}
			contentClassName={s.mobileModal}
		>
			{addedPlacementsOpen && (
				<AddedPlacementsMobile
					itemsCount={addedPlacement.length}
					onSubmit={onSubmit}
					onClose={() => setAddedPlacementsOpen(false)}
					addedPlacement={addedPlacement}
					onRemoveItem={onRemoveItem}
					onRemoveAll={onRemoveAll}
					minAddedItemsCount={minAddedItemsCount}
				/>
			)}
			<Modal.Header className="p-0 border-0">
				<div className="w-100">
					<div className={s.mobileModalHeaderPrimary}>
						<p>Добавление рекламных мест для наружной рекламы</p>

						<Button variant="text" onClick={onClose}>
							<Close />
						</Button>
					</div>

					{!isExpanded && (
						<div
							className={s.searchWrapper}
							style={{
								boxShadow: isSearchActive
									? '0px 5px 10px rgba(33, 37, 41, 0.1)'
									: 'none',
							}}
						>
							<Search
								minWidth="40%"
								placeholder="Поиск по адресу"
								setSearchString={handleSearchStringChange}
								searchString={searchString}
							/>
						</div>
					)}
				</div>
			</Modal.Header>

			<Modal.Body
				style={{
					padding: 0,
					width: '100%',
					height: '100%',
					maxHeight: '100%',
					position: 'relative',
				}}
			>
				{isSearchActive && (
					<div className={s.searchResultsList}>
						<LeftPanelPlacementList
							onAddressClick={handleAddressClick}
							searchAddresses={searchAddresses}
							addedPlacements={addedPlacement}
							onPlacementClick={handlePlacementClick}
							placements={placements}
						/>
					</div>
				)}

				<ToggleRightPannel
					count={addedPlacement.length}
					isExpanded={false}
					setIsExpanded={() => setAddedPlacementsOpen(true)}
				/>
				<MassCoverageYandexMap
					focusCenter={focusCenter}
					onItemClick={handleItemClick}
					objectManagerJSON={objectManagerJSON}
					center={center}
					radius={radius}
					getCirlce={getCirlce}
					setCenter={setCenter}
					items={items}
				/>

				{!isSearchActive && (
					<motion.div
						transition={{ duration: 0.3 }}
						className={s.bottomSheet}
						style={{
							height: isSearchActive ? '95%' : 'auto',
							maxHeight: isExpanded ? '95%' : undefined,
						}}
					>
						<div className="d-flex flex-column">
							<motion.div
								onClick={handleExpand}
								className={s.expandWrapper}
							>
								<Expand
									style={{
										transform: !isExpanded
											? 'rotate(90deg)'
											: 'rotate(270deg)',
									}}
								/>
							</motion.div>
							{isControlsNeeded && (
								<div
									className={s.radiusWrapper}
									style={{
										boxShadow: isExpanded
											? '0px 5px 10px rgba(33, 37, 41, 0.1)'
											: 'none',
									}}
								>
									<div>
										<RadiusRange
											onRadiusChange={onRadiusChange}
											radius={radius}
											value={formatSliderValue(radius)}
										/>
									</div>
								</div>
							)}
						</div>

						{isExpanded &&
							(detailedPlacement ? (
								<MassCoverageDetailedPlacement
									actionType={getDetailedItemActionType(
										isAlreadyAdded,
										true
									)}
									onClick={handleDetailedAction}
									placement={detailedPlacement}
									setDetailedPlacement={
										handleSetDetailedPlacement
									}
								/>
							) : (
								<LeftPanelPlacementList
									onAddressClick={onAddressClick}
									searchAddresses={null}
									addedPlacements={addedPlacement}
									onPlacementClick={handlePlacementClick}
									placements={placements}
								/>
							))}

						{isControlsNeeded && (
							<div style={{ width: '100%' }}>
								<LeftPanelFooter
									isFooterDisabled={isFooterDisabled}
									isPlacementsTheSame={isPlacementsTheSame}
									itemsCount={itemsCount}
									onAddPlacements={handleAddPlacements}
								/>
							</div>
						)}
					</motion.div>
				)}
			</Modal.Body>
		</Modal>
	)
}
