import React, { useState } from 'react'
import { Button, ButtonGroup, Form } from 'react-bootstrap'
import { ageGroups, AgeGroupsKeys, AgeGroupsValues } from '../constants'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	ageMarking: AgeGroupsKeys
	setFieldValue: (name: string, value: AgeGroupsKeys) => null
}
export const AgeMarkingSelector = ({ ageMarking, setFieldValue }: Props) => {
	const [selectedAgeMarking, setSelectedAgeMarking] =
		useState<AgeGroupsKeys>(ageMarking)

	const handleAgeMarkingChange = (value: AgeGroupsKeys) => {
		setFieldValue('age_marking', value)
		setSelectedAgeMarking(value)
	}
	const { isMobile } = useResponsive()
	return (
		<Form.Group className={'mt-4 d-flex flex-column'}>
			<Form.Label>Возрастная маркировка</Form.Label>
			<ButtonGroup
				aria-label="age-marking-label"
				style={{ maxWidth: 'fit-content' }}
				size={isMobile ? undefined : 'lg'}
			>
				{Object.keys(ageGroups).map((groupKey) => (
					<Button
						variant={
							selectedAgeMarking === groupKey
								? 'primary'
								: 'secondary'
						}
						key={groupKey}
						onClick={() =>
							handleAgeMarkingChange(groupKey as AgeGroupsKeys)
						}
					>
						{ageGroups[groupKey]}
					</Button>
				))}
			</ButtonGroup>
		</Form.Group>
	)
}
