import React, { useState } from 'react'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import { CampaignItemInfoField } from 'src/components/_moderator/components/pages/campaigns-moderation/content/campaign-item-info/common/CampaignItemInfoField'
import declOfNum from 'src/utils/declOfNum'
import useCampaignData from 'src/stores/campaignData'
import { Button } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'
import s from './detailedPlacement.module.scss'
import { DetailedItemAction } from '../../../../utils'

type MassCoverageDetailetPlacementProps = {
	placement: PlacementType
	setDetailedPlacement: (placement: PlacementType | undefined) => void
	onClick: () => void
	actionType: DetailedItemAction
}
export const MassCoverageDetailedPlacement = ({
	placement,
	setDetailedPlacement,
	actionType,
	onClick,
}: MassCoverageDetailetPlacementProps) => {
	const [campaign, campaignInterface] = useCampaignData()

	const { isMobile } = useResponsive()
	const infoFields = {
		['Размер']: placement.size,
		['Рекламу увидят']: placement?.ots_per_day
			? `${parseFloat(placement?.ots_per_day)} чел./день`
			: 'Неизвестно',

		['Длительность показа']:
			placement?.media_type?.duration +
			' ' +
			declOfNum(placement?.media_type?.duration, [
				'секунда',
				'секунды',
				'секунд',
			]),
		['График показов']: `${
			placement.only_work_days
				? 'Только рабочие дни, '
				: 'В рабочие и выходные дни, '
		}${
			placement?.works_on_holidays
				? 'включая праздничные'
				: 'не включая праздничные'
		}`,
		['Срок размещения']: campaignInterface.getCampaignPeriod(),
		['Дней показа']: placement.placement_showing_counts?.[0]?.ac_days_count,
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				maxHeight: '100%',
				height: '100%',
			}}
		>
			<div style={{ flexGrow: 1, overflowY: 'auto' }}>
				{!isMobile && (
					<div>
						<div>
							<Button
								onClick={() => setDetailedPlacement(undefined)}
								variant="text"
								className={s.backButton}
							>
								<span className="arrow-icon">&#8592;</span>{' '}
								<p>Назад</p>
							</Button>
						</div>
						<ImageFullScreenWrapper fullWidth>
							<img
								src={
									placement.image ||
									'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
								}
								alt="media"
								style={{
									width: '100%',
									height: '240px',
								}}
							/>
						</ImageFullScreenWrapper>
					</div>
				)}

				<div className={s.info}>
					<div className={s.infoFieldsHeader}>
						<div className={s.infoFieldsHeader_primal}>
							<h5>{placement.placement_type.name}</h5>
							{isMobile && (
								<Button
									onClick={() =>
										setDetailedPlacement(undefined)
									}
								>
									<i className="bi bi-x"></i>
								</Button>
							)}
						</div>

						<p>{placement.name}</p>
					</div>

					<div className={s.infoFields}>
						{infoFields &&
							Object.keys(infoFields!).map((key, index) => (
								<CampaignItemInfoField
									key={key}
									title={key}
									text={infoFields[key]}
									mainStyle={{
										borderTop:
											index === 0
												? '1px solid #CED4DA'
												: undefined,
									}}
									textStyle={{
										color:
											parseFloat(infoFields[key]) === 0
												? '#CED4DA'
												: '',
									}}
								/>
							))}
					</div>
				</div>
			</div>

			{actionType !== 'hidden' && (
				<div className={s.actionButton}>
					<Button
						onClick={onClick}
						variant={actionType === 'remove' ? 'danger' : 'primary'}
					>
						{actionType === 'remove'
							? 'Удалить из рекламной кампании'
							: 'Добавить в рекламную кампанию'}
					</Button>
				</div>
			)}
		</div>
	)
}
