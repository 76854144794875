import React from 'react'
import {
	PlacementsPickerProps,
	SelectedPlacement,
} from '../../../common/PlacementsPickerContent'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ReactComponent as TrashIcon } from 'src/assets/images/trash-delete.svg'
import { formattedNumber } from 'src/utils'
import { PlacementsInfo } from '../../../../model/types'

type Props = {
	extraInfo: PlacementsInfo | undefined
	placement: SelectedPlacement
	isLoading: boolean
} & Pick<
	PlacementsPickerProps,
	| 'onRemovePlacementsFromCart'
	| 'detailedPlacementId'
	| 'getDetailedPlacement'
>

export const PlacementCartItem = ({
	placement,
	onRemovePlacementsFromCart,
	detailedPlacementId,
	getDetailedPlacement,
	extraInfo,
	isLoading,
}: Props) => {
	const daysCount = extraInfo?.ac_days_count || 0

	const borderProps: React.CSSProperties =
		detailedPlacementId === placement.pk
			? {
					border: '2px solid #007BFF', // Full border when selected
			  }
			: {
					borderBottom: '1px solid #CED4DA',
			  }

	const handleRemoveFormCart = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.stopPropagation()
		onRemovePlacementsFromCart([placement.pk])
	}
	return (
		<div
			onClick={() => getDetailedPlacement(placement.pk)}
			style={{
				padding: '16px',
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				...borderProps,
			}}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					gap: '16px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '4px',
					}}
				>
					<h6
						style={{
							fontWeight: 500,
							fontSize: '16px',
							lineHeight: '24px',
							margin: 0,
						}}
					>
						{placement.placementType}
					</h6>
					<p
						style={{
							fontWeight: 400,
							fontSize: '14px',
							lineHeight: '22px',
							color: '#6C757D',
							margin: 0,
						}}
					>
						{placement.name}
					</p>
				</div>

				<OverlayTrigger
					placement="left"
					overlay={<Tooltip>Удалить место</Tooltip>}
				>
					<Button
						variant="text"
						onClick={handleRemoveFormCart}
						style={{ padding: '0', boxShadow: 'none' }}
					>
						<TrashIcon />
					</Button>
				</OverlayTrigger>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '8px',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'space-between',
					}}
				>
					<p
						style={{
							fontWeight: 400,
							fontSize: '14px',
							lineHeight: '22px',
							color: '#6C757D',
							margin: 0,
						}}
					>
						Только в рабочие дни, включая праздничные
					</p>
					<p
						style={{
							fontWeight: 400,
							fontSize: '14px',
							lineHeight: '22px',
							whiteSpace: 'nowrap',
							margin: 0,
						}}
					>
						на {placement.psc.videoDuration} секунд
					</p>
				</div>

				<div
					style={{
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'space-between',
					}}
				>
					<h6 style={{ margin: 0 }}>
						{isLoading ? '??' : daysCount} x{' '}
						{formattedNumber(placement.psc.price)} ₽{' '}
					</h6>

					<h6 style={{ margin: 0 }}>
						{isLoading
							? 'Идет рассчет...'
							: `${formattedNumber(
									daysCount * parseFloat(placement.psc.price)
							  )}
						₽`}
					</h6>
				</div>
			</div>
		</div>
	)
}
