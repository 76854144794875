import React from 'react'
import { SubRow } from '../../model/tableModel'
import { formattedNumber } from 'src/utils'
import { TextOverflow } from 'src/components/UtilityComponents'

type Props = {
	subRow: SubRow
	lastRow: boolean
}
export const PlacementRow = ({ subRow, lastRow }: Props) => {
	const { role, data } = subRow

	if (role === 'header') {
		return (
			<tr>
				<td scope="row" style={{ borderBottom: 'none' }}></td>
				<td scope="row" colSpan={8}>
					{data.placementType}
				</td>
			</tr>
		)
	}

	return (
		<tr>
			<td
				scope="row"
				style={{ borderBottom: lastRow ? '1px solid #CED4DA' : 'none' }}
			></td>
			<td scope="row">{data.placementType}</td>
			<td style={{ maxWidth: '320px' }}>
				<TextOverflow
					text={data?.address || ''}
					style={{ margin: 0 }}
					placement="right"
				/>
			</td>

			<td>{data.gender}</td>
			<td style={{ maxWidth: '120px' }}>
				<TextOverflow text={data.age || ''} style={{ margin: 0 }} />
			</td>
			<td style={{ textAlign: 'right' }}>
				{formattedNumber(data.showing_count)}
			</td>
			<td style={{ textAlign: 'right' }}>
				~ {formattedNumber(data.forecast_views)}
			</td>
			<td style={{ textAlign: 'right' }} scope="row">
				{formattedNumber(data.amount)} ₽
			</td>
		</tr>
	)
}
