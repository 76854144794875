import React, { useEffect, useMemo, useState } from 'react'
import { DetailedPlacementType, DetailedPsc } from '../../../../model/types'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import { Button, ButtonGroup } from 'react-bootstrap'
import { formattedNumber } from 'src/utils'
import DetailItem from 'src/utils/DetailItem'
import useCampaignData from 'src/stores/campaignData'
import { ReactComponent as CloseIcon } from 'src/assets/images/close cancel x.svg'
import {
	PlacementsPickerProps,
	SelectedPsc,
} from '../../../common/PlacementsPickerContent'

type Props = {
	details: DetailedPlacementType
	selectedPscForDetailed: SelectedPsc | null
} & Pick<
	PlacementsPickerProps,
	| 'onCloseDetailedPlacement'
	| 'onAddDetailedPlacement'
	| 'onRemovePlacementsFromCart'
	| 'addedPlacements'
>
export const DetailsContent = ({
	details,
	onCloseDetailedPlacement,
	onAddDetailedPlacement,
	selectedPscForDetailed,
	addedPlacements,
	onRemovePlacementsFromCart,
}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [selectedPsc, setSelectedPsc] = useState<DetailedPsc>(
		details.placement_showing_counts[0]
	)

	const isSelectedPscInAdded = useMemo(() => {
		return addedPlacements.some((el) => el.psc.pk === selectedPsc.id)
	}, [details, selectedPsc, addedPlacements])

	const handleAddToCartClick = () => {
		if (isSelectedPscInAdded) {
			onRemovePlacementsFromCart([details.pk])
			return
		}

		onAddDetailedPlacement(details, selectedPsc)
	}

	const buttonTitle = isSelectedPscInAdded
		? 'Убрать из кампании'
		: 'Добавить в кампанию'
	useEffect(() => {
		if (selectedPscForDetailed) {
			const psc = details.placement_showing_counts.find(
				(el) => el.id === selectedPscForDetailed.pk
			)

			if (psc) {
				setSelectedPsc(psc)
			}
		}
	}, [selectedPscForDetailed, details])

	return (
		<div style={{ width: '100%' }}>
			<div
				style={{
					maxHeight: '240px',
					width: '100%',
					height: '240px',
					position: 'relative',
				}}
			>
				<ImageFullScreenWrapper fullWidth>
					<img
						src={details.image}
						alt={details.image}
						style={{
							width: '100%',
							minWidth: '100%',
							height: '100%',
							maxHeight: '240px',
							minHeight: '240px',
							borderRadius: '16px 16px 0 0',
							display: 'block',
						}}
					/>
				</ImageFullScreenWrapper>

				<Button
					onClick={onCloseDetailedPlacement}
					variant={'text'}
					style={{
						width: '40px',
						height: '40px',
						position: 'absolute',
						top: 16,
						right: 16,
						backgroundColor: '#fff',
						borderRadius: '12px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 0,
					}}
				>
					<CloseIcon
						style={{
							width: '20px',
							height: '20px',
							fill: '#6C757D',
						}}
					/>
				</Button>
			</div>

			<div
				style={{
					padding: '16px',
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '4px',
					}}
				>
					<h5 className="m-0">{details.placement_type.name}</h5>
					<p
						className="m-0"
						style={{
							fontWeight: 400,
							fontSize: '14px',
							lineHeight: '22px',
							color: '#6C757D',
						}}
					>
						{details.name}
					</p>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
					}}
				>
					<p className="m-0">Длительность показа (в секундах):</p>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<ButtonGroup
							aria-label="videoDurations_select"
							style={{
								borderRadius: '12px',
								overflow: 'hidden',
							}}
						>
							{details.placement_showing_counts.map((psc) => (
								<Button
									onClick={() => setSelectedPsc(psc)}
									key={psc.id}
									variant={
										psc.id === selectedPsc.id
											? 'primary'
											: 'secondary'
									}
									style={{ boxShadow: 'none' }}
								>
									{psc.video_duration}
								</Button>
							))}
						</ButtonGroup>

						<h6 className="m-0">
							{formattedNumber(selectedPsc.price)} ₽ / день
						</h6>
					</div>
				</div>
				<div
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<h5 className="m-0">Стоимость:</h5>
					<h5 className="m-0">
						{formattedNumber(selectedPsc.ac_price)} ₽
					</h5>
				</div>
				<Button
					onClick={handleAddToCartClick}
					variant={isSelectedPscInAdded ? 'danger' : 'primary'}
					style={{
						height: '48px',
						borderRadius: '16px',
						fontWeight: 500,
						fontSize: '20px',
						lineHeight: '30px',
					}}
				>
					{buttonTitle}
				</Button>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<h6 className="m-0">О месте</h6>
						<div
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<DetailItem
								left="Размер:"
								right={details.size}
								styles={{ fontSize: '14px' }}
							/>
							<DetailItem
								left="Рекламу увидят:"
								right={`${formattedNumber(
									selectedPsc.showing_count_per_day
								)} чел./день`}
								styles={{ fontSize: '14px' }}
							/>
							<DetailItem
								left={'Количество экранов:'}
								right={details.screen_count}
								styles={{ fontSize: '14px' }}
							/>
							<DetailItem
								left={'График показов:'}
								right={
									<>
										{details.only_work_days
											? 'Только рабочие дни, '
											: 'В рабочие и выходные дни, '}
										<br />{' '}
										{details.works_on_holidays
											? 'включая праздничные'
											: 'не включая праздничные'}
									</>
								}
								styles={{ fontSize: '14px' }}
							/>
							<DetailItem
								left={'Срок размещения:'}
								right={campaignInterface.getCampaignPeriod()}
								styles={{ fontSize: '14px' }}
							/>
							<DetailItem
								left={'Дней показа:'}
								right={selectedPsc.ac_days_count}
								styles={{ fontSize: '14px' }}
							/>
						</div>
					</div>

					{(details.provider.booking_cancel ||
						details.provider.booking_features) && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '8px',
							}}
						>
							<h6 className="m-0">Дополнительно</h6>
							<div>
								{details.provider.booking_cancel && (
									<p
										style={{
											fontWeight: 400,
											fontSize: '14px',
											lineHeight: '22px',
										}}
									>
										• {details.provider.booking_cancel}
									</p>
								)}
								{details.provider.booking_features && (
									<div
										style={{
											fontWeight: 400,
											fontSize: '14px',
											lineHeight: '22px',
											whiteSpace: 'pre-line',
										}}
									>
										{details.provider.booking_features
											.split(/\r\n\r\n/) // Splits sections based on double new lines
											.map((section, index) => (
												<p
													key={index}
													style={{
														marginBottom: '10px',
													}}
												>
													• {section}
												</p>
											))}
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
