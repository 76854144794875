import React, { useState } from 'react'
import { MediaplanTableModel } from '../../model/tableModel'
import { Table } from 'react-bootstrap'
import { FormatRow } from './FormatRow'
import { PlacementRow } from './PlacementRow'
import s from './table.module.scss'
import { formattedNumber } from 'src/utils'

type Props = {
	tableData: MediaplanTableModel
}

export const MediaplanTable = ({ tableData }: Props) => {
	const [tableRows, setTableRows] = useState(tableData.rows)

	const handExpandCellClick = (rowId: string) => {
		setTableRows((prev) => {
			return prev.map((row) => {
				if (row.id === rowId) {
					return {
						...row,
						subrowsOpen: !row.subrowsOpen,
					}
				}
				return row
			})
		})
	}

	const totalAmount = tableData.rows.reduce((acc, row) => {
		return acc + row.data.amount
	}, 0)

	return (
		<table className={s.table}>
			<thead>
				<tr>
					{tableData.columns.map((col) => (
						<th
							key={col.id}
							scope="col"
							colSpan={col.id === 'action' ? 2 : 1}
							style={{
								width: col?.width,
								maxWidth: col?.maxWidth,
							}}
						>
							{col.value}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{tableRows.map((row, index) => (
					<>
						<FormatRow
							key={row.id}
							row={row}
							onExpand={handExpandCellClick}
						/>

						{row.subrowsOpen &&
							row.subrows.length &&
							row.subrows.map((subRow, index) => (
								<PlacementRow
									key={subRow.id}
									subRow={subRow}
									lastRow={index === row.subrows.length - 1}
								/>
							))}

						{index < tableRows.length - 1 && row.isDividerRow && (
							<tr>
								<td
									colSpan={8}
									style={{
										padding: 0,
										height: '4px',
										backgroundColor: '#F5F5F5',
									}}
								></td>
							</tr>
						)}
					</>
				))}
			</tbody>
			<tfoot>
				<tr style={{ backgroundColor: '#F5F5F5' }}>
					<td
						scope="row"
						colSpan={7}
						className={s.selectedCell}
						style={{ textAlign: 'right' }}
					>
						Итого:
					</td>
					<td
						className={s.selectedCell}
						style={{ textAlign: 'right' }}
					>
						{formattedNumber(totalAmount)} ₽
					</td>
				</tr>
			</tfoot>
		</table>
	)
}
