import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import { DetailedPlacementType } from '../model/types'

export const fetchDetailedPlacement = async (
	campaignId: string,
	placementId: number
) => {
	return await authFetch<DetailedPlacementType>({
		url: `${BASE_URL}/adv_companies/${campaignId}/available_placements/${placementId}/`,
		method: 'GET',
	})
}
