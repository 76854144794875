import React from 'react'
import {
	MoscowCentRingRequirementsArticle,
	ArticleItem,
} from './MoscowCentRingRequirementsArticle'

export const MCRCommonArticle = (props: any) => {
	return (
		<MoscowCentRingRequirementsArticle title="Общие требования">
			<ArticleItem title="Разрешение:">1080 x 1920 px</ArticleItem>
			<ArticleItem title="Дисклеймер:">
				Высота Дисклеймера 17 рх. Рекомендуем использовать рубленый
				шрифт («без засечек»), все буквы ЗАГЛАВНЫЕ. Ориентация
				дисклеймера — ГОРИЗОНТАЛЬНАЯ
			</ArticleItem>
			<ArticleItem title="Возрастная маркировка:">
				«0+», «6+», «12+», «16+», 18+». Высота 50 рх. Шрифт должен быть
				полужирного начертания (Semi bold).
			</ArticleItem>
			<ArticleItem
				title="Юридическая информация:"
				style={{ border: 'none' }}
			>
				<>
					В соответствии с федеральным законом «О рекламе»,{' '}
					<a
						href="https://www.consultant.ru/document/cons_doc_LAW_58968/"
						target="_blank"
						rel="noreferrer"
					>
						N 38-ФЗ от 13.03.2006
					</a>
					, на рекламной конструкции должны быть указаны:
					<ul>
						<li>
							Возрастная маркировка Пометка «Реклама» Название Юр.
						</li>
						<li>
							лица / Самозанятого Адрес регистрации Юр. лица /
							Самозанятого
						</li>
						<li> ИНН Юр. лица / Самозанятого</li>
					</ul>
				</>
			</ArticleItem>
		</MoscowCentRingRequirementsArticle>
	)
}
