import { ForecastMetro } from '../model/apiTypes'
import { MediaplanTableRow, SubRow } from '../model/tableModel'

export const mapMetro = (metro: ForecastMetro): MediaplanTableRow => {
	const totalFormatSums = {
		showing_count: 0,
	}

	const subRows: SubRow[] = []

	for (const placement of metro.placements) {
		totalFormatSums.showing_count += parseFloat(placement.ots_ac || '0')

		const subRow: SubRow = {
			id: `metro_${placement.pk}`,
			role: 'data',
			data: {
				placementType: placement.size,
				address: placement.name,
				showing_count: parseFloat(placement.ots_ac || '0'),
				forecast_views: parseFloat(placement.ots || '0'),
				amount: metro?.placements_amount || 0,
			},
		}
		subRows.push(subRow)
	}

	const row: MediaplanTableRow = {
		id: 'metro_row',
		isDividerRow: true,
		data: {
			region: 'Москва',
			gender: '-',
			age: '-',
			showing_count: totalFormatSums.showing_count,
			forecast_views: metro.forecast_views_count,
			amount: parseFloat(metro.amount || '0'),
			format: {
				name: 'Цифровые экраны на МЦД',
				placementsCount: subRows.length,
			},
		},
		subrows: subRows,
		subrowsOpen: false,
	}

	return row
}
