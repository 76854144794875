import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import { AdvFormats } from '../types'

export const checkIsAdvFormatSelected = (
	format: AdvFormats,
	campaign: CampaignInfoTypeBookingsSeparation
): boolean => {
	switch (format) {
		case AdvFormats.Outdoor:
			return (
				campaign.outdoor_adv_is_selected ||
				campaign.district_adv_is_selected
			)
		case AdvFormats.Indoor:
			return campaign.indoor_adv_is_selected
		case AdvFormats.PublicTransport:
			return (
				!!campaign.public_transport?.is_selected ||
				!!campaign.metro?.is_selected
			)
		case AdvFormats.Internet:
			return (
				!!campaign.internet_vk?.is_selected ||
				!!campaign.internet_yandex?.is_selected
			)

		case AdvFormats.TV:
			return !!campaign.adstream?.is_selected
		default:
			return false
	}
}
