import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card } from 'react-bootstrap'
import s from './commonMedia.module.scss'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'
import { CommonMediaControls } from './CommonMediaControls'
import {
	AdvItemState,
	GarantidItem,
	MediaFormatViewModel,
	PlacementViewModel,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import { CommonMediaModal } from './modals/CommonMediaModal'
import { downloadByRequest } from 'src/utils'
import { MediaFormatRequirements } from '../common/MediaFormatRequirements'
import { CommonMediaEdit } from './modals/CommonMediaEdit'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'
import useNotificationData from 'src/stores/notificationsData'
import { PreviewPlaceholder } from '../common/PreviewPlaceholder'
import { NewContentCreatedStatusType } from 'src/stores/types/newContendCreatedType'
import useCampaignData from 'src/stores/campaignData'
import useResponsive from 'src/utils/useResponsive'

type CommonMediaProps = {
	editable: boolean
	mediaFormat: MediaFormatViewModel<GarantidItem, PlacementViewModel>
}

const modalTitles: Record<CommonMediaModal, string> = {
	edit: 'Исправление рекламных мест',
	requirements: 'Технические требования к загрузке материала',
}
export const CommonMedia = ({ mediaFormat, editable }: CommonMediaProps) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [_, notificationsInterface] = useNotificationData()
	const [modalType, setModalType] = useState<CommonMediaModal | null>(null)
	const [isEditVinstantOpen, setIsEditVinstantOpen] = useState(false)

	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)

	const bookingItemsIds: number[] = useMemo(
		() =>
			mediaFormat.booking_item.map((bookingItem) => bookingItem.item.id),
		[mediaFormat]
	)
	// isDocsRequired, file, fileName должны быть одинаковыми для всех букингов внутри одного формата, иначе CommonMedia не доступен. см. родительский компонент
	const file = mediaFormat.booking_item[0].item.media_creative?.file || ''
	const fileName =
		mediaFormat.booking_item[0].item.media_creative?.file_name || ''
	const isDocsRequired = mediaFormat.booking_item[0].item.is_docs_required

	const fakeScreenResolution = mediaFormat.media_type_name
	const screenResolution = `${mediaFormat.media_requirements?.max_width} × ${mediaFormat.media_requirements?.max_height} px`

	const bookingVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'booking_id',
			itemId: mediaFormat.booking_item[0].item.id.toString(),
		})
	)

	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(
			mediaFormat.booking_item[0].item.id.toString()
		) as NewContentCreatedStatusType | undefined

	const bookingVinstantEditKey =
		mediaFormat.booking_item[0].item.id.toString()

	const handeAllBookingsEdit = editable
		? () => {
				setModalType('edit')
		  }
		: undefined

	const handleDownloadMedia = useCallback(async () => {
		const url = `adv_company_media/${
			mediaFormat.booking_item[0].item.media_creative?.id || ''
		}/download/`
		await downloadByRequest(url, fileName)
	}, [file, fileName])

	const handleRequirementsClick = useCallback(() => {
		setModalType('requirements')
	}, [])

	const handeVinstantEditClose = useCallback(() => {
		setIsEditVinstantOpen(false)
		setModalType('edit')
	}, [])
	const handleVinstantEditOpen = useCallback(() => {
		setIsEditVinstantOpen(true)
	}, [])
	const handleEditModalClose = useCallback(() => {
		setModalType(null)
	}, [])

	const refetchAfterVinstantEnd = async () => {
		await fetchVinstantCreative(campaign.id)
		await fetchTechnicalRequirements(campaign)
		notificationsInterface.setNewContendCreated(
			bookingVinstantEditKey,
			'pending'
		)
	}
	useEffect(() => {
		if (editCreativeVinstantStatus === 'end') {
			refetchAfterVinstantEnd()
		}
	}, [editCreativeVinstantStatus])
	return (
		<Card.Body className={s.commonMedia}>
			<MediaPreview
				isLoading={editCreativeVinstantStatus === 'start'}
				file={file}
				fileName={fileName}
				screenResolution={fakeScreenResolution}
			/>
			<CommonMediaControls
				onDeleteMedia={() => {}}
				onEditMedia={handeAllBookingsEdit}
				onUpload={handleDownloadMedia}
				onRequirementsClick={handleRequirementsClick}
			/>

			{isEditVinstantOpen && (
				<EditVinstantModal
					editVinstantLink={bookingVinstantLink}
					newContentKey={bookingVinstantEditKey}
					onClose={handeVinstantEditClose}
					open={isEditVinstantOpen}
				/>
			)}

			<CommonMediaModal
				title={modalTitles[modalType || 'requirements']}
				open={!!modalType}
				onClose={() => setModalType(null)}
			>
				{modalType === 'requirements' && (
					<MediaFormatRequirements
						mediaRequierments={mediaFormat.media_requirements}
					/>
				)}
				{modalType === 'edit' && (
					<CommonMediaEdit
						onClose={handleEditModalClose}
						bookingVinstantLink={bookingVinstantLink}
						isDocsRequired={isDocsRequired}
						file={file}
						fileName={fileName}
						screentResolution={screenResolution}
						bookingItemsIds={bookingItemsIds}
						onVinstantEditOpen={handleVinstantEditOpen}
						mediaRequierments={mediaFormat.media_requirements}
						isVinstantLoading={
							editCreativeVinstantStatus === 'start'
						}
					/>
				)}
			</CommonMediaModal>
		</Card.Body>
	)
}

type MediaPreviewProps = {
	file: string
	fileName: string
	screenResolution: string
	isLoading?: boolean
}

export const MediaPreview = ({
	file,
	fileName,
	screenResolution,
	isLoading = false,
}: MediaPreviewProps) => {
	const { isMobile } = useResponsive()

	const previewSize = isMobile ? '72px' : '80px'
	return (
		<div className={s.mediaPreview}>
			{isLoading ? (
				<>
					<PreviewPlaceholder />
				</>
			) : (
				<>
					<RenderPreview
						value={{ name: fileName, src: file }}
						style={{
							borderRadius: '12px',
							maxHeight: previewSize,
							maxWidth: previewSize,
							minHeight: previewSize,
							minWidth: previewSize,
						}}
					/>

					<div style={{ width: '100%', overflow: 'hidden' }}>
						<p style={{ color: '#212529' }}>{fileName}</p>
						<p style={{ color: '#6C757D' }}>{screenResolution}</p>
					</div>
				</>
			)}
		</div>
	)
}

const colorByState: { [key in AdvItemState]: string } = {
	adjustment: '#DC3545',
	reject: '#DC3545',
	canceled: '#CED4DA',
	completed: '#28A745',
	confirm: '#28A745',
	draft: '#CED4DA',
	inner_moderation: '#007BFF',
	moderation: '#007BFF',
	inner_confirm: '#007BFF',
}
type MediaPreviewExtendedProps = {
	children: React.ReactNode
	state: AdvItemState
} & MediaPreviewProps
export const MediaPreviewExtended = ({
	state,
	children,
	...restProps
}: MediaPreviewExtendedProps) => {
	return (
		<div
			className={s.mediaPreviewContainer}
			style={{ border: `1px dashed ${colorByState[state]}` }}
		>
			<MediaPreview {...restProps} />
			{children}
		</div>
	)
}
