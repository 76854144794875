import React, { useEffect, useRef, useState } from 'react'
import { FixedSizeList as List, ListChildComponentProps } from 'react-window'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import { PlacementItem } from './placement-item/PlacementItem'
import s from './placementsList.module.scss'
import cn from 'classnames'
import { PlacementsPickerProps } from '../../../common/PlacementsPickerContent'
import { ListFooter } from './list-footer/ListFooter'
import { AnimatePresence, motion } from 'framer-motion'
import { Search } from 'src/components/UtilityComponents'
import { Button, Form } from 'react-bootstrap'
import AutoSizer from 'react-virtualized-auto-sizer'

type Props = {
	placements: PlacementShort[]
} & Pick<
	PlacementsPickerProps,
	| 'selectedPlacements'
	| 'onSelectPlacement'
	| 'onApplyCommonDuration'
	| 'onAddSelectedToCart'
	| 'isAllSelectedInAdded'
	| 'getDetailedPlacement'
	| 'detailedPlacementId'
	| 'addedPlacements'
	| 'onChangePlacementsFilter'
	| 'onSelectAll'
	| 'onRemovePlacementsFromCart'
	| 'loading'
>

export const PlacementsList = ({
	placements,
	selectedPlacements,
	onSelectPlacement,
	onApplyCommonDuration,
	onAddSelectedToCart,
	isAllSelectedInAdded,
	getDetailedPlacement,
	detailedPlacementId,
	addedPlacements,
	onChangePlacementsFilter,
	onSelectAll,
	onRemovePlacementsFromCart,
	loading,
}: Props) => {
	const listRef = useRef<any>(null) // Ref for the react-window List

	const Row = ({ index, style }: ListChildComponentProps) => {
		const placement = placements[index]

		const findedInSelected = selectedPlacements.find(
			(el) => el.pk === placement.pk
		)

		const findedInAdded = addedPlacements.find(
			(el) => el.pk === placement.pk
		)
		const dayPrice = findedInSelected
			? findedInSelected.psc.price
			: findedInAdded
			? findedInAdded.psc.price
			: placement.placement_showing_count[0].price

		return (
			<PlacementRow
				loading={loading}
				getDetailedPlacement={getDetailedPlacement}
				applyedPscPrice={dayPrice}
				placement={placement}
				style={style}
				isDetailed={placement.pk === detailedPlacementId}
				isSelected={!!findedInSelected}
				isAdded={!!findedInAdded}
				onSelectPlacement={onSelectPlacement}
			/>
		)
	}

	// Scroll to the detailed placement when `detailedPlacementId` changes
	useEffect(() => {
		if (detailedPlacementId && listRef.current) {
			// Find the index of the detailed placement
			const index = placements.findIndex(
				(placement) => placement.pk === detailedPlacementId
			)

			if (index >= 0) {
				// Scroll to the item
				listRef.current.scrollToItem(index, 'center')
			}
		}
	}, [detailedPlacementId, placements])

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				backgroundColor: '#fff',
				display: 'flex',
				flexDirection: 'column',
				pointerEvents: 'auto',
			}}
		>
			{/* Search Bar */}
			<div
				style={{
					height: '112px',
					borderBottom: '1px solid grey',
					flexShrink: 0,
					padding: '16px',
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				<Search
					minWidth="100%"
					placeholder="Поиск по адресу"
					setSearchString={(searchString) =>
						onChangePlacementsFilter({ q: searchString })
					}
				/>
				{selectedPlacements.length === 0 ? (
					<Form.Check
						type={'checkbox'}
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<Form.Check.Input
							disabled={loading}
							onClick={() => onSelectAll(true)}
							type={'checkbox'}
							style={{
								width: '20px',
								height: '20px',
								margin: 0,
							}}
						/>
						<Form.Check.Label
							style={{
								marginLeft: '8px',
							}}
						>
							Выбрать всё
						</Form.Check.Label>
					</Form.Check>
				) : (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								display: 'flex',
								gap: '8px',
								alignItems: 'center',
							}}
						>
							<div
								style={{
									backgroundColor: '#007BFF',
									width: '20px',
									height: '20px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									borderRadius: '6px',
								}}
								onClick={() => onSelectAll(false)}
							>
								<div
									style={{
										height: '1.25px',
										width: '50%',
										backgroundColor: '#fff',
									}}
								></div>
							</div>
							<div>Выбрано: {selectedPlacements.length}</div>
						</div>
						<Button
							disabled={loading}
							variant="text"
							onClick={() => onSelectAll(false)}
							style={{
								padding: 0,
								boxShadow: 'none',
								border: 'none',
							}}
						>
							<h6 className="m-0 text-primary">Снять выбор</h6>
						</Button>
					</div>
				)}
			</div>

			{/* Virtualized List */}
			<div
				style={{
					flex: 1,
					overflow: 'hidden',
					height: '100%',
				}}
			>
				<AutoSizer>
					{({ height, width }) => (
						<List
							height={height}
							itemCount={placements.length}
							itemSize={112}
							width={width}
							ref={listRef}
						>
							{Row}
						</List>
					)}
				</AutoSizer>
			</div>

			<AnimatePresence>
				{selectedPlacements.length > 0 && (
					<motion.div
						initial={{ opacity: 0, height: 0 }}
						animate={{ opacity: 1, height: 'auto' }}
						exit={{ opacity: 0, height: 0 }}
						transition={{ duration: 0.3, ease: 'easeInOut' }}
					>
						<ListFooter
							onRemovePlacementsFromCart={
								onRemovePlacementsFromCart
							}
							isAllSelectedInAdded={isAllSelectedInAdded}
							onAddSelectedToCart={onAddSelectedToCart}
							selectedPlacements={selectedPlacements}
							onApplyCommonDuration={onApplyCommonDuration}
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	)
}

type PlacementRowProps = {
	placement: PlacementShort
	style: React.CSSProperties
	isSelected: boolean
	isDetailed: boolean
	isAdded: boolean
	applyedPscPrice: string
} & Pick<
	PlacementsPickerProps,
	'onSelectPlacement' | 'getDetailedPlacement' | 'loading'
>

export const PlacementRow: React.FC<PlacementRowProps> = ({
	placement,
	style,
	isSelected,
	isAdded,
	isDetailed,
	onSelectPlacement,
	applyedPscPrice,
	getDetailedPlacement,
	loading,
}) => {
	const [isHovered, setIsHovered] = useState(false)

	return (
		<div
			style={style}
			className={cn(s.placementRow, {
				[s.selected]: isSelected,
				[s.detailed]: isDetailed,
			})}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<PlacementItem
				loading={loading}
				getDetailedPlacement={getDetailedPlacement}
				applyedPscPrice={applyedPscPrice}
				isHovered={isHovered}
				isAdded={isAdded}
				isDetailed={isDetailed}
				placement={placement}
				isSelected={isSelected}
				onSelectPlacement={onSelectPlacement}
			/>
		</div>
	)
}
