import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import { MediaplanType } from '../model/tableModel'
import { MediaplanResponse } from '../model/apiTypes'

export const fetchMediaplan = async (
	campaignId: string,
	mediaplanType: MediaplanType
) => {
	const result = await authFetch<MediaplanResponse>({
		url: `${BASE_URL}/adv_companies/${campaignId}/${mediaplanType}/`,
		method: 'GET',
	})

	return result
}
