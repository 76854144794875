import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { MediaplanTable } from './mediaplan-table/MediaplanTable'
import { MediaplanColumn, MediaplanType } from '../model/tableModel'
import { fetchMediaplan } from '../api/fetchMediaplan'
import useCampaignData from 'src/stores/campaignData'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import { getTableRowsData } from '../utils/getTableRowsData'
import { CampaignTable } from './campaign-table/CampaignTable'
import { MediaplanResponse } from '../model/apiTypes'
import { TableActions } from './table-actions/TableActions'
import useResponsive from 'src/utils/useResponsive'
import MobileModal from 'src/components/UtilityComponents/MobileModal'

type Props = {
	show: boolean
	onHide: () => void
}

enum MediaplanLoadingState {
	loading,
	error,
	loaded,
}
export const Mediaplan = ({ onHide, show }: Props) => {
	const { isMobile } = useResponsive()
	const [campaign, _] = useCampaignData()
	const columns: MediaplanColumn[] = [
		{
			id: 'action',
			value: 'Формат рекламы',
			width: 320,
			maxWidth: 320,
		},

		{
			id: 'region',
			value: 'Регион',
			width: 320,
			maxWidth: 320,
		},
		{
			id: 'gender ',
			value: 'Пол',
			width: 140,
			maxWidth: 140,
		},
		{
			id: 'age',
			value: 'Возраст',
			width: 120,
			maxWidth: 120,
		},
		{
			id: 'showing_count',
			value: 'Показов рекламы',
		},
		{
			id: 'forecast_views',
			value: 'Рекламу увидят',
		},
		{
			id: 'amount',
			value: 'Стоимость без НДС',
		},
	]

	const mediaplanType: MediaplanType =
		campaign.state === CampaignStates.Draft ? 'forecast' : 'stats'

	const [loadingState, setLoadingState] = useState<MediaplanLoadingState>(
		MediaplanLoadingState.loading
	)

	const [mediaplan, setMediaplan] = useState<MediaplanResponse | null>(null)

	const tableData = useMemo(() => {
		if (mediaplan) {
			const rows = getTableRowsData(mediaplan, campaign)

			return { rows: rows, columns: columns }
		}

		return {
			rows: [],
			columns: columns,
		}
	}, [mediaplan])

	const handleFetchMediaplan = async () => {
		try {
			const result = await fetchMediaplan(campaign.id, mediaplanType)

			setMediaplan(result)

			setLoadingState(MediaplanLoadingState.loaded)
		} catch (error) {
			setLoadingState(MediaplanLoadingState.error)
		}
	}

	useEffect(() => {
		handleFetchMediaplan()
	}, [])

	if (isMobile) {
		return (
			<MobileModal
				onHide={onHide}
				show={show}
				title={'Медиаплан рекламной кампании'}
				paddingContent="0"
				defaultTouchingSetup={false}
				enableTouching={false}
				contentStyles={{ width: '100%' }}
			>
				{loadingState === MediaplanLoadingState.loading && (
					<div className="d-flex justify-content-center align-items-center h-100">
						<Spinner animation="border" variant="primary" />
					</div>
				)}
				{loadingState === MediaplanLoadingState.error && (
					<div className="d-flex justify-content-center">
						<p>Ошибка загрузки медиаплана</p>
					</div>
				)}

				{loadingState === MediaplanLoadingState.loaded && (
					<div>
						<TableActions />

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '48px',
							}}
						>
							<CampaignTable mediaplan={mediaplan!} />
							<MediaplanTable tableData={tableData} />
						</div>
					</div>
				)}
			</MobileModal>
		)
	}

	return (
		<Modal show={show} onHide={onHide} fullscreen={true} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>Медиаплан «{campaign.name}»</Modal.Title>
			</Modal.Header>
			<Modal.Body className={'p-0'}>
				{loadingState === MediaplanLoadingState.loading && (
					<div className="d-flex justify-content-center align-items-center h-100">
						<Spinner animation="border" variant="primary" />
					</div>
				)}
				{loadingState === MediaplanLoadingState.error && (
					<div className="d-flex justify-content-center">
						<p>Ошибка загрузки медиаплана</p>
					</div>
				)}

				{loadingState === MediaplanLoadingState.loaded && (
					<div>
						<TableActions />

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '48px',
							}}
						>
							<CampaignTable mediaplan={mediaplan!} />
							<MediaplanTable tableData={tableData} />
						</div>
					</div>
				)}
			</Modal.Body>
		</Modal>
	)
}
