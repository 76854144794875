import React, { useMemo } from 'react'
import { InternetState } from 'src/stores/ADMarketTypes.types'
import useResponsive from 'src/utils/useResponsive'
import useCampaignData from 'src/stores/campaignData'
import { defaultMessageData, MessagesData } from '../../constants/messages-data'

type Props = {
	state: InternetState
	adjustment_msg: string
	reject_msg: string
}
export const StateView = ({ adjustment_msg, reject_msg, state }: Props) => {
	const data = MessagesData[state] || defaultMessageData

	if (state === InternetState.Adjustment) {
		data.message = adjustment_msg
	}
	if (state === InternetState.Reject) {
		data.message = reject_msg
	}
	const { isMobile } = useResponsive()
	const fontSize = useMemo<string>(
		() => (isMobile ? '14px' : '16px'),
		[isMobile]
	)
	if (!data) return <></>

	return (
		<div
			className={'d-flex flex-column'}
			style={{
				color: data.color,
				fontSize,
				marginBottom: isMobile ? '16px' : '24px',
			}}
		>
			<div className={'mb-3'}>
				<i className={data.icon} style={{ marginRight: '12px' }} />
				{data.title}
			</div>
			<div
				className={'w-100 mb-3'}
				style={{
					height: '4px',
					borderRadius: '8px',
					backgroundColor: '#E9ECEF',
					position: 'relative',
				}}
			>
				<div
					style={{
						width: `${data.progress}%`,
						backgroundColor: data.color,
						borderRadius: '8px',
						height: '4px',
						position: 'absolute',
					}}
				></div>
			</div>
			<span>{data.message}</span>
		</div>
	)
}
