import { ForecastAdstream } from '../model/apiTypes'
import { MediaplanTableRow } from '../model/tableModel'

export const mapAdstream = (input: {
	adstream: ForecastAdstream
	extra: {
		gender: string
		age: string
		region: string
	}
}): MediaplanTableRow => {
	const { extra, adstream } = input

	const row: MediaplanTableRow = {
		id: 'adstream',
		data: {
			age: extra.age,
			gender: extra.gender,
			region: extra.region,
			showing_count: 0,
			forecast_views: adstream.forecast_views_count,
			amount: parseFloat(adstream.amount || '0'),
			format: {
				name: 'ТВ-реклама',
				placementsCount: adstream.selection_ids.length,
			},
		},
		subrows: [],
		subrowsOpen: false,
	}

	return row
}
