import React from 'react'
import { MediaplanTableRow } from '../../model/tableModel'
import { formattedNumber } from 'src/utils'
import {
	ConditionArrowUpDown,
	TextOverflow,
} from 'src/components/UtilityComponents'
import s from './table.module.scss'

type FormatRow = {
	row: MediaplanTableRow
	onExpand: (rowId: string) => void
}

export const FormatRow = ({ row, onExpand }: FormatRow) => {
	const data = row.data

	const placementsCountTitle =
		row.id === 'adstream' ? 'Выбрано каналов:' : 'Выбрано мест:'
	return (
		<tr>
			<td
				scope="row"
				onClick={() =>
					row.subrows.length ? onExpand(row.id) : undefined
				}
				style={{
					width: '48px',
					height: '1px',
					padding: '16px',
					cursor: 'pointer',
				}}
				className={row.subrows.length ? s.actionCell : ''}
			>
				{row.subrows.length > 0 && (
					<div className="d-flex align-items-start justify-content-center h-100">
						<ConditionArrowUpDown
							open={row.subrowsOpen}
							styles={{
								height: '16px',
								width: '16px',
								fill: '#6C757D',
							}}
						/>
					</div>
				)}
			</td>
			<td scope="row" style={{ height: '1px', width: '272px' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '4px',
						height: '100%',
						justifyContent: 'center',
					}}
				>
					<h6 className="m-0">{data.format.name}</h6>
					{data.format.placementsCount > 0 && (
						<p
							className="m-0"
							style={{
								color: '#6C757D',
								fontWeight: 400,
								fontSize: '14px',
								lineHeight: ' 22px',
							}}
						>
							{placementsCountTitle} {data.format.placementsCount}
						</p>
					)}
				</div>
			</td>
			<td style={{ maxWidth: '320px' }}>
				<TextOverflow
					text={data.region}
					style={{ margin: 0 }}
					placement="right"
				/>
			</td>
			<td scope="row" style={{ maxWidth: '120px' }}>
				{data.gender}
			</td>
			<td style={{ maxWidth: '120px' }}>
				<TextOverflow
					text={data.age || ''}
					style={{ margin: 0 }}
					placement="right"
				/>
			</td>
			<td style={{ textAlign: 'right' }}>
				{data.showing_count ? formattedNumber(data.showing_count) : ''}
			</td>
			<td style={{ textAlign: 'right' }}>
				{formattedNumber(data.forecast_views)}
			</td>
			<td style={{ textAlign: 'right' }} scope="row">
				{formattedNumber(data.amount)} ₽
			</td>
		</tr>
	)
}
