import React, { useEffect } from 'react'
import BookingAD from 'src/components/_client/create-campaign/Step2-ADformats/BookingsAD/BookingAD'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import { IndoorAdvHeader } from '../../indoor-presentation-card/indoor-adv-header/IndoorAdvHeader'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import { IndoorBooking } from 'src/components/_client/create-campaign/Step2-ADformats/BookingsAD/indoor-booking/IndoorBooking'
import useResponsive from 'src/utils/useResponsive'

type Props = {}
export const IndoorGarantViews = ({}: Props) => {
	const { isMobile } = useResponsive()
	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)
	useEffect(() => {
		return () => {
			setAdFormatErrors('indoorAd', null)
		}
	}, [])
	return (
		<CardWrapper>
			<IndoorAdvHeader />
			{isMobile ? (
				<BookingAD
					isHeader={false}
					isHiddenContent={false}
					onError={() => {}}
					setIsLoading={() => {}}
					bookings_type="indoor"
				/>
			) : (
				<IndoorBooking />
			)}
		</CardWrapper>
	)
}
