import React, { FC, useEffect, useMemo, useState } from 'react'
import s from './typeSwitcher.module.scss'
import { CampaignInfoType } from '../../../../../stores/ADMarketTypes.types'
import useCampaignData from '../../../../../stores/campaignData'
import { AdIcon } from '../../../create-campaign/Step2-ADformats/AdFormatSelector'
import InternetAd from '../../internet/InternetAd'
import TvAd from '../../tv/TvAd'
import { useHistory } from 'react-router-dom'
import { MoscowCentRingInfo } from '../../public-transport/moscow-cent-ring-info/MoscowCentRingInfo'
import cn from 'classnames'
import { OutdoorInfo } from '../../bookings/OutdoorInfo'
import { IndoorInfo } from '../../bookings/IndoorInfo'
import { PublicTransportAd } from '../../public-transport/PublicTransportAd'

enum ADTypes {
	OUTDOOR_BOOKINGS = 'outdoor',
	INDOOR_BOOKINGS = 'indoor',
	INTERNET = 'internet',
	TV = 'tv',
	PUBLIC_TRANSPORT = 'publicTransport',
}

type AdTab = {
	key: ADTypes
	isEnabled: boolean
	Component: FC
	title: string
	icon: JSX.Element
}

const getInitialTab = (campaign: CampaignInfoType): ADTypes => {
	if (campaign.outdoor_adv_is_selected || campaign.district_adv_is_selected)
		return ADTypes.OUTDOOR_BOOKINGS
	if (campaign.indoor_adv_is_selected) return ADTypes.INDOOR_BOOKINGS
	if (campaign.public_transport?.is_selected || campaign.metro.is_selected)
		return ADTypes.PUBLIC_TRANSPORT
	if (
		campaign.internet_yandex.is_selected ||
		campaign.internet_vk.is_selected
	)
		return ADTypes.INTERNET
	if (campaign.teletarget.is_selected || campaign.adstream.is_selected)
		return ADTypes.TV
	return ADTypes.OUTDOOR_BOOKINGS
}

const paramNameForTabs = 'tab'

const CampaignTypeSwitcher = (props) => {
	const [campaign] = useCampaignData()
	const history = useHistory()
	const [currentTab, setCurrentTab] = useState<ADTypes>(
		getInitialTab(campaign)
	)

	// eslint-disable-next-line compat/compat
	const params = new URLSearchParams(window.location.search)
	const tabs = useMemo<AdTab[]>(() => {
		return [
			{
				key: ADTypes.OUTDOOR_BOOKINGS,
				isEnabled:
					campaign.outdoor_adv_is_selected ||
					campaign.district_adv_is_selected,
				Component: OutdoorInfo,
				title: 'Наружная реклама',
				icon: AdIcon({ name: 'outdoor', withCurrentColor: true }),
			},
			{
				key: ADTypes.INDOOR_BOOKINGS,
				isEnabled: campaign.indoor_adv_is_selected,
				Component: IndoorInfo,
				title: 'Внутренняя реклама',
				icon: AdIcon({ name: 'indoor', withCurrentColor: true }),
			},
			{
				key: ADTypes.PUBLIC_TRANSPORT,
				isEnabled:
					!!campaign.public_transport?.is_selected ||
					campaign.metro.is_selected,
				Component: PublicTransportAd,
				title: 'Общественный транспорт',
				icon: AdIcon({
					name: ADTypes.PUBLIC_TRANSPORT,
					withCurrentColor: true,
				}),
			},
			{
				key: ADTypes.INTERNET,
				isEnabled:
					campaign.internet_vk?.is_selected ||
					campaign.internet_yandex?.is_selected,
				Component: InternetAd,
				title: 'Интернет-реклама',
				icon: AdIcon({
					name: ADTypes.INTERNET,
					withCurrentColor: true,
				}),
			},
			{
				key: ADTypes.TV,
				isEnabled:
					campaign.adstream.is_selected ||
					campaign.teletarget.is_selected,
				Component: TvAd,
				title: 'ТВ-реклама',
				icon: AdIcon({ name: ADTypes.TV, withCurrentColor: true }),
			},
		]
	}, [campaign])

	const CurrentTabComponent = useMemo<FC<any>>(() => {
		// @ts-ignore
		return tabs.find((tab) => tab.key === currentTab).Component
	}, [tabs, currentTab])

	//Установка таба если есть гет параметр и он один из существующих, если нет - удаляем гет параметр
	useEffect(() => {
		const initialParam = params.get(paramNameForTabs)
		if (
			initialParam &&
			Object.values(ADTypes).includes(
				initialParam.toLowerCase() as ADTypes
			) &&
			tabs.find((tab) => tab.key === initialParam.toLowerCase())
				?.isEnabled
		) {
			setCurrentTab(initialParam.toLowerCase() as ADTypes)
		} else {
			params.delete(paramNameForTabs)
			history.push({ search: '' })
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		params.delete(paramNameForTabs)
		params.append(paramNameForTabs, currentTab.toString())
		history.push({ search: params.toString() })
	}, [currentTab]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className={s.typeSwitcherWrapper}>
				<div className={s.typeSwitcherContainer}>
					{tabs.map((tab) => (
						<button
							disabled={!tab.isEnabled}
							onClick={() => {
								if (!tab.isEnabled || tab.key === currentTab)
									return
								setCurrentTab(tab.key)
							}}
							className={cn(s.typeSwitcherButton, {
								[s.typeSwitcherButton_enabled]:
									currentTab !== tab.key && tab.isEnabled,
								[s.typeSwitcherButton_active]:
									currentTab === tab.key,
							})}
							style={{ display: 'flex' }}
							key={tab.key}
						>
							{tab.icon}
							{currentTab === tab.key && <h5>{tab.title}</h5>}
						</button>
					))}
				</div>
			</div>

			<CurrentTabComponent
				bookings_type={
					tabs.find((tab) => tab.key === currentTab)?.key || ''
				}
			/>
		</>
	)
}

export default CampaignTypeSwitcher
