import React, {
	Component,
	FC,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	createContext,
} from 'react'
import ModalBookingAdjustment from '../../_provider/Bookings/content/components/ModalBookingAdjustment/ModalBookingAdjustment'
import CreateCreativeDesktopModal from '../../_client/create-campaign/Step3-Upload/CreateCreative/CreateCreativeDesktopModal'
import CreateCreativeMobileModal from '../../_client/create-campaign/Step3-Upload/CreateCreative/CreateCreativeMobileModal'
import MediaPlanModal from '../../_client/campaign-mediaplan/MediaPlanModal'
import CreateCreativeSuccessModal from '../../_client/create-campaign/Step3-Upload/CreateCreative/CreateCreativeSuccessModal'
import { Mediaplan } from 'src/components/_client/campaign-mediaplan/new-mediaplan/ui/Mediaplan'

export enum REUSABLE_MODALS {
	getAdjustmentMessage,
	createCreativeDesktopModal,
	createCreativeMobileModal,
	showMediaPlanModal,
	showCreateCreativeSuccess,
}
const ModalVariants: {
	[key in REUSABLE_MODALS]: React.FC<ReusableModalProps<any>>
} = {
	[REUSABLE_MODALS.getAdjustmentMessage]: ModalBookingAdjustment,
	[REUSABLE_MODALS.createCreativeDesktopModal]: CreateCreativeDesktopModal,
	[REUSABLE_MODALS.createCreativeMobileModal]: CreateCreativeMobileModal,
	[REUSABLE_MODALS.showMediaPlanModal]: Mediaplan,
	[REUSABLE_MODALS.showCreateCreativeSuccess]: CreateCreativeSuccessModal,
}
type state = {
	variant: REUSABLE_MODALS | null
	show: boolean
	doneValue: null | any
}
const initialState: state = {
	variant: null,
	show: false,
	doneValue: null,
}
const ReusableModalsContext = createContext<[state, React.Dispatch<any>]>([
	initialState,
	() => {},
])
export const useReusableModalsContext = () => useContext(ReusableModalsContext)

export interface ReusableModalProps<T> {
	show: boolean
	onHide: () => void
	onDone: (value: T) => void
}

function reducer(state, action): state {
	switch (action.type) {
		case 'SET_VARIANT':
			return { show: false, variant: action.payload, doneValue: null }
		case 'SET_VALUE':
			return { ...state, doneValue: action.payload }
		case 'HIDE':
			return initialState
		case 'SHOW':
			return { ...state, show: true }
		default:
			return initialState
	}
}

const ReusableModalsProvider: FC = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const Component = useMemo<React.FC<any> | null>(() => {
		if (state.variant === null) return null
		return ModalVariants[state.variant]
	}, [state.variant])

	const onHide = () => {
		dispatch({ type: 'HIDE' })
	}
	const onDone = (value) => {
		dispatch({ type: 'SET_VALUE', payload: value })
	}
	return (
		<ReusableModalsContext.Provider value={[state, dispatch]}>
			{children}
			{state.variant !== null && Component && (
				<Component show={state.show} onHide={onHide} onDone={onDone} />
			)}
		</ReusableModalsContext.Provider>
	)
}

let resolveCallback
export function useReusableModals() {
	const [state, dispatch] = useReusableModalsContext()
	useEffect(() => {
		return () => dispatch({ type: 'HIDE' })
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (state.doneValue) {
			close()
			try {
				resolveCallback(state.doneValue)
			} catch (e) {}
		}
	}, [state.doneValue]) // eslint-disable-line react-hooks/exhaustive-deps

	const close = () => {
		dispatch({
			type: 'HIDE',
		})
	}

	function showModal<TypeValue>(
		modalType: REUSABLE_MODALS
	): Promise<TypeValue> {
		dispatch({ type: 'SET_VARIANT', payload: modalType })
		dispatch({ type: 'SHOW' })
		return new Promise<TypeValue>((res, rej) => {
			resolveCallback = res
		})
	}

	return showModal
}

export default ReusableModalsProvider
