import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useState } from 'react'
import { PlacementsPickerProps } from '../../common/PlacementsPickerContent'
import { PlacementsCart } from './cart/PlacementsCart'
import { CollapseCartButton } from './collapse-cart-button/CollapseCartButton'
import { NoItemsInCart } from './no-items/NoItemsInCart'

type Props = {} & Pick<
	PlacementsPickerProps,
	| 'addedPlacements'
	| 'onRemovePlacementsFromCart'
	| 'detailedPlacementId'
	| 'getDetailedPlacement'
	| 'onClose'
>

export const RightPanel = ({
	addedPlacements,
	onRemovePlacementsFromCart,
	detailedPlacementId,
	getDetailedPlacement,
	onClose,
}: Props) => {
	const [openPanel, setOpenPanel] = useState(true)

	const handleToggleOpenPanel = () => {
		setOpenPanel(!openPanel)
	}

	return (
		<div
			style={{
				display: 'flex',
				gap: '16px',
				top: 0,
				right: 0,
				position: 'absolute',
				height: openPanel ? '100%' : 'auto',
				zIndex: 1056,
			}}
		>
			<div style={{ display: 'flex', gap: '16px' }}>
				<div style={{ padding: '16px' }}>
					<CollapseCartButton
						needRipple={false}
						count={addedPlacements.length}
						isExpanded={openPanel}
						toggleOpenPanel={handleToggleOpenPanel}
					/>
				</div>

				<motion.div
					initial={{ width: 0, opacity: 0 }}
					animate={{
						width: openPanel ? 360 : 0,
						opacity: openPanel ? 1 : 0,
					}}
					exit={{ width: 0, opacity: 0 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
					style={{
						minWidth: openPanel ? '360px' : 0,
						backgroundColor: '#fff',
					}}
				>
					{addedPlacements.length ? (
						<PlacementsCart
							onClose={onClose}
							getDetailedPlacement={getDetailedPlacement}
							detailedPlacementId={detailedPlacementId}
							addedPlacements={addedPlacements}
							onRemovePlacementsFromCart={
								onRemovePlacementsFromCart
							}
						/>
					) : (
						<NoItemsInCart />
					)}
				</motion.div>
			</div>
		</div>
	)
}
