import produce from 'immer'
import createStoreHook from '../utils/createStoreHook'
import { createStoreon } from 'storeon'
import makeStoreResettable from '../utils/makeStoreResettable'
import { storeonDevtools } from 'storeon/devtools'
import { authFetch } from '../utils/authFetch'
import {
	archiveMedia,
	BASE_URL,
	BookingsGroupOperations,
	createMediaType,
	deleteMediaType,
	exportBookings,
	patchMediaType,
	providerAdvCampaigns,
	providerArchivePlacement,
	providerBookingsList,
	providerCompanies,
	providerCounts,
	providerCreateInvite,
	providerCreatePlacement,
	providerCreateShowingPrice,
	providerDeleteInvite,
	providerDeletePlacement,
	providerDeleteShowingPrice,
	providerEditPlacement,
	providerInvites,
	providerMediaTypes,
	providerMembers,
	providerPatchBooking,
	providerPlacements,
	providerPlacementsQuery,
	providerRestorePlacement,
	restoreMediaType,
} from '../constants/api'
import moment from 'moment'
import downloadBlob from '../utils/downloadBlob'
import { BookingState } from './ADMarketTypes.types'

type ProviderDataState = {
	data: any
	ready: boolean
}

export const ProviderDataStore = createStoreon<ProviderDataState, any>([
	(store) => {
		makeStoreResettable(store, {
			data: null,
			ready: false,
		})

		store.on(
			'receive',
			produce((state, data) => {
				state.data = { ...state.data, ...data }
				state.ready = true
			})
		)
		store.on(
			'logout',
			produce((state, data) => {
				state.data = data
				state.ready = true
			})
		)
	},
	process.env.NODE_ENV !== 'production' &&
		storeonDevtools({ name: 'admarket-providerData' }),
])

export const ProviderDataStoreInterface = {
	name: 'ProviderData',

	get() {
		return ProviderDataStore.get().data
	},

	subscribe(callback) {
		return ProviderDataStore.on('@changed', callback)
	},

	checkDataIsReady() {
		return true
	},

	async fetchCounts() {
		const counts = await authFetch({ url: providerCounts, method: 'GET' })
		ProviderDataStore.dispatch('receive', {
			counts: counts,
		})
	},

	async fetchMedia() {
		const media = await authFetch({
			url: providerMediaTypes,
			method: 'GET',
		})
		ProviderDataStore.dispatch('receive', {
			mediatypes: media,
		})
	},

	async fetchBookings(
		state,
		limit,
		offset,
		adv_company,
		company,
		search,
		ordering,
		regions
	) {
		const url = new URLSearchParams()
		url.append('limit', limit)
		url.append('offset', offset)
		if (state !== 'all') url.append('state', state)
		if (search) url.append('q', search)
		if (ordering) url.append('o', ordering)
		if (adv_company?.length !== 0)
			adv_company?.forEach((adv) =>
				url.append('advertising_company', adv)
			)
		if (regions.length !== 0) {
			regions?.forEach((region) => url.append('placement_region', region))
		}
		if (company?.length !== 0)
			company?.forEach((company) => url.append('company', company))
		const bookings: any = await authFetch({
			url: `${providerBookingsList}?${url.toString()}`,
			method: 'GET',
		})
		if (
			bookings?.count !== 0 &&
			bookings?.results?.length === 0 &&
			offset !== 0
		) {
			return new Error('wrong offset')
		} else {
			ProviderDataStore.dispatch('receive', {
				bookings: bookings,
			})
		}
	},

	async fetchInvites() {
		const invites: any[] = await authFetch({
			url: providerInvites,
			method: 'GET',
		})
		if (invites && invites.length !== 0) {
			ProviderDataStore.dispatch('receive', {
				invites: invites,
			})
		} else {
			ProviderDataStore.dispatch('receive', {
				invites: [],
			})
		}
	},

	async fetchBookingsFilters() {
		const companies = await authFetch({
			url: providerCompanies,
			method: 'GET',
		})
		const adv_campaigns = await authFetch({
			url: providerAdvCampaigns,
			method: 'GET',
		})

		const regions = (await authFetch({
			url: `${BASE_URL}/provider/regions/`,
			method: 'GET',
		})) as { region: string }[]
		try {
			Promise.all([companies, adv_campaigns]).then(() => {
				ProviderDataStore.dispatch('receive', {
					bookings_filters: {
						companies,
						adv_campaigns,
						regions: regions.map((el) => ({
							id: el.region,
							name: el.region,
						})),
					},
				})
			})
		} catch (e) {
			ProviderDataStore.dispatch('receive', {
				bookings_filters: null,
			})
		}
	},

	async sendInvite(email) {
		try {
			await authFetch({
				url: providerCreateInvite,
				method: 'POST',
				body: { email },
			})
			await ProviderDataStoreInterface.fetchInvites()
		} catch (e: any) {
			return e.data
		}
	},

	async deleteInvite(id) {
		await authFetch({
			url: providerDeleteInvite.params(id),
			method: 'DELETE',
		})
		await ProviderDataStoreInterface.fetchInvites()
	},

	async fetchMembers() {
		const members: any[] = await authFetch({
			url: providerMembers,
			method: 'GET',
		})
		if (members && members.length !== 0) {
			ProviderDataStore.dispatch('receive', {
				members: members,
			})
		} else {
			ProviderDataStore.dispatch('receive', {
				members: [],
			})
		}
	},

	async AcceptBooking(id) {
		const body = { state: 'confirm' }
		await authFetch({
			url: providerPatchBooking.params(id),
			method: 'PATCH',
			body,
		})
	},

	async patchProviderBooking(
		bookingId: string,
		body: {
			state: 'confirm' | 'reject' | 'adjustment'
			adjusment_msg?: string
			reject_msg?: string
			is_docs_required: boolean
		}
	) {
		await authFetch({
			url: providerPatchBooking.params(bookingId),
			method: 'PATCH',
			body,
		})
	},

	async RejectBooking(id) {
		const body = { state: 'reject' }
		await authFetch({
			url: providerPatchBooking.params(id),
			method: 'PATCH',
			body,
		})
	},

	async AdjustBooking(id, message) {
		const body = { state: 'adjustment', adjustment_msg: message }
		await authFetch({
			url: providerPatchBooking.params(id),
			method: 'PATCH',
			body,
		})
	},

	async fetch() {
		await ProviderDataStoreInterface.fetchCounts()
		await ProviderDataStoreInterface.fetchMedia()
		await ProviderDataStoreInterface.fetchBookingsFilters()
	},

	async getCounts() {
		await ProviderDataStoreInterface.fetchCounts()
	},
	async refetchAll() {
		await ProviderDataStoreInterface.fetch()
	},

	async refetchMedia() {
		await ProviderDataStoreInterface.refetchAll()
	},

	async refetchPlacements() {
		await ProviderDataStoreInterface.refetchAll()
	},

	async ArchiveMedia(id) {
		await authFetch({ url: archiveMedia.params(id), method: 'POST' })
		await ProviderDataStoreInterface.refetchMedia()
	},

	async RestoreMedia(id) {
		await authFetch({ url: restoreMediaType.params(id), method: 'POST' })
		await ProviderDataStoreInterface.refetchMedia()
	},

	async DeleteMedia(id) {
		await authFetch({ url: deleteMediaType.params(id), method: 'POST' })
		await ProviderDataStoreInterface.refetchMedia()
	},

	async CreateMedia(body) {
		await authFetch({ url: createMediaType, method: 'POST', body })
		await ProviderDataStoreInterface.refetchMedia()
	},

	async PatchMedia(id, body) {
		await authFetch({
			url: patchMediaType.params(id),
			method: 'PATCH',
			body,
		})
		await ProviderDataStoreInterface.refetchMedia()
	},

	async fetchPlacementsQuery(query: string) {
		try {
			const placements: any = await authFetch({
				url: providerPlacementsQuery.params(query),
				method: 'GET',
			})
			ProviderDataStore.dispatch('receive', {
				placements: placements,
			})
		} catch (e) {
			console.log(e)
		}
	},

	async fetchPlacements(state, limit, offset, search?: string) {
		const placements: any = await authFetch({
			url: providerPlacements.params(
				state === 'all' || state === 'archive' ? '' : state,
				limit,
				offset,
				state === 'archive',
				search
			),
			method: 'GET',
		})

		if (
			placements?.count !== 0 &&
			placements?.results?.length === 0 &&
			offset !== 0
		) {
			return new Error('wrong offset')
		} else {
			ProviderDataStore.dispatch('receive', {
				placements: placements,
			})
		}
	},

	async CreatePlacement(body, showing_prices) {
		const { id } = await authFetch({
			url: providerCreatePlacement,
			method: 'POST',
			body,
		})
		if (showing_prices.length !== 0 && id) {
			for (const price of showing_prices) {
				await ProviderDataStoreInterface.createShowingPrice(
					id,
					price,
					true
				)
			}
		}
	},

	async EditPlacement(id, body, returnAnswer = false) {
		const answer = await authFetch({
			url: providerEditPlacement.params(id),
			method: 'PATCH',
			body,
		})

		if (returnAnswer) return answer
	},

	async createShowingPrice(id, body, withoutRefetch = false) {
		await authFetch({
			url: providerCreateShowingPrice.params(id),
			method: 'POST',
			body,
		})
		if (withoutRefetch) return
		await ProviderDataStoreInterface.refetchPlacements()
	},

	async deleteShowingPrice(id) {
		await authFetch({
			url: providerDeleteShowingPrice.params(id),
			method: 'DELETE',
			raw: true,
		})
		await ProviderDataStoreInterface.refetchPlacements()
	},

	async ArchivePlacement(id) {
		await authFetch({
			url: providerArchivePlacement.params(id),
			method: 'POST',
		})
	},

	async RestorePlacement(id) {
		await authFetch({
			url: providerRestorePlacement.params(id),
			method: 'POST',
			body: { in_archive: false },
		})
	},

	async DeletePlacement(id) {
		await authFetch({
			url: providerDeletePlacement.params(id),
			method: 'DELETE',
		})
	},

	async DisablePlacement(id, activation_date) {
		const answ: any = await ProviderDataStoreInterface.EditPlacement(
			id,
			{
				is_active: false,
				activation_date: activation_date
					? moment(activation_date).format('YYYY-MM-DD')
					: null,
			},
			true
		)
		if (answ) return answ?.id
	},
	async EnablePlacement(id) {
		const answ: any = await ProviderDataStoreInterface.EditPlacement(
			id,
			{
				is_active: true,
			},
			true
		)
		if (answ) return answ?.id
	},
	async ExportBookings(id: number | number[]) {
		const answ: Response = await authFetch({
			url: exportBookings,
			method: 'POST',
			body: { bookings: Array.isArray(id) ? id : [id] },
			raw: true,
		})
		if (answ.ok) {
			const blob = await answ.blob()
			const date = moment(moment.now()).format('DD.MM.YYYY_HH:mm')
			downloadBlob(blob, `AdMarket Брони ${date}`, 'xlsx')
		} else throw new Error('Ошибка при загрузке')
	},
	async GroupBookingsOperation(
		action: 'accept' | 'edit' | 'reject',
		ids: number[],
		message: string = ''
	) {
		let body: any = {
			bookings: ids,
		}
		switch (action) {
			case 'accept':
				body.state = BookingState.Confirm
				break
			case 'reject':
				body.state = BookingState.Reject
				break
			case 'edit':
				body.state = BookingState.Adjustment
				body.adjustment_msg = message
				break
		}
		try {
			const answ: any = await authFetch({
				url: BookingsGroupOperations,
				method: 'POST',
				body,
			})
		} catch (e) {
			return e
		}
	},
}

const useProviderData = createStoreHook(ProviderDataStoreInterface)
export default useProviderData
