import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import InputWithEndText from 'src/components/UtilityComponents/InputWithEndText'
import { BASE_URL } from 'src/constants/api'
import useCampaignData from 'src/stores/campaignData'
import { authFetch } from 'src/utils/authFetch'
import useDebounce from 'src/utils/useDebounce'
import useResponsive from 'src/utils/useResponsive'
import { getError } from 'src/utils/getError'
import { NumericFormat } from 'react-number-format'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import s from './mcd.module.scss'
import { ReactComponent as EditIcon } from 'src/assets/images/edit.svg'
import { ReactComponent as MapIcon } from 'src/assets/images/pin-2.svg'
import {
	DetailedMCD,
	PlacementShort,
	PlacementType,
} from 'src/stores/ADMarketTypes.types'
import { MapDataType } from 'src/components/_client/create-campaign/Step2-ADformats/outdoor-ad/types'
import { MassCoverageModalMap } from 'src/components/_client/create-campaign/Step2-ADformats/outdoor-ad/components/outdoor-mass-coverage/modals'
import { CreateMassCoverageDistrictBookingBody } from 'src/stores/types/districtBookingTypes'
import useUserData from 'src/stores/userData'

type MCDFields = {
	amount: string
}
type MCDErrors = {
	amount: string | undefined
	placements: string | undefined
}

const fetchDetailedMCDInfo = async (campaignId: string) => {
	const res = await authFetch<DetailedMCD>({
		url: `${BASE_URL}/adv_companies/${campaignId}/metro-adv/`,
		method: 'GET',
	})
	return res
}

const fetchMCDPlacements = async () => {
	const res = await authFetch<PlacementShort[]>({
		url: `${BASE_URL}/adv_companies/placements/?is_mcd=true`,
		method: 'GET',
	})
	return res
}

type UpdateMCDBody = {
	placement_ids: number[]
	center_lat: string
	center_lon: string
	radius: number
}

const updateMCDAdv = async (
	campaignId: string,
	body: Partial<UpdateMCDBody>
) => {
	const res = await authFetch({
		url: `${BASE_URL}/adv_companies/${campaignId}/metro-adv/`,
		method: 'PATCH',
		body,
	})
	return res
}
type Props = {}
export const MCDControls = ({}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [detailedMCD, setDetailetMCD] = useState<DetailedMCD | null>(null)
	const [userData, _] = useUserData()

	const minAddedItemsCount = useMemo(() => {
		return userData?.config?.min_count_selected_place_metro
			? userData.config.min_count_selected_place_metro
			: 5
	}, [userData])

	const [mapData, setMapData] = useState<MapDataType | null>(null)
	const [isMapModalOpen, setIsMapModalOpen] = useState(false)

	const removeAdFormatErrorByErrorId = useAdformatsStore(
		(state) => state.removeAdFormatErrorByErrorId
	)
	const minBudget = useMemo(() => {
		return campaign.metro.min_budget
	}, [campaign])

	const actualAmount = useMemo(() => {
		return parseFloat(campaign.metro?.amount || '0')
	}, [campaign])

	const [amount, setAmount] = useState(campaign.metro.amount || '0')

	const debouncedAmount = useDebounce(amount, 400)

	const [errors, setErrors] = useState<MCDErrors>(() => ({
		amount:
			parseFloat(amount) < minBudget
				? `Минимальный бюджет ${minBudget} ₽`
				: undefined,
		placements: undefined,
	}))

	const handleValidate = useCallback(
		(field: keyof MCDFields, value: string | number) => {
			const validateErrors: MCDErrors = {
				...errors,
			}

			if (field === 'amount') {
				const validValue = parseFloat(value as string)

				if (value === '') {
					validateErrors.amount = 'Добавьте бюджет'
				} else if (isNaN(validValue)) {
					validateErrors.amount = 'Некорректное значение'
				} else if (validValue < minBudget) {
					validateErrors.amount = `Минимальный бюджет ${minBudget} ₽`
				} else {
					validateErrors.amount = undefined
				}
			}
			return validateErrors
		},
		[errors, minBudget]
	)

	const updateMCD = async (field: keyof MCDFields, value: string) => {
		try {
			const validationResult = handleValidate(field, value)

			if (validationResult[field]) {
				setErrors(validationResult)
				return
			}

			const body = { [field]: value }

			//сброс isSelected state
			if (campaign.metro?.is_selected) {
				Object.assign(body, { is_selected: false })
			}

			await authFetch({
				method: 'PATCH',
				url: `${BASE_URL}/adv_companies/${campaign.id}/metro-adv/`,
				body,
			})
			await campaignInterface.refetchSelected()
			removeAdFormatErrorByErrorId('mcd', 'mcd_amount')
			setErrors((prev) => ({ ...prev, amount: '' }))
		} catch (e: any) {
			const error = getError(e)

			if (error?.data?.amount) {
				setErrors((prev) => ({
					...prev,
					amount: error.data.amount,
				}))
			}
		} finally {
		}
	}

	const handleAmountChange = (value: string | undefined) => {
		if (value) {
			const newAmount = value
				.replaceAll(' ', '')
				.replace('₽', '')
				.replace(',', '.')
			setAmount(newAmount)
		} else {
			setErrors((prev) => ({ ...prev, amount: 'Введите бюджет' }))
		}
	}

	const handleMapOpen = async () => {
		const placements = await fetchMCDPlacements()

		const mcdCenter: [number, number] =
			!detailedMCD?.center_lat || !detailedMCD?.center_lon
				? [55.75222, 37.61556]
				: [
						parseFloat(detailedMCD?.center_lat),
						parseFloat(detailedMCD?.center_lon),
				  ]

		await setMapData({
			amount: '0',
			center: mcdCenter,
			cityId: 0,
			cityName: '',
			currentPlacements: detailedMCD!
				.placements as unknown as PlacementType[],
			placements: placements,
			districtBookingId: 0,
			radius: detailedMCD!.radius || 500,
		})
		setIsMapModalOpen(true)
	}

	const handleSubmit = async (
		body: CreateMassCoverageDistrictBookingBody
	) => {
		try {
			const updateBody: Partial<UpdateMCDBody> = {
				center_lat: body.center_lat.toString(),
				center_lon: body.center_lon.toString(),
				placement_ids: body.placement_ids,
				radius: parseFloat(body.radius || '0'),
			}

			await updateMCDAdv(campaign.id, updateBody)
			const updatedMCD = await fetchDetailedMCDInfo(campaign.id)
			setDetailetMCD(updatedMCD)
			setErrors((prev) => ({ ...prev, placements: '' }))
			setIsMapModalOpen(false)
		} catch (e) {
		} finally {
		}
	}
	useEffect(() => {
		if (campaign?.id) {
			fetchDetailedMCDInfo(campaign?.id)
				.then((res) => {
					setDetailetMCD(res)
					if (res?.placements.length < minAddedItemsCount) {
						setErrors((prev) => ({
							...prev,
							placements: `Добавьте минимум ${minAddedItemsCount} мест`,
						}))
					}
				})
				.catch((e) => {
					console.error(e)
				})
		}
	}, [campaign.metro?.id])

	useEffect(() => {
		if (actualAmount !== parseFloat(amount)) {
			updateMCD('amount', debouncedAmount)
		} else {
			const validateErrors = handleValidate('amount', amount)
			setErrors(validateErrors)
		}
	}, [debouncedAmount, actualAmount])

	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)

	useEffect(() => {
		if (!!errors.amount) {
			addNewAdFormatError('mcd', {
				id: 'mcd_amount',
				field: 'Бюджет',
				message: errors.amount || 'Ошибка в бюджете',
			})
		} else {
			removeAdFormatErrorByErrorId('mcd', 'mcd_amount')
		}

		if (!!errors.placements) {
			addNewAdFormatError('mcd', {
				id: 'mcd_placements',
				field: 'Места',
				message: errors.placements || 'Ошибка в местах',
			})
		} else {
			removeAdFormatErrorByErrorId('mcd', 'mcd_placements')
		}
	}, [errors])

	const { isMobile } = useResponsive()
	return (
		<>
			<Form>
				<Row
					style={{
						display: 'flex',
						flexDirection: isMobile ? 'column' : 'row',
						gap: isMobile ? '1rem' : 0,
					}}
				>
					<Form.Group as={Col} id="mcd_amount">
						<Form.Label>Бюджет</Form.Label>

						<NumericFormat
							decimalScale={2}
							decimalSeparator=","
							value={amount}
							suffix=" ₽"
							thousandSeparator={' '}
							customInput={InputWithEndText}
							onChange={(e) => handleAmountChange(e.target.value)}
							size={'lg'}
							isInvalid={!!errors.amount}
							FeedBackComponent={
								<Form.Control.Feedback type="invalid">
									{errors.amount}
								</Form.Control.Feedback>
							}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Оплата</Form.Label>
						<Form.Control
							type={'text'}
							value={'За показы'}
							disabled
							size={'lg'}
						/>
					</Form.Group>
				</Row>

				{detailedMCD && (
					<div
						className={s.placements}
						id={'mcd_placements'}
						style={{
							borderColor: errors.placements ? 'red' : 'auto',
						}}
					>
						<div className="d-flex align-items-center justify-content-between">
							<div className="d-flex flex-column gap-1">
								<div className="d-flex align-items-center justify-content-between gap-3">
									<MapIcon />
									<h6 className="m-0">{`Добавлено мест: ${detailedMCD.placements.length}`}</h6>
								</div>

								{errors.placements && (
									<small style={{ color: '#DC3545' }}>
										{errors.placements}
									</small>
								)}
							</div>

							<Button
								style={{
									display: 'flex',
									gap: '4px',
									borderRadius: '12px',
									padding: '8px 16px',
								}}
								variant="light"
								onClick={handleMapOpen}
							>
								<EditIcon />
								<p className="m-0">Изменить</p>
							</Button>
						</div>
					</div>
				)}

				{isMapModalOpen && mapData && (
					<MassCoverageModalMap
						onSubmit={handleSubmit}
						mapData={mapData}
						open={isMapModalOpen}
						onClose={() => setIsMapModalOpen(false)}
						minAddedItemsCount={minAddedItemsCount}
					/>
				)}
			</Form>
		</>
	)
}
