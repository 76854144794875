import { set } from 'immer/dist/internal'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import FileInput from 'src/components/UtilityComponents/FileInput'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import { ObjectValue } from 'src/components/UtilityComponents/ProgressFileInput'
import useCampaignData from 'src/stores/campaignData'
import { getError } from 'src/utils/getError'
import useResponsive from 'src/utils/useResponsive'
import { DocumentsUploadForm } from '../../../internet/DocumentsUploadForm'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL } from 'src/constants/api'
import { InternetState } from 'src/stores/ADMarketTypes.types'
import { useCampaignInfoStore } from '../../../store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from '../../../store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantInputButton } from '../../../common/edit-vinstant-input-button/EditVinstantInputButton'
import useNotificationData from 'src/stores/notificationsData'
import { EditVinstantProcessingWrapped } from '../../../common/edit-vinstant-processing/EditVinstantProcessing'

type Props = {
	open: boolean
	onClose: () => void
	onEditVinstantClick: () => void
}
export const MCDEditModal = ({ onClose, open, onEditVinstantClick }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const { isMobile } = useResponsive()
	const [isDocsSatisfied, setIsDocsSatisfied] = useState(
		!campaign.metro.is_docs_required
	)

	const moveMCRToModeration = async () => {
		const nextMCDState =
			campaign.state === 'moderation'
				? InternetState.Inner_Moderation
				: InternetState.Moderation
		await authFetch({
			url: `${BASE_URL}/adv_companies/${campaign.id}/metro-adv/`,
			method: 'PATCH',
			body: {
				state: nextMCDState,
			},
		})
		await campaignInterface.refetchSelected()
		onClose()
	}
	return (
		<>
			{isMobile ? (
				<MobileModal
					show={open}
					onHide={onClose}
					title={'Исправление рекламного места'}
				>
					<div className={'d-flex flex-column h-100'}>
						<div style={{ flex: '1 1 auto' }}>
							<MCDEditForm
								onEditVinstantClick={onEditVinstantClick}
								setIsDocsSatisfied={setIsDocsSatisfied}
							/>
						</div>
						<Dropdown.Divider className={' mt-3 mb-3'} />
						<div className={'d- flex flex-column'}>
							<Button
								variant="secondary"
								onClick={onClose}
								className={'w-100 mb-2'}
							>
								Отменить
							</Button>
							<Button
								variant="primary"
								disabled={!isDocsSatisfied}
								onClick={moveMCRToModeration}
								className={'w-100 mb-3'}
							>
								Отправить на модерацию
							</Button>
						</div>
					</div>
				</MobileModal>
			) : (
				<Modal
					show={open}
					onHide={onClose}
					backdrop="static"
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'} className={'ms-2'}>
							Исправление рекламного места
						</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ padding: '24px' }}>
						<MCDEditForm
							onEditVinstantClick={onEditVinstantClick}
							setIsDocsSatisfied={setIsDocsSatisfied}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={onClose}>
							Отменить
						</Button>
						<Button
							variant="primary"
							disabled={!isDocsSatisfied}
							onClick={moveMCRToModeration}
						>
							Отправить на модерацию
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</>
	)
}

type MCREditFormProps = {
	setIsDocsSatisfied: (isDocsSatisfied: boolean) => void
	onEditVinstantClick: () => void
}
export const MCDEditForm = ({
	setIsDocsSatisfied,
	onEditVinstantClick,
}: MCREditFormProps) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [content, setContent] = useState<ObjectValue | undefined>(() => {
		if (campaign.metro?.image) {
			return {
				name: campaign.metro.image.split('.').pop() || '',
				src: campaign.metro.image!,
			}
		}
		if (campaign.metro?.video) {
			return {
				name: campaign.metro.video.split('.').pop() || '',
				src: campaign.metro.video!,
			}
		}
		return undefined
	})
	const [downloadError, setDownloadError] = useState('')
	const handleUpload = async (file: File) => {
		try {
			const result = await campaignInterface.uploadMCDCreative(
				file,
				campaign.id
			)

			if (result) {
				const newContent = result.image || result.video
				if (newContent) {
					setContent({
						name: newContent.split('.').pop() || '',
						src: newContent,
					})
					setDownloadError('')
				}
			}
			await campaignInterface.refetchSelected()
		} catch (e) {
			const error = getError(e)
			if (error?.data?.video || error?.data?.image) {
				if (error?.data?.video) {
					setDownloadError(error.data.video?.join(', '))
				}

				if (error?.data?.image) {
					setDownloadError(error.data.image?.join(', '))
				}
			}
		}
	}
	const handleDeleteContent = () => {
		setContent(undefined)
	}

	const bookingVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'metro_id',
			itemId: campaign.public_transport?.id?.toString() || '',
		})
	)
	const [_, notificationsInterface] = useNotificationData()
	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated('metro')

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
			{editCreativeVinstantStatus === 'start' ? (
				<EditVinstantProcessingWrapped />
			) : (
				<FileInput
					ExtraButton={
						bookingVinstantLink ? (
							<EditVinstantInputButton
								onClick={onEditVinstantClick}
							/>
						) : null
					}
					error={downloadError}
					onUpload={handleUpload}
					value={content}
					onRemove={handleDeleteContent}
					size={campaign.metro?.image ? 5 : 10}
					allowedExtensions={['mp4', 'jpg']}
					descr={
						'Изображения — JPG. Видео - MP4. Разрешение: 1080 x 1920 px. Размер: JPG до 5 МБ, MP4 до 10 МБ'
					}
				/>
			)}

			{campaign.metro?.is_docs_required && (
				<DocumentsUploadForm
					onDocumentLoaded={() => setIsDocsSatisfied(true)}
					adFormatId={campaign.metro.id}
					campaignId={campaign.id}
					title="Загрузить документы"
					type="metro"
				/>
			)}
		</div>
	)
}
