import React from 'react'
import {
	MoscowCentRingRequirementsArticle,
	ArticleItem,
} from './MoscowCentRingRequirementsArticle'
import useResponsive from 'src/utils/useResponsive'

export const MCRAnimationArticle = (props: any) => {
	return (
		<MoscowCentRingRequirementsArticle
			title={
				<div>
					<span>Дла анимированного контента</span>
					<p
						style={{
							margin: '0.25rem 0 0 0',
							fontSize: '14px',
							fontWeight: 400,
							lineHeight: '22px',
							letterSpacing: '-0.03em',
						}}
					>
						Обязательные требования
					</p>
				</div>
			}
		>
			<>
				<ArticleItem title="Формат:">mp4</ArticleItem>
				<ArticleItem title="Размер:">до 10 МБ</ArticleItem>
				<ArticleItem title="Длительность: ">
					от 5 до 60 секунд
				</ArticleItem>
				<ArticleItem title="Кодек:">H.264</ArticleItem>
				<ArticleItem
					title="Дополнительно:"
					style={{ border: 'none', paddingBottom: 0 }}
				>
					<ul>
						<li>пиксели квадратные</li>
						<li>без субтитров</li>
						<li>без звуковой дорожки</li>
					</ul>
				</ArticleItem>
			</>

			<div style={{ marginTop: '0.5rem' }}>
				<p
					style={{
						margin: '0',
						fontSize: '14px',
						lineHeight: '22px',
						letterSpacing: '-0.03em',
					}}
				>
					Рекомендуемые требования
				</p>
				<ArticleItem title="Кадровая частота: ">
					25 FPS (PAL)
				</ArticleItem>
				<ArticleItem title="Битрейт:">25 000</ArticleItem>
				{/* <ArticleItem title="Профиль:">Основной профиль 4:2</ArticleItem>
				<ArticleItem title="ReFrame:">2/4 или 8 максимум</ArticleItem>
				<ArticleItem title="Хроматическая субдискретизация:">
					4:2:0
				</ArticleItem>
				<ArticleItem title="Глубина цвета:">8 бит</ArticleItem>
				<ArticleItem title="Изображение:">Прогрессивное</ArticleItem>
				<ArticleItem
					title="Дополнительно:"
					style={{ border: 'none', paddingBottom: 0 }}
				>
					<ul>
						<li>CABAC (рекомендуется)</li>
						<li>Без пиковой частоты кадров (VFR)</li>
					</ul>
				</ArticleItem> */}
			</div>
		</MoscowCentRingRequirementsArticle>
	)
}
