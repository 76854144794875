import React from 'react'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import { defaultPlacementImage } from 'src/constants/defaultPlacementImageUrl'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import s from './placementItem.module.scss'
import { SelectCheckbox } from './SelectCheckbox'
import { TextOverflow } from 'src/components/UtilityComponents'
import { Badge } from 'react-bootstrap'
import { PlacementsPickerProps } from '../../../../common/PlacementsPickerContent'
import { formattedNumber } from 'src/utils'

type Props = {
	placement: PlacementShort
	isSelected: boolean
	isHovered: boolean
	isAdded: boolean
	isDetailed: boolean
	applyedPscPrice: string
} & Pick<
	PlacementsPickerProps,
	'onSelectPlacement' | 'getDetailedPlacement' | 'loading'
>

export const PlacementItem = ({
	placement,
	isSelected,
	isAdded,
	isDetailed,
	isHovered,
	onSelectPlacement,
	applyedPscPrice,
	getDetailedPlacement,
	loading,
}: Props) => {
	const image = placement.image_url || defaultPlacementImage

	const handleGetDetailedPlacement = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.stopPropagation()

		getDetailedPlacement(placement.pk)
	}
	return (
		<div
			style={{
				display: 'flex',
				gap: '8px',
				width: '100%',
				overflow: 'hidden',
				opacity: loading ? 0.5 : 1,
			}}
		>
			<div
				style={{
					maxWidth: '80px',
					maxHeight: '80px',
					flexShrink: 0,
					width: '80px',
					height: '80px',
					position: 'relative',
				}}
			>
				<SelectCheckbox
					loading={loading}
					isHovered={isHovered}
					isSelected={isSelected}
					onSelectPlacement={onSelectPlacement}
					placement={placement}
				/>
				<ImageFullScreenWrapper>
					<img
						src={image}
						alt={image}
						style={{
							width: '80px',
							height: '80px',
							borderRadius: '12px',
							objectFit: 'fill',
						}}
					/>
				</ImageFullScreenWrapper>
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '8px',
					width: '100%',
					minWidth: 0,
				}}
				onClick={(e) => handleGetDetailedPlacement(e)}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '4px',
						width: '100%',
						overflow: 'hidden',
					}}
				>
					<TextOverflow
						text={placement.placement_type_name}
						style={{ fontSize: '16px', fontWeight: 400, margin: 0 }}
					/>
					<TextOverflow
						text={placement.name}
						style={{
							fontSize: '14px',
							color: '#6C757D',
							margin: 0,
						}}
					/>
				</div>

				<div
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<h6>{formattedNumber(applyedPscPrice)} ₽ / день</h6>
					{isAdded && (
						<Badge
							style={{
								width: 'fit-content',
								alignSelf: 'flex-end',
							}}
							bg="success"
						>
							Добавлено
						</Badge>
					)}
				</div>
			</div>
		</div>
	)
}
