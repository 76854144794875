import React from 'react'
import s from './tvCommon.module.scss'
import { TVIcon } from 'src/components/_client/create-campaign/Step2-ADformats/TVAd/TVSelector'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { ReactComponent as MenuIcon } from 'src/assets/images/more-vert.svg'
import { ReactComponent as MCR } from 'src/assets/images/railway-2.svg'
import { ReactComponent as MCD } from 'src/assets/images/metro.svg'

import { Button } from 'react-bootstrap'
type Props = {
	isAdstream: boolean
	headerText: string
	price: string | null
	onMenuClick?: () => void
	iconType: 'tv' | 'public_transport' | 'metro'
}

const icons = {
	tv: TVIcon,
	public_transport: MCR,
	metro: MCD,
}

export const TvItemHeader = ({
	isAdstream,
	headerText,
	price,
	onMenuClick,
	iconType,
}: Props) => {
	const handleMenuClick = () => {
		onMenuClick && onMenuClick()
	}
	const Icon = icons[iconType]
	return (
		<div className={s.tvItem_header}>
			<div className={s.tvItem_headerInfo}>
				<div className={s.tvItem_headerTitle}>
					{<Icon />}
					<h5>{headerText}</h5>
				</div>
				<h5>{IntlFormatter.format(Number(price))}</h5>
			</div>
			<div className={s.menuContainer}>
				<Button onClick={handleMenuClick} variant={'text'}>
					<MenuIcon width={'1rem'} height={'1rem'} />
				</Button>
			</div>
		</div>
	)
}
