import moment from 'moment/moment'
import { useEffect, useMemo, useState } from 'react'
import useCampaignData from '../../../../stores/campaignData'
import declOfNum from '../../../../utils/declOfNum'

export enum TIME_VARIABLES {
	TIME_FOR_CHOOSE_PAYMENT_METHOD = 1000 * 60 * 60 * 24, // 24 hr in ms
	TIME_FOR_INVOICE = 1000 * 60 * 60 * 24, // 1 day in ms
}

const usePaymentTime = () => {
	const [campaign] = useCampaignData()
	const [now, setNow] = useState<moment.Moment>(moment())

	const TIME_FOR_PAY = useMemo<TIME_VARIABLES>(() => {
		if (campaign.active_invoices?.length !== 0)
			return TIME_VARIABLES.TIME_FOR_INVOICE
		return TIME_VARIABLES.TIME_FOR_CHOOSE_PAYMENT_METHOD
	}, [campaign])

	const startTime = useMemo<moment.Moment | null>(() => {
		if (!campaign.to_payment_waiting_dt) return null
		if (TIME_FOR_PAY === TIME_VARIABLES.TIME_FOR_CHOOSE_PAYMENT_METHOD)
			return moment(campaign.to_payment_waiting_dt)
		if (TIME_FOR_PAY === TIME_VARIABLES.TIME_FOR_INVOICE)
			return moment(campaign.active_invoices[0].created_at)
		return moment()
	}, [campaign.to_payment_waiting_dt, TIME_FOR_PAY])

	const endTime = useMemo<moment.Moment | null>(() => {
		if (!startTime) return null
		const tmp = moment(startTime)
		return tmp.add(TIME_FOR_PAY, 'milliseconds')
	}, [startTime, TIME_FOR_PAY])

	const diffMinutes = useMemo<number | null>(() => {
		if (!now.isBefore(endTime)) return null
		if (endTime && startTime) {
			return Math.round(endTime.diff(now, 'minutes'))
		}
		return 0
	}, [startTime, endTime, now, campaign]) // eslint-disable-line react-hooks/exhaustive-deps

	const formattedTimeString = useMemo<null | string>(() => {
		if (diffMinutes === null) return null
		const minutes = diffMinutes % 60
		const hours = Math.floor(diffMinutes / 60) % 24
		const days = Math.floor(diffMinutes / 60 / 24)
		let res: string = ''
		if (days) res += `${days} ${declOfNum(days, ['день', 'дня', 'дней'])} `
		if (hours)
			res += `${hours} ${declOfNum(hours, ['час', 'часа', 'часов'])} `
		if (minutes)
			res += `${minutes} ${declOfNum(minutes, [
				'минута',
				'минуты',
				'минут',
			])}`
		return res
	}, [diffMinutes])

	useEffect(() => {
		const int_ID = setInterval(() => {
			setNow(moment())
		}, 30000)
		return () => clearInterval(int_ID)
	}, [])

	return {
		isTimePassed: diffMinutes === null,
		remainingMinutes: diffMinutes,
		formattedTimeString,
		timeType: TIME_VARIABLES[TIME_FOR_PAY],
		fullTime: TIME_FOR_PAY,
	} as const
}

export default usePaymentTime
