import React from 'react'
import s from './tableActions.module.scss'
import { Button } from 'react-bootstrap'
import { ReactComponent as XlsIcon } from 'src/assets/images/xls.svg'
import { authFetch } from 'src/utils/authFetch'
import { companyInfo } from 'src/constants/api'
import useCampaignData from 'src/stores/campaignData'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import downloadBlob from 'src/utils/downloadBlob'

export const TableActions = () => {
	const [campaign] = useCampaignData()
	const statsUrl =
		campaign?.id && campaign.state === CampaignStates.Draft
			? 'forecast'
			: 'stats'

	const download = async () => {
		const answ: Response = await authFetch({
			url: companyInfo.params(campaign.id) + `${statsUrl}/export/`,
			method: 'POST',
			raw: true,
		})
		if (answ.ok) {
			const blob = await answ.blob()
			downloadBlob(blob, `Медиаплан ${campaign?.name}`, 'xlsx')
		} else throw new Error('Ошибка при загрузке')
	}

	return (
		<div className={s.actionsContainer}>
			<Button variant="light" onClick={() => download()}>
				<XlsIcon style={{ width: '20px', height: '20px' }} /> Скачать
				таблицу в Excel
			</Button>
		</div>
	)
}
