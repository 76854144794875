import React, { useEffect, useState } from 'react'
import {
	PlacementsPickerProps,
	SelectedPsc,
} from '../../../common/PlacementsPickerContent'
import { DetailedPlacementType } from '../../../../model/types'
import { fetchDetailedPlacement } from '../../../../api/fetchDetailedPlacement'
import useCampaignData from 'src/stores/campaignData'
import { Spinner } from 'react-bootstrap'
import { DetailsContent } from './DetailsContent'
import { motion } from 'framer-motion'
import s from './details.module.scss'

type Props = { selectedPscForDetailed: SelectedPsc | null } & Pick<
	PlacementsPickerProps,
	| 'detailedPlacementId'
	| 'onCloseDetailedPlacement'
	| 'onAddDetailedPlacement'
	| 'onRemovePlacementsFromCart'
	| 'addedPlacements'
>

enum LoadingState {
	pending = 'pending',
	loading = 'loading',
	loaded = 'loaded',
	error = 'error',
}

export const PlacementDetails = ({
	detailedPlacementId,
	onCloseDetailedPlacement,
	onAddDetailedPlacement,
	selectedPscForDetailed,
	onRemovePlacementsFromCart,
	addedPlacements,
}: Props) => {
	const [campaign, _] = useCampaignData()

	const [detailedPlacement, setDetailedPlacement] =
		useState<DetailedPlacementType | null>(null)

	const [loadingState, setLoadignState] = useState<LoadingState>(
		LoadingState.pending
	)

	const handleFetchDetailedPlacement = async () => {
		try {
			setLoadignState(LoadingState.loading)
			const detailed = await fetchDetailedPlacement(
				campaign.id,
				detailedPlacementId!
			)
			setDetailedPlacement(detailed)
			setLoadignState(LoadingState.loaded)
		} catch {
			setLoadignState(LoadingState.error)
		}
	}

	useEffect(() => {
		if (detailedPlacementId) {
			handleFetchDetailedPlacement()
		}
	}, [detailedPlacementId])

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.3, ease: 'easeInOut' }}
			style={{
				width: 'max-content',
				height: '100%',
				overflow: 'hidden',
				pointerEvents: 'auto',
			}}
		>
			<div
				className={s['m-hidden-scrollbar']}
				style={{
					minWidth: '360px',
					borderRadius: '16px',
					width: '360px',
					height: '100%',
					backgroundColor: '#fff',
					overflowY: 'scroll',
				}}
			>
				{loadingState === LoadingState.loading && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<Spinner animation="border" variant="primary" />
					</div>
				)}

				{loadingState === LoadingState.error && <div>Ошибка</div>}

				{loadingState === LoadingState.loaded && (
					<DetailsContent
						addedPlacements={addedPlacements}
						onRemovePlacementsFromCart={onRemovePlacementsFromCart}
						selectedPscForDetailed={selectedPscForDetailed}
						onAddDetailedPlacement={onAddDetailedPlacement}
						details={detailedPlacement!}
						onCloseDetailedPlacement={onCloseDetailedPlacement}
					/>
				)}
			</div>
		</motion.div>
	)
}
