import { motion } from 'framer-motion'
import React, { useState } from 'react'
import declOfNum from 'src/utils/declOfNum'
import { DetailItemNew } from 'src/utils/DetailItem'
import ModalMediaFormatQuickView from '../details/ModalMediaFormatQuickView'
import s from './PlacementInfo.module.scss'

export function PlacementInfo({ placement }) {
	const [showModalQuickView, setShowModalQuickView] = useState(false)
	return (
		<motion.div
			initial={{ opacity: 0, y: -10 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3 }}
			className={s.infoContainer}
		>
			<div className={s.imgFrame}>
				<img
					className={s.infoimg}
					src={
						placement.image ||
						'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
					}
				/>
			</div>

			<div className={s.items}>
				<DetailItemNew left={'ID:'} right={placement.external_id} />

				<DetailItemNew
					left={'Медиа-формат:'}
					right={
						<>
							<a
								href="/"
								onClick={(e) => {
									e.preventDefault()
									setShowModalQuickView(true)
								}}
							>
								{placement.media_type.name}
							</a>
						</>
					}
				/>
				{showModalQuickView && (
					<ModalMediaFormatQuickView
						media_format={placement.media_type}
						show={showModalQuickView}
						onHide={() => setShowModalQuickView(false)}
					/>
				)}

				<DetailItemNew
					left={'Тип:'}
					right={placement.placement_type.image_type}
				/>

				<DetailItemNew left={'Размер:'} right={placement.size} />

				<DetailItemNew
					left={'Рекламу увидят:'}
					right={
						parseFloat(placement?.ots_per_day || '0') + ' чел./день'
					}
				/>

				<DetailItemNew
					left={'Длительность показа:'}
					right={
						placement.media_type.duration +
						' ' +
						declOfNum(placement.media_type.duration, [
							'секунда',
							'секунды',
							'секунд',
						])
					}
				/>

				<DetailItemNew
					left={'График показов:'}
					right={
						placement.only_work_days
							? 'Только рабочие дни, '
							: 'В рабочие и выходные дни, '
					}
				/>

				<DetailItemNew
					left={'Месторасположение:'}
					right={
						<>
							{placement.lat.replace('.', '°') + "'N"},{' '}
							{placement.lon.replace('.', '°') + "'E"}
						</>
					}
				/>
			</div>
		</motion.div>
	)
}
