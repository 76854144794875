import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import useResponsive from 'src/utils/useResponsive'
import { PlacementsFilter } from '../../model/types'
import { DoorType } from 'src/stores/technical-requirements-store'
import { fetchPlacements } from '../../api/fetchPlacements'
import {
	PlacementsPickerContent,
	SelectedPlacement,
} from './PlacementsPickerContent'
import useCampaignData from 'src/stores/campaignData'

type Props = {
	onClose: () => void
	open: boolean
}
export const PlacementsPickerWrapper = ({ onClose, open }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()
	const isMobile = { useResponsive }

	const [initialized, setInitialized] = useState(false)
	const [placements, setPlacements] = useState<PlacementShort[]>([])
	const [placementsFilter, setPlacementsFilter] = useState<PlacementsFilter>({
		door_type: DoorType.indoor,
		q: '',
		placement_type_name: '',
	})

	const [focusCenter, setFocusCenter] = useState<[number, number]>([
		parseFloat(campaign.show_regions[0].lat),
		parseFloat(campaign.show_regions[0].lon),
	])

	const [allPlacementTypes, setAllPlacementTypes] = useState<string[]>([])
	const [loading, setLoading] = useState(false)

	const handleFetchPlacements = async () => {
		try {
			setLoading(true)
			const placements = await fetchPlacements(placementsFilter)
			if (!initialized) {
				const placementTypes = new Set(
					placements.map((el) => el.placement_type_name)
				)

				setAllPlacementTypes(Array.from(placementTypes))
			}
			setPlacements(placements)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
			if (!initialized) {
				setInitialized(true)
			}
		}
	}

	const handleSortPlacements = (addedPlacements: SelectedPlacement[]) => {
		setPlacements((prevPlacements) => {
			return [...prevPlacements].sort((a, b) => {
				const isAAdded = addedPlacements.some(
					(added) => added.pk === a.pk
				)
				const isBAdded = addedPlacements.some(
					(added) => added.pk === b.pk
				)

				if (isAAdded && !isBAdded) return -1 // a goes first
				if (!isAAdded && isBAdded) return 1 // b goes first
				return 0 // Keep original order if both or neither are added
			})
		})
	}

	const handleFilterChange = (filter: Partial<PlacementsFilter>) => {
		setPlacementsFilter((prev) => ({ ...prev, ...filter }))
	}
	useEffect(() => {
		handleFetchPlacements()
	}, [placementsFilter])
	return (
		<Modal show={open} onHide={onClose} fullscreen>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					Добавление мест внутренней рекламы
				</Modal.Title>
			</Modal.Header>

			<Modal.Body className={'p-0'} style={{ overflow: 'hidden' }}>
				{initialized ? (
					<PlacementsPickerContent
						loading={loading}
						onClose={onClose}
						onSortPlacements={handleSortPlacements}
						focusCenter={focusCenter}
						onFocusCenterChange={setFocusCenter}
						placementTypes={allPlacementTypes}
						placements={placements}
						onChangePlacementsFilter={handleFilterChange}
					/>
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '16px',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<Spinner animation="border" variant="primary" />

						<h5 className="text-primary">Загружаем места</h5>
					</div>
				)}
			</Modal.Body>
		</Modal>
	)
}
