import { authFetch } from 'src/utils/authFetch'
import { SelectedPlacement } from '../ui/common/PlacementsPickerContent'
import { BASE_URL } from 'src/constants/api'

export const addCartPlacementsToCampaign = async (
	campaignId,
	addedPlacements: SelectedPlacement[]
) => {
	const body = {
		placement_showing_count_ids: addedPlacements.map((el) => el.psc.pk),
	}

	await authFetch<any>({
		url: `${BASE_URL}/adv_companies/${campaignId}/mass-booking/`,
		method: 'POST',
		body,
	})
}
