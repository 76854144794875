import { ForecastPublicTransport } from '../model/apiTypes'
import { MediaplanTableRow } from '../model/tableModel'

export const mapPublicTransport = (input: {
	publicTransport: ForecastPublicTransport
	extra: {
		isDividerRow: boolean
	}
}): MediaplanTableRow => {
	const { extra, publicTransport } = input

	const row: MediaplanTableRow = {
		data: {
			age: '-',
			gender: '-',
			region: 'Москва',
			showing_count: 0,
			forecast_views: parseInt(publicTransport.forecast_views_count),
			amount: parseInt(publicTransport.amount),
			format: {
				name: 'Цифровые экраны на МЦК',
				placementsCount: 0,
			},
		},
		isDividerRow: extra.isDividerRow,
		id: 'public_transport',
		subrows: [],
		subrowsOpen: false,
	}

	return row
}
