import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PlacementsPickerProps } from './PlacementsPickerContent'
import useCampaignData from 'src/stores/campaignData'

type Props = {
	isExpanded: boolean
	setIsExpanded: (isExpanded: boolean) => void
} & Pick<
	PlacementsPickerProps,
	'onChangePlacementsFilter' | 'placementTypes' | 'onFocusCenterChange'
>

export const PlacementsNav = ({
	isExpanded,
	setIsExpanded,
	onChangePlacementsFilter,
	placementTypes,
	onFocusCenterChange,
}: Props) => {
	const [campaign, _] = useCampaignData()

	const handlePlacementTypeChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		onChangePlacementsFilter({ placement_type_name: event.target.value })
	}

	const handleFocusCity = (cityId: string) => {
		if (!cityId) {
			return
		}
		const city = campaign.show_regions.find(
			(el) => el.id === Number(cityId)
		)

		if (city) {
			onFocusCenterChange([parseFloat(city.lat), parseFloat(city.lon)])
		}
	}

	return (
		<div style={{ display: 'flex', gap: '16px', pointerEvents: 'auto' }}>
			<CollapseButton
				isExpanded={isExpanded}
				setIsExpanded={setIsExpanded}
			/>
			<div style={{ display: 'flex', gap: '8px' }}>
				<Form.Select
					style={{
						width: '240px',
						height: '40px',
						borderRadius: '12px',
					}}
					aria-label="City Focus Selector"
					onChange={(event) => handleFocusCity(event.target.value)}
				>
					<option value="">Все города</option>
					{campaign.show_regions.map((city) => (
						<option key={city.id} value={city.id}>
							{city.title}
						</option>
					))}
				</Form.Select>

				<Form.Select
					aria-label="Placement Type Filter"
					onChange={handlePlacementTypeChange}
					style={{
						width: '240px',
						height: '40px',
						borderRadius: '12px',
					}}
				>
					<option value="">Все типы мест</option>
					{placementTypes.map((type) => (
						<option key={type} value={type}>
							{type}
						</option>
					))}
				</Form.Select>
			</div>
		</div>
	)
}

type CollapseButtonProps = {
	isExpanded: boolean
	setIsExpanded: (isExpanded: boolean) => void
}
const CollapseButton = ({ isExpanded, setIsExpanded }: CollapseButtonProps) => {
	return (
		<OverlayTrigger
			placement="right"
			overlay={(props) => (
				<Tooltip id="expand" {...props}>
					{isExpanded ? 'Свернуть' : 'Развернуть'}
				</Tooltip>
			)}
		>
			<div
				style={{
					zIndex: 2,
					borderRadius: '50%',
					backgroundColor: '#fff',
					width: '40px',
					minWidth: '40px',
					height: '40px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer',
				}}
				onClick={() => setIsExpanded(!isExpanded)}
			>
				<i
					style={{
						transform: isExpanded
							? 'rotate(0deg)'
							: 'rotate(-180deg)',
						transition: 'transform .5s ease-in-out',
						width: '24px',
					}}
				>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M3.4938 8.00012L9.34743 2.42896L7.96861 0.980225L0.310587 8.2687C-0.103212 8.66253 -0.103578 9.32238 0.309783 9.71667L7.96781 17.0214L9.34824 15.5742L3.50459 10.0001L19.9999 10.0012L20.0001 8.00118L11.7469 8.00065L3.4938 8.00012Z"
							fill="#212529"
						/>
					</svg>
				</i>
			</div>
		</OverlayTrigger>
	)
}
