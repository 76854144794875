import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import { PlacementsInfo } from '../model/types'

export const fetchPlacementsInfo = async (
	campaignId: string,
	placementIds: number[]
) => {
	return await authFetch<PlacementsInfo[]>({
		url: `${BASE_URL}/adv_companies/${campaignId}/placements_info/?placement_ids=${placementIds.join(
			','
		)}`,
		method: 'GET',
	})
}
