import { defaultPlacementImage } from '../constants/defaultPlacementImage'
import { AdvItemState, AdvMediaType, ImageFormats } from '../types'
import {
	AdvFormatBookingItem,
	MediaFormatViewModel,
	GarantidItem,
	PlacementViewModel,
} from '../types/viewModel.types'

export const indoorsMapper = (mediaTypes: AdvMediaType[]) => {
	const allMediaTypesWithIndoors = mediaTypes.filter((mediaType) =>
		mediaType.placements.find((placement) => placement.indoors.length > 0)
	)

	if (allMediaTypesWithIndoors.length === 0) {
		return []
	}

	const indoors = allMediaTypesWithIndoors.map(
		(mediaType): MediaFormatViewModel<GarantidItem, PlacementViewModel> => {
			const placementsWithIndoor = mediaType.placements.filter(
				(placement) => placement.indoors.length > 0
			)

			let approvedCount = 0
			let rejectedCount = 0

			const indoorBookings = placementsWithIndoor.map((placement) => {
				const item: AdvFormatBookingItem<
					GarantidItem,
					PlacementViewModel
				> = {
					item: {
						adjustment_msg: placement.indoors[0].adjustment_msg,
						booking_budget:
							placement.indoors[0].booking_budget || '0',
						id: placement.indoors[0].id,
						is_docs_required: placement.indoors[0].is_docs_required,
						media_creative: placement.indoors[0].media_creative,
						reject_msg: placement.indoors[0].reject_msg,
						placement_showing_count: {
							showing_count_per_day:
								placement.indoors[0].placement_showing_count
									.showing_count_per_day || 0,
							video_duration:
								placement.indoors[0].placement_showing_count
									.video_duration || 0,
						},
						state: placement.indoors[0].state,
					},
					placement: {
						id: placement.id,
						image: placement.image || defaultPlacementImage,
						name: placement.name,
						address: placement.address,
					},
				}

				if (item.item.state === AdvItemState.Confirm) {
					approvedCount += 1
				}
				if (
					item.item.state === AdvItemState.Reject ||
					item.item.state === AdvItemState.Adjustment
				) {
					rejectedCount += 1
				}

				return item
			})

			const imageFormats = mediaType.name
				.toLowerCase()
				.includes('colizeum')
				? [ImageFormats.jpeg, ImageFormats.jpg]
				: mediaType.image_formats

			return {
				id: mediaType.id,
				total_format_budget: indoorBookings
					.reduce((booking, current) => {
						if (
							current.item.state === AdvItemState.Canceled ||
							current.item.state === AdvItemState.Reject
						) {
							return booking
						}
						return booking + Number(current.item.booking_budget)
					}, 0)
					.toString(),
				counts: {
					total: indoorBookings.length,
					approved: approvedCount,
					rejected: rejectedCount,
				},
				booking_item: indoorBookings,
				media_type_name: mediaType.name,
				media_requirements: {
					age_restriction_requirements:
						mediaType.age_restriction_requirements,
					color_model: mediaType.color_model,
					codec: mediaType.codec,
					disclaimer_requirements: mediaType.disclaimer_requirements,
					duration: mediaType.duration,
					image_formats: imageFormats,
					image_max_size: mediaType.image_max_size,
					max_bitrate: mediaType.max_bitrate,
					max_dpi: mediaType.max_dpi,
					max_fps: mediaType.max_fps,
					max_height: mediaType.max_height,
					max_width: mediaType.max_width,
					min_bitrate: mediaType.min_bitrate,
					min_dpi: mediaType.min_dpi,
					min_fps: mediaType.min_fps,
					min_height: mediaType.min_height,
					min_width: mediaType.min_width,
					is_image: mediaType.is_image,
					is_video: mediaType.is_video,
					other_requirements: mediaType.other_requirements,
					video_formats: mediaType.video_formats,
					video_max_size: mediaType.video_max_size,
					with_audio_track: mediaType.with_audio_track,
					additional_info: mediaType.additional_info,
					aspect_ratio: mediaType.aspect_ratio,
					external_link: mediaType.external_link,
				},
			}
		}
	)
	return indoors
}
