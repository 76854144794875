import { InternetVariants } from 'src/components/_client/create-campaign/Step2-ADformats/new-adformats-step/types'
import { ForecastInternet } from '../model/apiTypes'
import { MediaplanTableRow } from '../model/tableModel'

export const mapInternet = (input: {
	internet: ForecastInternet
	type: InternetVariants
	extra: {
		region: string
		age: string
		gender: string
		isDividerRow: boolean
	}
}) => {
	const { extra, internet, type } = input

	const formatName =
		type === InternetVariants.Vk ? 'VK Реклама' : 'Яндекс.Директ'

	const row: MediaplanTableRow = {
		id: internet.id.toString(),
		data: {
			format: {
				name: formatName,
				placementsCount: 0,
			},
			region: extra.region,
			gender: extra.gender,
			age: extra.age,
			showing_count: 0,
			forecast_views: internet.forecast_views_count,
			amount: internet.amount,
		},
		isDividerRow: extra.isDividerRow,
		subrows: [],
		subrowsOpen: false,
	}

	return row
}
