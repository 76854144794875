import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ReactComponent as CartIcon } from 'src/assets/images/purchases.svg'
import s from './collapseButton.module.scss'

type TogglePannelProps = {
	isExpanded: boolean
	toggleOpenPanel: () => void
	count: number
	needRipple: boolean
}

export const CollapseCartButton = ({
	isExpanded,
	toggleOpenPanel,
	count,
	needRipple,
}: TogglePannelProps) => {
	return (
		<OverlayTrigger
			placement="left"
			overlay={(props) => (
				<Tooltip {...props}>
					{isExpanded ? 'Свернуть' : 'Развернуть'}
				</Tooltip>
			)}
		>
			<div
				style={{
					width: '64px',
					minWidth: '64px',
					height: '64px',
					zIndex: 1,
					borderRadius: '50%',
					backgroundColor: '#fff',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer',
					position: 'relative',
				}}
				onClick={toggleOpenPanel}
			>
				<i style={{ transition: 'transform .5s ease-in-out' }}>
					{!isExpanded ? (
						<CartIcon style={{ width: '32px', height: '32px' }} />
					) : (
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ transform: 'rotate(-180deg)' }}
						>
							<path
								d="M3.4938 8.00012L9.34743 2.42896L7.96861 0.980225L0.310587 8.2687C-0.103212 8.66253 -0.103578 9.32238 0.309783 9.71667L7.96781 17.0214L9.34824 15.5742L3.50459 10.0001L19.9999 10.0012L20.0001 8.00118L11.7469 8.00065L3.4938 8.00012Z"
								fill="#212529"
							/>
						</svg>
					)}
				</i>

				{/* Continuous ripple effect */}
				{!isExpanded && needRipple && (
					<div className={s['ripple-container']}>
						<span className={s.ripple}></span>
						<span className={s.delay}></span>
					</div>
				)}

				{!isExpanded && count > 0 && (
					<div
						style={{
							position: 'absolute',
							width: '22px',
							height: '22px',
							backgroundColor: '#007BFF',
							borderRadius: '50%',
							color: '#fff',
							top: -3,
							textAlign: 'center',
							fontSize: '12px',
							paddingTop: '2px',
							right: 0,
						}}
					>
						{count}
					</div>
				)}
			</div>
		</OverlayTrigger>
	)
}
