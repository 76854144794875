import React, { useEffect, useState } from 'react'
import ProgressFileInput from 'src/components/UtilityComponents/ProgressFileInput'
import useCampaignData from 'src/stores/campaignData'
import { CampaingDocsType } from 'src/stores/types/campaingDocsType'

type DocsListProps = {
	campaignId: string
	type:
		| 'vk'
		| 'yandex'
		| 'teletarget'
		| 'adstream'
		| 'booking'
		| 'public_transport'
		| 'districtbooking'
		| 'metro'
	adFormatId: number
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	onDocumentLoaded?: React.Dispatch<React.SetStateAction<boolean>>
}

export const DocsList = ({
	campaignId,
	type,
	adFormatId,
	setIsLoading,
	onDocumentLoaded,
}: DocsListProps) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [docs, setDocs] = useState<Array<CampaingDocsType>>([])

	const fetchList = async () => {
		setIsLoading(true)
		const result = await campaignInterface.fetchCampaignDocsList(campaignId)
		setDocs(result)
		setIsLoading(false)
	}
	useEffect(() => {
		fetchList()
	}, [])

	const updateUrl = `/core/api/v1/adv_companies/${campaignId}/docs/`
	const removeUrl = `/core/api/v1/adv_companies/docs/${campaignId}`

	const handleDocWasDeleted = (docId) => {
		setDocs(docs?.filter((el) => el.id !== docId))

		onDocumentLoaded && onDocumentLoaded(true)
		setIsLoading(false)
	}
	const handleDocWasUploaded = (newDoc: CampaingDocsType) => {
		setDocs([...docs, newDoc])

		onDocumentLoaded && onDocumentLoaded(true)
		setIsLoading(false)
	}

	const filteredDocs = docs.filter((el) => el[type] === adFormatId)

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
			{!!docs.length &&
				filteredDocs.map((el) => (
					<ProgressFileInput
						onLoadedMessage="Документ загружен"
						key={el.id}
						initialValue={{ name: el.file_name, src: el.file }}
						nameInBody="file"
						onFileDelete={() => handleDocWasDeleted(el.id)}
						setIsLoading={() => setIsLoading(true)}
						updateUrl={updateUrl}
						removeUrl={`/core/api/v1/adv_companies/docs/${el.id}`}
						removeMethod="DELETE"
						updateMethod="POST"
					/>
				))}
			<ProgressFileInput
				description="Документ PDF формата. Размер - не более 20 МБ"
				key={docs.length}
				allowedExtension={['pdf']}
				onLoadedMessage="Документ загружен"
				initialValue={{ name: '', src: '' }}
				nameInBody="file"
				onFileLoad={handleDocWasUploaded}
				updateUrl={updateUrl}
				removeUrl={removeUrl}
				removeMethod="DELETE"
				updateMethod="POST"
				additionalBody={{ [type]: adFormatId }}
			/>
		</div>
	)
}
