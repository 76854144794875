import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import useCampaignData from 'src/stores/campaignData'
import { formattedNumber } from 'src/utils'
import declOfNum from 'src/utils/declOfNum'
import DetailItem from 'src/utils/DetailItem'

type Props = {
	details: PlacementType
}
export const PlacementsDetailsCommonList = ({ details }: Props) => {
	const [_, campaignInterface] = useCampaignData()
	return (
		<>
			<h5 className={'mb-1'}>{details.placement_type.name}</h5>
			<span className={'text-secondary'} style={{ fontSize: '14px' }}>
				{details.name}
			</span>
			<Dropdown.Divider className={'m-0 p-0 mt-3'} />
			<DetailItem left={'Размер'} right={details.size} />
			<DetailItem
				left={'Рекламу увидят'}
				right={
					details?.ots ? (
						`${formattedNumber(details?.ots_ac)} человек`
					) : (
						<span className={'text-secondary'}>Неизвестно</span>
					)
				}
			/>
			{details.screen_count && (
				<DetailItem
					left={'Количество экранов'}
					right={details?.screen_count}
				/>
			)}
			{details?.media_type?.duration && (
				<DetailItem
					left={'Длительность показа'}
					right={
						details?.media_type?.duration +
						' ' +
						declOfNum(details?.media_type?.duration, [
							'секунда',
							'секунды',
							'секунд',
						])
					}
				/>
			)}
			<DetailItem
				left={'График показов'}
				right={
					<>
						{details.only_work_days
							? 'Только рабочие дни, '
							: 'В рабочие и выходные дни, '}
						<br />{' '}
						{details?.works_on_holidays
							? 'включая праздничные'
							: 'не включая праздничные'}
					</>
				}
			/>
			<DetailItem
				left={'Срок размещения'}
				right={campaignInterface.getCampaignPeriod()}
			/>
			<DetailItem
				left={details.only_work_days ? 'Рабочих дней' : 'Всего дней'}
				right={details.placement_showing_counts?.[0]?.ac_days_count}
			/>
		</>
	)
}
