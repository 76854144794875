import { ForecastDistrictBooking } from '../model/apiTypes'
import { MediaplanTableRow, SubRow } from '../model/tableModel'

export const mapDistrictBookings = (
	distictBookings: ForecastDistrictBooking[]
): MediaplanTableRow => {
	const totalFormatSums = {
		regions: [] as string[],
		showing_count: 0,
		forecast_views: 0,
		amount: 0,
	}

	const subRows: SubRow[] = []

	for (const district of distictBookings) {
		totalFormatSums.forecast_views += parseFloat(
			district.forecast_views_count || '0'
		)
		totalFormatSums.showing_count += district.placements.reduce(
			(acc, placement) => acc + parseFloat(placement.ots_ac || '0'),
			0
		)
		totalFormatSums.amount += parseFloat(district.amount || '0')
		totalFormatSums.regions.push(district.city_name)

		const headerRow: SubRow = {
			id: `district_${district.id}`,
			role: 'header',
			data: {
				placementType: district.city_name,
			},
		}

		subRows.push(headerRow)

		for (const placement of district.placements) {
			const subRow: SubRow = {
				id: `district_${placement.pk}`,
				role: 'data',
				data: {
					placementType: placement.size,
					address: placement.name,
					showing_count: parseFloat(placement.ots_ac || '0'),
					forecast_views: parseFloat(placement.ots || '0'),
					amount: district.placements_amount,
				},
			}
			subRows.push(subRow)
		}
	}

	const districtRow: MediaplanTableRow = {
		id: 'districts_row',
		isDividerRow: true,
		data: {
			region: totalFormatSums.regions.join(', '),
			gender: '-',
			age: '-',
			showing_count: totalFormatSums.showing_count,
			forecast_views: totalFormatSums.forecast_views,
			amount: totalFormatSums.amount,
			format: {
				name: 'Наружная реклама.Максимальный охват',
				placementsCount: subRows.filter(
					(subRow) => subRow.role !== 'header'
				).length,
			},
		},
		subrows: subRows,
		subrowsOpen: false,
	}

	return districtRow
}
