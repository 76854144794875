import React from 'react'
import { ReactComponent as MCDIcon } from 'src/assets/images/metro.svg'
import s from './mcd.module.scss'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import { MCDControls } from './MCDControls'

type Props = {}
export const MCD = ({}: Props) => {
	return (
		<CardWrapper>
			<div className={s.ourdoorMassCoverage}>
				<div className={s.ourdoorMassCoverageHeader}>
					<div className={s.ourdoorMassCoverageHeader_primary}>
						<h5>Цифровые экраны на МЦД</h5>
						<MCDIcon />
					</div>
				</div>
				<p className="m-0">
					Экраны на станциях и в поездах Московских Центральных
					Диаметров.
				</p>

				<MCDControls />
			</div>
		</CardWrapper>
	)
}
