import React from 'react'
import useCampaignData from 'src/stores/campaignData'
import { MoscowCentRingInfo } from './moscow-cent-ring-info/MoscowCentRingInfo'
import s from '../bookings/BookingAd.module.scss'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { MCDInfo } from './mcd-info/MCDInfo'

export const PublicTransportAd = (props: any) => {
	const [campaign, campaignInterface] = useCampaignData()

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
			{campaign.public_transport.is_selected && <MoscowCentRingInfo />}
			{campaign.metro.is_selected && <MCDInfo />}

			<div className={s.bookingBudget}>
				<>
					<h5>Всего за общественный транспорт:</h5>
					<h5>{IntlFormatter.format(campaign.budget.transport)}</h5>
				</>
			</div>
		</div>
	)
}
