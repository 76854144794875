import React from 'react'
import {
	advCategories,
	ageGroups,
} from 'src/components/_client/create-campaign/constants'
import s from './table.module.scss'
import { Table } from 'react-bootstrap'
import { MediaplanResponse } from '../../model/apiTypes'
import moment from 'moment'

type Props = {
	mediaplan: MediaplanResponse
}
export const CampaignTable = ({ mediaplan }: Props) => {
	const startTime = mediaplan.start_time.slice(0, 5)
	const endTime = mediaplan.end_time.slice(0, 5)

	const startDate = moment(mediaplan.start_date).format('D MMM YYYY')
	const endDate = moment(mediaplan.end_date).format('D MMM YYYY')
	return (
		<Table className={s.table} bordered>
			<thead>
				<tr>
					<th
						scope="col"
						style={{ width: '360px', minWidth: '360px' }}
					>
						Срок размещения
					</th>
					<th
						scope="col"
						style={{ width: '240px', minWidth: '240px' }}
					>
						Время показа (по мск)
					</th>
					<th
						scope="col"
						style={{ width: '360px', minWidth: '300px' }}
					>
						Тематика рекламы
					</th>
					<th
						scope="col"
						style={{ width: '140px', minWidth: '140px' }}
					>
						Маркировка
					</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td style={{ padding: 0 }}>
						<div style={{ height: '100%', display: 'flex' }}>
							<div
								style={{
									width: '50%',
									height: '100%',
									borderRight: '1px solid #CED4DA',
									padding: '12px 16px',
								}}
							>{`c ${startDate}`}</div>
							<div
								style={{ width: '50%', padding: '12px 16px' }}
							>{`по ${endDate}`}</div>
						</div>
					</td>
					<td>{`с ${startTime} до ${endTime}`}</td>
					<td>
						{mediaplan.topic ? advCategories[mediaplan.topic] : '-'}
					</td>
					<td style={{ textAlign: 'right' }}>
						{ageGroups[mediaplan.age_marking]}
					</td>
				</tr>
			</tbody>
		</Table>
	)
}
//
