import React, { useRef, useState, useEffect } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type TextOverflowProps = {
	text: string
	className?: string
	style?: React.CSSProperties
	placement?: 'top' | 'right'
}

export const TextOverflow: React.FC<TextOverflowProps> = ({
	text,
	className = '',
	style = {},
	placement = 'top',
}) => {
	const textRef = useRef<HTMLParagraphElement>(null)
	const [isTruncated, setIsTruncated] = useState(false)

	// Check if text is truncated
	useEffect(() => {
		if (textRef.current) {
			setIsTruncated(
				textRef.current.scrollWidth > textRef.current.clientWidth
			)
		}
	}, [text])

	const renderTooltip = (props: any) => (
		<Tooltip id={`tooltip-${text}`} {...props}>
			{text}
		</Tooltip>
	)

	return (
		<OverlayTrigger
			placement={placement}
			overlay={isTruncated ? renderTooltip : <></>}
		>
			<p
				ref={textRef}
				className={className}
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					cursor: isTruncated ? 'pointer' : 'default',
					...style,
				}}
			>
				{text}
			</p>
		</OverlayTrigger>
	)
}
