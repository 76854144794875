import React, { useEffect, useMemo, useState } from 'react'
import { PlacementsList } from './placements-list/PlacementsList'
import { PlacementDetails } from './placement-details/PlacementDetails'
import { PlacementsNav } from '../../common/PlacementsNav'
import { AnimatePresence, motion } from 'framer-motion'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import {
	PlacementsPickerProps,
	SelectedPsc,
} from '../../common/PlacementsPickerContent'

type Props = {
	placements: PlacementShort[]
} & Pick<
	PlacementsPickerProps,
	| 'onSelectPlacement'
	| 'selectedPlacements'
	| 'onApplyCommonDuration'
	| 'onAddSelectedToCart'
	| 'isAllSelectedInAdded'
	| 'getDetailedPlacement'
	| 'detailedPlacementId'
	| 'addedPlacements'
	| 'onCloseDetailedPlacement'
	| 'onAddDetailedPlacement'
	| 'onChangePlacementsFilter'
	| 'onSelectAll'
	| 'placementTypes'
	| 'onFocusCenterChange'
	| 'onRemovePlacementsFromCart'
	| 'loading'
>

export const LeftPanel = ({
	placements,
	selectedPlacements,
	detailedPlacementId,
	onSelectPlacement,
	onApplyCommonDuration,
	onAddSelectedToCart,
	isAllSelectedInAdded,
	getDetailedPlacement,
	addedPlacements,
	onCloseDetailedPlacement,
	onAddDetailedPlacement,
	onChangePlacementsFilter,
	onSelectAll,
	placementTypes,
	onFocusCenterChange,
	onRemovePlacementsFromCart,
	loading,
}: Props) => {
	const [openList, setOpenList] = useState(true)

	const selectedPscForDetailed: SelectedPsc | null = useMemo(() => {
		const findedPlacement = selectedPlacements.find(
			(el) => el.pk === detailedPlacementId
		)

		if (!findedPlacement) {
			return null
		}
		return {
			pk: findedPlacement.psc.pk,
			price: findedPlacement.psc.price,
			videoDuration: findedPlacement.psc.videoDuration,
		}
	}, [detailedPlacementId, selectedPlacements])
	return (
		<div
			style={{
				display: 'flex',
				gap: '16px',
				maxWidth: 'min-content',
				top: 0,
				left: 0,
				position: 'absolute',
				height: '100%',
				zIndex: 1056,
				pointerEvents: 'none',
			}}
		>
			<motion.div
				initial={{ width: 0, opacity: 0 }}
				animate={{
					width: openList ? 360 : 0,
					opacity: openList ? 1 : 0,
				}}
				exit={{ width: 0, opacity: 0, height: 0 }}
				transition={{ duration: 0.3, ease: 'easeInOut' }}
				style={{
					minWidth: openList ? '360px' : 0,
					pointerEvents: 'auto',
				}}
			>
				<PlacementsList
					loading={loading}
					onRemovePlacementsFromCart={onRemovePlacementsFromCart}
					onSelectAll={onSelectAll}
					onChangePlacementsFilter={onChangePlacementsFilter}
					detailedPlacementId={detailedPlacementId}
					addedPlacements={addedPlacements}
					getDetailedPlacement={getDetailedPlacement}
					isAllSelectedInAdded={isAllSelectedInAdded}
					onAddSelectedToCart={onAddSelectedToCart}
					onApplyCommonDuration={onApplyCommonDuration}
					placements={placements}
					selectedPlacements={selectedPlacements}
					onSelectPlacement={onSelectPlacement}
				/>
			</motion.div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					minWidth: 'fit-content',
					padding: '16px',
				}}
			>
				<PlacementsNav
					onFocusCenterChange={onFocusCenterChange}
					onChangePlacementsFilter={onChangePlacementsFilter}
					placementTypes={placementTypes}
					isExpanded={openList}
					setIsExpanded={setOpenList}
				/>
				<AnimatePresence>
					{detailedPlacementId && (
						<PlacementDetails
							onRemovePlacementsFromCart={
								onRemovePlacementsFromCart
							}
							selectedPscForDetailed={selectedPscForDetailed}
							onAddDetailedPlacement={onAddDetailedPlacement}
							onCloseDetailedPlacement={onCloseDetailedPlacement}
							detailedPlacementId={detailedPlacementId}
							addedPlacements={addedPlacements}
						/>
					)}
				</AnimatePresence>
			</div>
		</div>
	)
}
