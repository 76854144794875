import React from 'react'
import { ReactComponent as InfoIcon } from 'src/assets/images/info-ring.svg'

export const NoItemsInCart = () => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				gap: '16px',
				padding: '40px 24px',
			}}
		>
			<InfoIcon
				style={{ fill: '#6C757D', width: '40px', height: '40px' }}
			/>
			<h5 className="m-0" style={{ color: '#6C757D' }}>
				Нет добавленных мест
			</h5>
		</div>
	)
}
