import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import useDebounce from 'src/utils/useDebounce'
import { ReactComponent as CloseIcon } from 'src/assets/images/close cancel x.svg'

export type PlacementSearchProps = {
	setSearchString: (searchString: string) => void
	minWidth?: string
	placeholder?: string
	onFocus?: () => void
	onBlur?: () => void
	searchString?: string
}
export const Search = ({
	setSearchString,
	minWidth,
	placeholder,
	searchString,
	onBlur,
	onFocus,
}: PlacementSearchProps) => {
	const [value, setValue] = useState(searchString || '')
	const debounce = useDebounce(value, 600)

	const handleResetSearch = () => {
		setValue('')
		setSearchString('')
	}

	useEffect(() => {
		setSearchString(debounce)
	}, [debounce])

	useEffect(() => {
		if (searchString !== undefined) {
			setValue(searchString)
		}
	}, [searchString])
	return (
		<div
			className={'position-relative'}
			style={{ minWidth: minWidth || '400px' }}
		>
			<Form.Control
				onFocus={onFocus}
				onBlur={onBlur}
				style={{ borderRadius: '12px' }}
				placeholder={placeholder || 'Поиск'}
				className={'pe-4 w-100'}
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>
			{debounce.trim() === '' ? (
				<svg
					width="13"
					height="13"
					viewBox="0 0 13 13"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					style={{
						position: 'absolute',
						top: 12,
						right: 12,
						pointerEvents: 'none',
					}}
				>
					<path
						d="M5.33333 0C8.27885 0 10.6667 2.38781 10.6667 5.33333C10.6667 6.56547 10.2488 7.70001 9.54715 8.60302L12.1381 11.1953L11.1953 12.1381L8.60302 9.54715C7.70001 10.2488 6.56547 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333C0 2.38781 2.38781 0 5.33333 0ZM5.33333 1.33333C3.12419 1.33333 1.33333 3.12419 1.33333 5.33333C1.33333 7.54247 3.12419 9.33333 5.33333 9.33333C7.54247 9.33333 9.33333 7.54247 9.33333 5.33333C9.33333 3.12419 7.54247 1.33333 5.33333 1.33333Z"
						fill="#6C757D"
					/>
				</svg>
			) : (
				<CloseIcon
					onClick={handleResetSearch}
					style={{
						width: '13px',
						height: '13px',
						position: 'absolute',
						top: 12,
						right: 12,
						zIndex: 999,
					}}
				/>
			)}
		</div>
	)
}
